import { firestore, storage } from "../../../firebase" // Adjust import based on your Firebase setup
import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	getDoc,
	query,
	orderBy,
	where
} from "firebase/firestore"

import { ref, getDownloadURL } from "firebase/storage"

export async function getPeople(queryParams) {
	if (!queryParams || !queryParams.customerId) {
		return Promise.resolve([]) // Return an empty array instead of resolving with nothing
	}

	const customerId = queryParams.customerId

	if (typeof customerId !== "string" || customerId.trim() === "") {
		return Promise.reject(new Error("customerId must be a non-empty string"))
	}

	try {
		const peopleCollectionRef = collection(doc(firestore, "Customers", customerId), "People")
		const snapshot = await getDocs(peopleCollectionRef)

		// Map over the documents and return an array of data
		const people = snapshot?.docs?.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return people
	} catch (error) {
		console.error("Error fetching people:", error)
		return Promise.reject(error) // Propagate the error
	}
}

export async function getAssets(queryParams) {
	if (!queryParams || !queryParams.customerId) {
		return Promise.resolve([]) // Return an empty array instead of resolving with nothing
	}

	const customerId = queryParams.customerId

	if (typeof customerId !== "string" || customerId.trim() === "") {
		return Promise.reject(new Error("customerId must be a non-empty string"))
	}

	try {
		const assetsCollectionRef = collection(doc(firestore, "Customers", customerId), "Assets")
		const snapshot = await getDocs(assetsCollectionRef)

		// Map over the documents and return an array of data
		const assets = snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return assets
	} catch (error) {
		console.error("Error fetching assets:", error)
		return Promise.reject(error) // Propagate the error
	}
}

export async function getGlobalOptions() {
	try {
		// Create document references
		const generalInfoRef = doc(firestore, "GlobalOptions", "GeneralInfo")
		const mapRef = doc(firestore, "GlobalOptions", "Map")
		const defaultAssetTypesRef = doc(firestore, "GlobalOptions", "DefaultAssetTypes")

		// Fetch document data
		const [generalInfoSnapshot, mapSnapshot, defaultAssetTypesSnapshot] = await Promise.all([
			getDoc(generalInfoRef),
			getDoc(mapRef),
			getDoc(defaultAssetTypesRef)
		])

		// Extract data from snapshots
		const generalInfo = generalInfoSnapshot ? generalInfoSnapshot.data() : null
		const map = mapSnapshot ? mapSnapshot.data() : null
		const defaultAssetTypesData = defaultAssetTypesSnapshot
			? defaultAssetTypesSnapshot.data()
			: {}
		const defaultAssetTypes = defaultAssetTypesData.types || []

		return {
			generalInfo,
			map,
			defaultAssetTypes
		}
	} catch (error) {
		console.error("Error fetching global options:", error)
		// Handle errors appropriately
		return {
			generalInfo: null,
			map: null,
			defaultAssetTypes: []
		}
	}
}

export async function getPeopleWithImages(queryParams) {
	if (!queryParams || !queryParams.people) return Promise.resolve(null)

	const peopleWithImages = await Promise.all(
		queryParams.people.map(async person => {
			const personPicturePath = person?.personalData?.picture?.replace("gs://", "")

			if (!personPicturePath) {
				return {
					...person,
					picture: null
				}
			}

			const index = personPicturePath.indexOf("/")
			const pathWithoutBucket = personPicturePath.slice(index)

			try {
				const imageRef = ref(storage, pathWithoutBucket)
				const downloadURL = await getDownloadURL(imageRef)
				return {
					...person,
					picture: downloadURL
				}
			} catch (err) {
				console.error("Error fetching image URL: ", err)
				return {
					...person,
					picture: null
				}
			}
		})
	)

	return peopleWithImages
}

export async function getFloorPlans(queryParams) {
	if (!queryParams || !queryParams.siteId) return Promise.resolve()

	try {
		const floorPlansRef = collection(doc(firestore, "Sites", queryParams.siteId), "FloorPlans")
		const q = query(floorPlansRef, orderBy("level", "desc"))
		const querySnapshot = await getDocs(q)

		const floorPlans = querySnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return floorPlans
	} catch (error) {
		console.error("Error fetching floor plans:", error)
		return []
	}
}

export function createFloorplan(queryParams) {
	const { siteId, floorplanData, selectedCustomer, user } = queryParams

	if (!siteId || !floorplanData) {
		return Promise.resolve(null)
	}

	const reqParams = {
		email: queryParams.user.email,
		userId: queryParams.user.id,
		floorplans: [queryParams?.floorplanData]
	}
	// console.log("🚀  reqParams:", reqParams)
	// console.log("🚀  reqParams:", JSON.stringify(reqParams))

	// Call API function
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")
	var raw = JSON.stringify(reqParams)

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/manage-site/manageFloorplans/${selectedCustomer.id}/${siteId}`,
		requestOptions
	)
}

export async function getAreas(queryParams) {
	if (!queryParams || !queryParams.siteId) return Promise.resolve([])

	try {
		const areasRef = collection(doc(firestore, "Sites", queryParams.siteId), "Areas")
		const querySnapshot = await getDocs(areasRef)

		const areas = querySnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return areas
	} catch (error) {
		console.error("Error fetching areas:", error)
		return []
	}
}

export async function getDepartments(queryParams) {
	if (!queryParams || !queryParams.customerId || !queryParams.userId) {
		return Promise.resolve({ managedDepartments: [], employeeDepartments: [] })
	}

	try {
		const departmentsRef = collection(
			doc(firestore, "Customers", queryParams.customerId),
			"Departments"
		)

		const managedQuery = query(departmentsRef, where("manager", "==", queryParams.userId))
		const employeeQuery = query(
			departmentsRef,
			where("employees", "array-contains", queryParams.userId)
		)

		const [managedSnapshot, employeeSnapshot] = await Promise.all([
			getDocs(managedQuery),
			getDocs(employeeQuery)
		])

		const managedDepartments = managedSnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		const employeeDepartments = employeeSnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return {
			managedDepartments,
			employeeDepartments
		}
	} catch (error) {
		console.error("Error fetching departments:", error)
		return { managedDepartments: [], employeeDepartments: [] }
	}
}

export async function getResources(queryParams) {
	if (!queryParams || !queryParams.customer || !queryParams.location) {
		return Promise.resolve(null)
	}

	try {
		const resourcesRef = collection(
			doc(firestore, "Customers", queryParams.customer),
			"Resources"
		)
		const resourcesQuery = query(resourcesRef, where("networkId", "==", queryParams.location))

		const snapshot = await getDocs(resourcesQuery)

		const resources = snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return resources
	} catch (error) {
		console.error("Error fetching resources:", error)
		return null
	}
}

export async function deleteFloorPlan({
	siteId,
	floorplansToDelete = [],
	areasToDelete = [],
	poisToDelete = [],
	user,
	selectedCustomer
}) {
	if (!siteId || !user || !selectedCustomer) {
		console.error("Missing required parameters.")
		return Promise.resolve()
	}

	const reqParams = {
		email: user.email,
		userId: user.id,
		floorplans: floorplansToDelete
	}

	// Prepare headers and request options for API call
	const myHeaders = new Headers({
		"X-API-Key": process.env.REACT_APP_API_GATEWAY_KEY,
		"Content-Type": "application/json"
	})

	const raw = JSON.stringify(reqParams)

	const requestOptions = {
		method: "DELETE",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	try {
		// Delete areas, points of interest, and floorplans in parallel
		const deletePromises = [
			...areasToDelete.map(area =>
				deleteDoc(doc(firestore, `Sites/${siteId}/Areas/${area.id}`))
			),
			...poisToDelete.map(poi =>
				deleteDoc(doc(firestore, `Sites/${siteId}/PointsOfInterest/${poi.id}`))
			),
			fetch(
				`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/manage-site/manageFloorplans/${selectedCustomer.id}/${siteId}`,
				requestOptions
			)
		]

		if (floorplansToDelete.length > 0) {
			deletePromises.push(
				...floorplansToDelete.map(floor =>
					deleteDoc(doc(firestore, `Sites/${siteId}/FloorPlans/${floor.id}`))
				)
			)
		}

		// Execute all delete operations and API call
		await Promise.all(deletePromises)
	} catch (error) {
		console.error("Error deleting floor plans or associated data:", error)
	}
}

export async function updateFloorPan(queryParams) {
	const { siteId, floorplanData, floorPlanToEditId, selectedCustomer, user, POISToDeleteArray } =
		queryParams

	if (!siteId || !floorplanData) {
		return Promise.resolve(null)
	}

	const reqParams = {
		email: queryParams.user.email,
		userId: queryParams.user.id,
		floorplans: [queryParams?.floorplanData]
	}

	// Call API function
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")
	var raw = JSON.stringify(reqParams)
	// console.log("🚀  raw:", raw)

	var requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/manage-site/manageFloorplans/${selectedCustomer.id}/${siteId}`,
		requestOptions
	)
}

export async function deletePointsOfInterestAfterEditFloorplan({
	floorPlanId,
	siteId,
	POISToDeleteArray
}) {
	if (!siteId || !floorPlanId || !POISToDeleteArray || !POISToDeleteArray.length) {
		return Promise.resolve(null)
	}

	try {
		const deletePromises = POISToDeleteArray.map(val => {
			const poiDocRef = doc(firestore, `Sites/${siteId}/PointsOfInterest/${val.id}`)
			return deleteDoc(poiDocRef)
		})

		await Promise.all(deletePromises)

		console.log("Points of Interest successfully deleted.")
	} catch (error) {
		console.error("Error deleting Points of Interest:", error)
	}
}

export async function getPointsOfInterest(queryParams) {
	if (!queryParams || !queryParams.siteId) {
		return Promise.resolve(null)
	}

	try {
		const poiCollectionRef = collection(
			doc(firestore, "Sites", queryParams.siteId),
			"PointsOfInterest"
		)
		const poiQuery = query(poiCollectionRef, orderBy("created.date"))

		const snapshot = await getDocs(poiQuery)

		const pointsOfInterest = snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return pointsOfInterest
	} catch (error) {
		console.error("Error fetching Points of Interest:", error)
		return null
	}
}
