import React, { useMemo, useCallback, useState, useEffect } from "react"
import { Button } from "../../../../_partials/Button"
import { useUIContext } from "../../UIContext"
import { makeStyles, ListItemIcon, List, ListItem, ListItemText, Collapse } from "@material-ui/core"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { Input } from "../../../../_partials/inputs/Input"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import Alert from "@material-ui/lab/Alert"
import Switch from "@mui/material/Switch"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import ViewListIcon from "@material-ui/icons/ViewList"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { addDoc, collection, serverTimestamp } from "firebase/firestore"
import { firestore } from "../../../../../firebase"

const EditSchema = Yup.object().shape({
	subject: Yup.string().nullable().trim().min(1, "Subject must not be empty"),
	message: Yup.string().nullable().trim().min(1, "Message must not be empty")
})
const initialValues = {
	subject: "",
	message: ""
}

const useStyles = makeStyles(theme => ({
	title: {
		color: "#444",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "600",
		lineHeight: "160%",
		textTransform: "capitalize",
		padding: "1rem 0"
	},
	description: {
		color: "#8C8CA1",
		fontFamily: "Poppins",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "170%",
		padding: "1rem 0"
	},
	label: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "170%",
		padding: "1rem 0"
	},
	container: {
		padding: "1rem",
		backgroundColor: "#FFFFFF"
	},
	buttonContainer: {
		// padding: "1rem 1rem 0 1rem"
		padding: "0 1rem 0 1rem"
	},
	toggleButton: {
		display: "flex !important",
		padding: "10px 20px !important",
		justifyContent: "center !important",
		alignItems: "flex-start !important",
		gap: "10px !important",
		flex: "1 0 0 !important",
		borderRadius: "4px !important",
		border: "none !important",

		fontFamily: "Poppins !important",
		fontSize: "16px !important",
		fontStyle: "normal !important",
		fontWeight: "700 !important",
		lineHeight: "normal !important",
		letterSpacing: "0.64px !important",
		textTransform: "uppercase !important"
	},
	normalPriority: { color: "#53B04F !important", backgroundColor: "#E6F4E6 !important" },
	normalPrioritySelected: {
		color: "#FFFFFF !important",
		backgroundColor: "#53B04F !important"
	},
	mediumPriority: {
		color: "#F6B952 !important",
		backgroundColor: "#FDF1DC !important",
		margin: "0 10px !important"
	},
	mediumPrioritySelected: {
		color: "#FFFFFF !important",
		backgroundColor: "#F6B952 !important",
		margin: "0 10px !important"
	},
	criticalPriority: {
		color: "#E65019 !important",
		backgroundColor: "#FBE6DE !important"
	},
	criticalPrioritySelected: {
		color: "#FFFFFF !important",
		backgroundColor: "#E65019 !important"
	},
	selectedApp: {
		borderBottom: "1px solid #2673F0",
		fontWeight: "700"
	},
	selectedSms: {
		borderBottom: "1px solid #8680FF",
		fontWeight: "700"
	},
	switchSms: {
		"& .MuiSwitch-thumb": {
			backgroundColor: "#8680FF !important"
		},
		"& .MuiSwitch-track": {
			backgroundColor: "#cfccff !important"
		}
	},
	switchApp: {
		"& .MuiSwitch-thumb": {
			backgroundColor: "#2673F0 !important"
		},
		"& .MuiSwitch-track": {
			backgroundColor: "#a8c7f9 !important"
		}
	}
}))

const CustomToggleButton = ({ alignment, value }) => {
	const classes = useStyles()
	const isSelected = alignment === value

	return (
		<ToggleButton
			className={`${classes.toggleButton} 
        ${
				value === "normal"
					? isSelected
						? classes.normalPrioritySelected
						: classes.normalPriority
					: ""
			} 
        ${
				value === "medium"
					? isSelected
						? classes.mediumPrioritySelected
						: classes.mediumPriority
					: ""
			} 
        ${
				value === "critical"
					? isSelected
						? classes.criticalPrioritySelected
						: classes.criticalPriority
					: ""
			}
      `}
			value={value}
		>
			{value}
		</ToggleButton>
	)
}

const SendMessage = ({ onHide, handleChange }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const UIContext = useUIContext()
	const UIProps = useMemo(
		() => ({
			membersToSendMessage: UIContext.membersToSendMessage,
			selectedValues: UIContext.selectedValues,
			sitesValue: UIContext.sitesValue
		}),
		[UIContext]
	)

	const { selectedCustomer, selectedSite, user, allSites } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite,
			selectedCustomer: state.profile?.currentCustomer,
			allSites: state.manageSites?.sites,
			user: state.auth?.user
		}),
		shallowEqual
	)

	const [alignment, setAlignment] = useState("normal")
	const [messageType, setMessageType] = useState("APP")
	const [inputMessage, setInputMessage] = useState("")
	const [inputSubject, setInputSubject] = useState("")
	const [invalidMembers, setInvalidMembers] = useState([])
	const [validMembers, setValidMembers] = useState([])
	const [groups, setGroups] = useState([])
	const [openList, setOpenList] = useState(true)
	const [anchorEl, setAnchorEl] = useState(null)
	const [checked, setChecked] = useState(true)
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [failure, setFailure] = useState(false)

	const open = Boolean(anchorEl)

	const handleChangeMessageType = event => {
		setChecked(event.target.checked)

		if (event.target.checked) setMessageType("APP")
		else setMessageType("SMS")
	}

	const handleClickList = () => {
		setOpenList(!openList)
	}

	const handleButtonGroupChange = useCallback((event, newAlignment) => {
		if (newAlignment !== null) {
			setAlignment(newAlignment)
		}
	}, [])

	const onSubmit = () => {
		console.log("on submit")
		return
	}

	useEffect(() => {
		if (!UIProps.membersToSendMessage || UIProps.membersToSendMessage.length === 0) return

		if (messageType === "SMS") {
			const v =
				UIProps.membersToSendMessage?.length > 0 &&
				UIProps.membersToSendMessage.filter(person => person.personalData?.phoneNumber)
			setValidMembers(v)
		}
		if (messageType === "APP") {
			const v =
				UIProps.membersToSendMessage?.length > 0 &&
				UIProps.membersToSendMessage.filter(person => person.UID)
			setValidMembers(v)
		}
	}, [UIProps.membersToSendMessage, messageType])

	useEffect(() => {
		if (!validMembers) return
		if (messageType === "SMS") {
			const v =
				UIProps.membersToSendMessage?.length > 0 &&
				UIProps.membersToSendMessage.filter(person => !person.personalData?.phoneNumber)
			setInvalidMembers(v)
		}
		if (messageType === "APP") {
			const v =
				UIProps.membersToSendMessage?.length > 0 &&
				UIProps.membersToSendMessage.filter(person => !person.UID)
			setInvalidMembers(v)
		}
	}, [validMembers])

	useEffect(() => {
		if (!UIProps.selectedValues || UIProps.selectedValues.length === 0) return

		const flattened = Object.values(UIProps.selectedValues).flat()
		setGroups(flattened)
	}, [UIProps.selectedValues])

	return (
		<div className={classes.container} data-testid="send-message-component">
			<div className="row">
				<div className="col-4">
					<span className={classes.title}>Summary</span>

					{UIProps.sitesValue && UIProps.sitesValue.length > 0 && (
						<div
							style={{
								marginTop: "10px",
								marginBottom: "10px",
								borderRadius: "5px",
								border: "1px solid #2673F0",
								margin: "2rem 0",
								padding: "10px"
							}}
							className="row"
						>
							<div className="col">
								<div
									className="row"
									style={{ paddingLeft: "12.5px", alignItems: "center" }}
								>
									<div
										className="col-4 d-flex"
										style={{ position: "relative", overflow: "auto", height: "31px" }}
									>
										{UIProps.sitesValue &&
											UIProps.sitesValue.slice(0, 6).map((data, index) => {
												const site = allSites?.find(aSite => aSite.id === data)
												return (
													<OverlayTrigger
														placement="bottom"
														overlay={
															<Tooltip id="quick-actions-tooltip">
																Name: {site.siteInfo?.name}
															</Tooltip>
														}
													>
														<div
															key={index}
															className="icon-preview"
															style={{
																position: "absolute",
																left: `${index * 10}px`
															}}
														>
															{site?.siteInfo?.imageUrls?.[0] ? (
																<img
																	src={site.siteInfo.imageUrls[0]}
																	alt="people profile image placeholder"
																/>
															) : (
																<SVG src={"/media/crowdkeep/person.svg"} />
															)}
														</div>
													</OverlayTrigger>
												)
											})}
									</div>
									<div className="col-1 select-counter" style={{ fontWeight: 700 }}>
										{UIProps.sitesValue.length}
									</div>
									<div className="col select-counter">Sites Selected</div>
								</div>
							</div>
						</div>
					)}
					{validMembers && validMembers.length > 0 && (
						<div
							style={{
								marginTop: "10px",
								marginBottom: "10px",
								borderRadius: "5px",
								border: "1px solid #2673F0",
								margin: "2rem 0",
								padding: "10px"
							}}
							className="row"
						>
							<div className="col">
								<div
									className="row"
									style={{ paddingLeft: "12.5px", alignItems: "center" }}
								>
									<div
										className="col-4 d-flex"
										style={{ position: "relative", overflow: "auto", height: "31px" }}
									>
										{validMembers &&
											validMembers.slice(0, 6).map((person, index) => {
												return (
													<OverlayTrigger
														placement="bottom"
														overlay={
															<Tooltip id="quick-actions-tooltip">
																Name: {person?.personalData?.name}
															</Tooltip>
														}
													>
														<div
															key={index}
															className="icon-preview"
															style={{
																position: "absolute",
																left: `${index * 10}px`
															}}
														>
															{person?.personalData?.picture ? (
																<img
																	src={person?.personalData?.picture}
																	alt="people profile image placeholder"
																/>
															) : (
																<SVG src={"/media/crowdkeep/person.svg"} />
															)}
														</div>
													</OverlayTrigger>
												)
											})}
									</div>
									<div className="col-1 select-counter" style={{ fontWeight: 700 }}>
										{validMembers.length}
									</div>
									<div className="col select-counter">People Selected</div>
								</div>
							</div>
						</div>
					)}
					{groups && groups.length > 0 && (
						<div
							style={{
								marginTop: "10px",
								marginBottom: "10px",
								borderRadius: "5px",
								border: "1px solid #2673F0",
								margin: "2rem 0",
								padding: "10px"
							}}
							className="row"
						>
							<div className="col">
								<div
									className="row"
									style={{ paddingLeft: "12.5px", alignItems: "center" }}
								>
									<div
										className="col-4 d-flex"
										style={{ position: "relative", overflow: "auto", height: "31px" }}
									>
										{groups &&
											groups.slice(0, 6).map((index, group) => {
												return (
													<OverlayTrigger
														placement="bottom"
														overlay={
															<Tooltip id="quick-actions-tooltip">
																Name: {group}
															</Tooltip>
														}
													>
														<div
															key={index}
															className="icon-preview"
															style={{
																position: "absolute",
																left: `${index * 10}px`
															}}
														>
															<SVG src={"/media/crowdkeep/person.svg"} />
														</div>
													</OverlayTrigger>
												)
											})}
									</div>
									<div className="col-1 select-counter" style={{ fontWeight: 700 }}>
										{groups.length}
									</div>
									<div className="col select-counter">Groups Selected</div>
								</div>
							</div>
						</div>
					)}

					{invalidMembers && invalidMembers?.length > 0 && (
						<>
							<div className="mb-4">
								<Alert
									severity="warning"
									style={{
										color: "rgb(102, 60, 0)",
										backgroundColor: "rgb(255, 244, 229)",
										alignItems: "center"
									}}
								>
									Attention:{" "}
									<b>
										{invalidMembers?.length}/{UIProps.membersToSendMessage?.length}
									</b>{" "}
									will not receive a message. <br />
									To receive a message via <b>{messageType}</b>, all recipients <br />
									should have a valid{" "}
									<b>{messageType === "SMS" ? "Phone Number" : "UID"}</b>.
								</Alert>
							</div>

							<div className="mb-4">
								{/* com collapse com span com o nome e o uuid */}
								<List
									aria-labelledby="nested-list-subheader"
									// className={classes.root}
								>
									<ListItem button onClick={handleClickList}>
										<ListItemIcon>
											<ViewListIcon />
										</ListItemIcon>
										<ListItemText
											primary={`List of people without ${
												messageType === "SMS" ? "Phone Number" : "UID"
											}`}
										/>
										{openList ? <ExpandLess /> : <ExpandMore />}
									</ListItem>
									<Collapse
										in={openList}
										timeout="auto"
										unmountOnExit
										style={{
											maxHeight: "100px",
											overflowX: "scroll"
										}}
									>
										<List component="div" disablePadding>
											{invalidMembers?.length > 0 &&
												invalidMembers.map(person => {
													return (
														<ListItem
															key={person?.id}
															button
															className={classes.nested}
															// style={{
															// 	background:
															// 		person.status !== "unassigned" &&
															// 		person.status !== "created"
															// 			? "#FBE6DE"
															// 			: "transparent"
															// }}
														>
															<ListItemIcon>
																<SVG
																	style={{
																		fill: "#1C1C3B"
																	}}
																	src={toAbsoluteUrl(
																		"/media/crowdkeep/person.svg"
																	)}
																/>
															</ListItemIcon>
															<ListItemText
																primary={person?.personalData?.name}
																// secondary={
																// 	person.status !== "unassigned" &&
																// 	person.status !== "created" &&
																// 	getSecondaryText(person)
																// }
															/>
														</ListItem>
													)
												})}
										</List>
									</Collapse>
								</List>
							</div>
						</>
					)}
				</div>
				<div className="col">
					<div className="row m-0 justify-content-between">
						<div className={`${classes.title} col`}>Send Message</div>
						<div
							className="col"
							style={{
								textAlignLast: "end",
								display: "flex",
								justifyContent: "end",
								alignItems: "center"
							}}
						>
							<div
								className={`${classes.label} ${
									messageType === "SMS" && classes.selectedSms
								} p-0`}
							>
								SMS
							</div>
							<Switch
								checked={checked}
								className={messageType === "SMS" ? classes.switchSms : classes.switchApp}
								onChange={handleChangeMessageType}
								inputProps={{ "aria-label": "controlled" }}
							/>
							<div
								className={`${classes.label} ${
									messageType === "APP" && classes.selectedApp
								} p-0`}
							>
								APP
							</div>
						</div>
					</div>
					<div className={classes.description}>
						Alert will be sent to all the selected people on site. If a specific person is not
						selected, the alert will be sent to people on-site today.
					</div>

					<div className={classes.label}>Priority Level</div>
					<ToggleButtonGroup
						color="primary"
						value={alignment}
						exclusive
						onChange={handleButtonGroupChange}
						aria-label="Priority Level"
						fullWidth
						// style={{ width: "100%" }}
					>
						<CustomToggleButton value="normal" alignment={alignment} />
						<CustomToggleButton value="medium" alignment={alignment} />
						<CustomToggleButton value="critical" alignment={alignment} />
					</ToggleButtonGroup>

					{/* <div className={classes.label}>Subject</div> */}
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validationSchema={EditSchema}
						onSubmit={values => {
							onSubmit(values)
						}}
						data-testid="NewNodeModel"
					>
						{({ setFieldValue, handleSubmit, dirty, values }) => {
							return (
								<Form className="form form-label-right p-3" data-testid="NewNodeModel">
									<div className="form-group row mb-0 mt-2">
										<div className="drawer_title_container d-flex w-100 align-items-center justify-content-between">
											<div className={classes.label}>
												Subject <sup style={{ color: "red" }}> *</sup>
											</div>
										</div>
										<div className="col" style={{ padding: "0", marginBottom: "2rem" }}>
											<Field
												variant="filled"
												name="subject"
												placeholder="Enter subject"
												component={Input}
												InputProps={{
													disableUnderline: true
												}}
												value={inputSubject}
												onChange={e => {
													if (!e.target.value) {
														setInputSubject("")
														setFieldValue("subject", "")
													} else {
														setInputSubject(e.target.value)
														setFieldValue("subject", e.target.value)
													}
												}}
											/>
										</div>
									</div>
									<div className="form-group row mb-0">
										<div className="drawer_title_container d-flex w-100 align-items-center justify-content-between">
											<div className={classes.label}>
												Message <sup style={{ color: "red" }}> *</sup>
											</div>
										</div>
										<div className="col" style={{ padding: "0", marginBottom: "2rem" }}>
											<Field
												variant="filled"
												name="message"
												placeholder="Write a message..."
												multiline
												rows={6} // Adjust the number of rows as needed
												component={Input}
												InputProps={{
													disableUnderline: true,
													style: { height: "auto", padding: "12px" }
												}}
												value={inputMessage}
												onChange={e => {
													if (!e.target.value) {
														setInputMessage("")
														setFieldValue("message", "")
													} else {
														setInputMessage(e.target.value)
														setFieldValue("message", e.target.value)
													}
												}}
												data-testid="Formik_Form_Notes"
											/>
										</div>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
			<div className="row justify-content-between" style={{ padding: "0 1rem 0 1rem" }}>
				<Button
					color="primary"
					variant="outlined"
					text="BACK"
					startIcon={<KeyboardArrowLeftIcon fontSize="small" />}
					onClick={e => handleChange(e, 1)}
				/>
				<Button
					color="primary"
					variant="contained"
					disabled={!inputSubject.trim() || !inputMessage.trim()}
					onClick={() => {
						setLoading(true)
						handleBroadcast(
							selectedCustomer,
							selectedSite,
							user,
							validMembers,
							messageType,
							inputMessage,
							inputSubject,
							setSuccess,
							setFailure,
							setLoading,
							dispatch
						)
					}}
					text="SEND"
					needsLoadingBar
					loading={loading}
					success={success}
					failure={failure}
					setSuccess={setSuccess}
					setFailure={setFailure}
				/>
			</div>
		</div>
	)
}

export default SendMessage

async function handleBroadcast(
	selectedCustomer,
	selectedSite,
	user,
	validMembers,
	messageType,
	inputMessage,
	inputSubject,
	setSuccess,
	setFailure,
	setLoading,
	dispatch
) {
	var messagesCount = 0
	if (messageType === "SMS") {
		messagesCount = await sendViaSMS({
			people: validMembers,
			inputMessage,
			customerId: selectedCustomer.id,
			customerName: selectedCustomer.customerInfo?.name,
			siteId: selectedSite.id,
			siteName: selectedSite.siteInfo?.name,
			user
		}).then(res => {
			setLoading(false)
			if (res && res > 0) {
				setSuccess(true)
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: `Message has been sent successfully (${res}).` }
				})
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: `The message was not sent successfully (${res}).` }
				})
			} else {
				setFailure(true)
			}
		})
	} else {
		messagesCount = await sendViaAPP({
			people: validMembers,
			inputMessage,
			inputSubject,
			user,
			customerId: selectedCustomer.id
		}).then(res => {
			setLoading(false)
			if (res && res > 0) {
				setSuccess(true)
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: `Message has been sent successfully (${res}).` }
				})
			} else {
				setFailure(true)
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: `The message was not sent successfully (${res}).` }
				})
			}
		})
	}
}

async function sendViaSMS({
	people,
	inputMessage,
	customerId,
	customerName,
	siteId,
	siteName,
	user
}) {
	const phoneNumbers = people.map(val => val.personalData?.phoneNumber).filter(val => val)
	var promises = []

	phoneNumbers.forEach(phoneNumber => {
		const docData = {
			from: process.env.REACT_APP_TWILLIO_FROM_NUMBER,
			to: phoneNumber || phoneNumber,
			body: inputMessage,
			senderInformation: {
				customerId: customerId,
				customerName: customerName,
				siteId: siteId,
				siteName: siteName || null,
				userId: user.id,
				userEmail: user.email,
				date: serverTimestamp() // Use serverTimestamp to set the current date and time
			}
		}

		try {
			promises.push(addDoc(collection(firestore, "TwilioMessages"), docData))
		} catch (e) {
			console.error("Send message error: ", e)
		}
	})

	await Promise.all(promises)
	return promises.length
}

async function sendViaAPP({ people, inputMessage, inputSubject, user, customerId }) {
	const userIDs = people.map(val => val.UID).filter(val => val)

	var promises = []

	userIDs.forEach(UID => {
		var myHeaders = new Headers()
		myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
		myHeaders.append("Content-Type", "application/json")
		var raw = JSON.stringify({
			topic: UID + "-messages",
			title: inputSubject,
			body: inputMessage,
			sentFrom: "crowdkeep_broadcast",
			sentById: user.id,
			sentByEmail: user.email,
			to: "",
			toEmail: "",
			customerId: customerId
		})
		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow"
		}
		promises.push(
			fetch(`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/send-notification`, requestOptions)
		)
	})

	await Promise.all(promises)
	return promises.length
}
