import React, { useState, useEffect } from "react"
import CloseIcon from "@material-ui/icons/Close"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/admin/inventory/InventoryActions"
import { Button } from "../../../../_partials/Button"
import { IconButton, makeStyles } from "@material-ui/core"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { Autocomplete_People } from "../../../../_partials/inputs/Autocomplete_PeopleTable"
import { Input } from "../../../../_partials/inputs/Input"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import "../Inventory.css"
import { startButtonProgress, finishButtonProgress } from "../../utils/utils"
import { Modal } from "react-bootstrap"
import { Bold } from "../../../../_partials/typography"

const EditSchema = Yup.object().shape({
	notes: Yup.string().nullable().trim().min(1, "Notes must not be empty")
})

const initialValues = {
	firmware: "",
	vendor: "",
	model: "",
	notes: "",
	min: "",
	max: "",
	quantity: ""
}

const useStyles = makeStyles(() => ({
	autocomplete: {
		width: "100%"
	},
	select: {
		borderColor: "red",
		height: "36px",
		backgroundColor: "#F2F2F2",
		borderRadius: "5px",

		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "transparent"
		},
		"& .MuiInputBase-input": {
			fontWeight: "500"
		}
	}
}))

const NewNodeModal = ({ showNewNodeModal, setShowNewNodeModal, technology }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	// ━━━━━━━━━━━━━  useState  ━━━━━━━━━━━━━ \\
	const [selectedModel, setSelectedModel] = useState("")
	const [selectedModelNodeType, setSelectedModelNodeType] = useState("")
	const [selectedFirmware, setSelectedFirmware] = useState("")
	const [selectedVendor, setSelectedVendor] = useState("")
	const [modelList, setModelList] = useState([])
	const [firmwareList, setFirmwareList] = useState([])
	const [firmwareVendorList, setFirmwareVendorList] = useState([])
	const [isGateway, setIsGateway] = useState(false)
	const [quantity, setQuantity] = useState(null)
	const [suggestionRange, setSuggestionRange] = useState(null)
	const [loadingSuggestion, setLoadingSuggestion] = useState(true)
	const [saving, setSaving] = useState(false)
	const [minAndMaxState, setMinAndMaxState] = useState({ min: false, max: false })
	// ━━━━━━━━━━━━━  Selectors  ━━━━━━━━━━━━━ \\
	const { nodesInventory, modelsInventory, firmwaresInventory, listLoading, user } = useSelector(
		state => ({
			nodesInventory: state.inventory.nodesInventory,
			modelsInventory: state.inventory.modelsInventory,
			firmwaresInventory: state.inventory.firmwaresInventory,
			listLoading: state.inventory.listLoading,
			user: state.auth?.user
		}),

		shallowEqual
	)

	// ━━━━━━━━━━━━━  useEffect  ━━━━━━━━━━━━━ \\
	// Effect 1: Update model list
	useEffect(() => {
		// console.log("Effect 1")
		if (!nodesInventory || !technology) return

		const listForModel = modelsInventory && modelsInventory.length > 0 && modelsInventory

		if (selectedModelNodeType) {
			if (selectedModelNodeType === "gateway") setIsGateway(true)
			else setIsGateway(false)

			setSelectedModel("")
			setModelList(listForModel.filter(model => model.nodeType === selectedModelNodeType))
			return
		}

		setModelList(listForModel)
	}, [nodesInventory, technology, selectedModelNodeType])

	// Effect 2: Update firmware list based on selected model
	useEffect(() => {
		// console.log("Effect 2")
		if (!selectedModel || !nodesInventory || !technology) {
			setSelectedVendor("")
			setSelectedFirmware("")
			return
		}

		const listForFirmwareVendor =
			firmwaresInventory &&
			firmwaresInventory.length > 0 &&
			firmwaresInventory.filter(firmware => firmware.modelId === selectedModel.id)

		// Create a Set to store unique vendor names
		const uniqueVendors = new Set()
		const filteredFirmwareList =
			listForFirmwareVendor &&
			listForFirmwareVendor.filter(firmware => {
				if (!uniqueVendors.has(firmware.vendor)) {
					uniqueVendors.add(firmware.vendor)
					return true
				}
				return false
			})

		setFirmwareVendorList(filteredFirmwareList)

		// Reset selected vendor if it's not available in the new firmware list
		if (
			filteredFirmwareList &&
			filteredFirmwareList.length > 0 &&
			filteredFirmwareList.some(firmware => firmware.vendor === "Crowdkeep")
		) {
			setSelectedVendor("Crowdkeep")
		} else {
			setSelectedVendor("")
			setSelectedFirmware("")
		}
	}, [selectedModel])

	// Effect 3: Filter and select the latest firmware version based on selected vendor
	useEffect(() => {
		// console.log("Effect 3")
		if (!selectedVendor || !firmwareVendorList) {
			setSelectedFirmware("")
			return
		}

		// Filter firmware list based on selected vendor
		const filteredFirmwareList =
			firmwaresInventory &&
			firmwaresInventory.length > 0 &&
			firmwaresInventory.filter(firmware => firmware.vendor === selectedVendor)

		// Sort firmware list based on version numbers
		const sortedFirmwareList =
			filteredFirmwareList &&
			filteredFirmwareList.length > 0 &&
			filteredFirmwareList.sort((a, b) => {
				const versionToNumber = version => Number(version.replace(/\./g, ""))
				return versionToNumber(a.version) > versionToNumber(b.version) ? -1 : 1
			})

		// Select the latest firmware version
		if (sortedFirmwareList.length > 0) {
			setSelectedFirmware(sortedFirmwareList[0].version)
		} else setSelectedFirmware("")

		// Update firmware list state
		setFirmwareList(sortedFirmwareList)
	}, [selectedVendor])

	//given suggestion range after quantity input
	useEffect(() => {
		setLoadingSuggestion(true) // Set loading to true initially

		// If quantity or nodesInventory is empty, return early
		if (!quantity || parseInt(quantity) <= 0 || !nodesInventory) {
			setSuggestionRange([]) // Reset suggestion range
			setLoadingSuggestion(false) // Reset loading to false
			return
		}

		const existingNodes = nodesInventory.map(node => parseInt(node.node_address))
		existingNodes.sort((a, b) => a - b) // Sort existing node addresses

		const maxAddress = 4294967294 // Maximum address limit

		let auxRange = []

		for (let i = 1; i <= maxAddress; i++) {
			if (auxRange && auxRange?.length > 0 && auxRange.length == parseInt(quantity)) break //stop when range length equals and parseInt(quantity)

			if (existingNodes.includes(i)) continue //next iteration if value exists

			auxRange.push(i) //new value for suggestion range

			let lastInserted = auxRange[auxRange.length - 2]

			//if last inserted equals actual - 1 then its sequential
			if (auxRange.length > 0) {
				if (auxRange.length > 1 && lastInserted != i - 1) {
					auxRange = []
					auxRange.push(i)
				} else {
					if (auxRange && auxRange?.length > 0 && auxRange.length == parseInt(quantity)) break
					else continue
				}
			}
		}
		setSuggestionRange([auxRange[0], auxRange[auxRange.length - 1]]) // Set suggestion range
		setLoadingSuggestion(false) // Set loading to false after processing
	}, [quantity, nodesInventory])

	//  ━━━━━━━ Handlers ━━━━━━━

	const onHide = () => {
		setShowNewNodeModal(false)
	}

	const onSubmit = async values => {
		const { min, max, notes } = values

		const existsNode =
			nodesInventory &&
			technology &&
			nodesInventory.length > 0 &&
			nodesInventory.some(node => node.node_address >= min && node.node_address <= max)

		if ((!parseInt(min) || !parseInt(max)) && !suggestionRange?.length > 0) {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: { message: "Nodes range must be defined." }
			})
			return
		}

		if (quantity && parseInt(quantity) <= 0) {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: { message: "Quantity must be greater than 0." }
			})
			return
		}

		if (!quantity && existsNode) {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: { message: "A node with an address within that range already exists." }
			})
			return
		}

		if (!technology || !user) return

		setSaving(true)
		const buttonToProgressEl = document.getElementById("buttonToProgress_editPoi")
		buttonToProgressEl &&
			startButtonProgress(buttonToProgressEl, "button__progress_editPoi", "button__text_editPoi")

		// Find the firmware object matching the selected version and vendor
		const firmwareObject =
			firmwaresInventory &&
			firmwaresInventory.length > 0 &&
			firmwaresInventory.find(
				firm => firm.version === selectedFirmware && firm.vendor === selectedVendor
			)

		// Extract the ID from the firmware object
		const firmwareId = firmwareObject.id

		//range of nodes to create
		const range = [parseInt(min), parseInt(max)]

		if (selectedModelNodeType == "gateway") {
			dispatch(
				actions.createNodes({
					technology: technology,
					data: {
						firmwareId: firmwareId,
						modelId: selectedModel.id,
						gatewayQuantity: parseInt(quantity),
						notes: notes,
						callersUserId: user.id
					},
					onHide
				})
			)
				.then(() => {
					setSaving(false)
					onHide()
				})
				.catch(error => {
					console.error("Error saving:", error) // Handle error
					setSaving(false) // Reset saving state on error
				})
		} else {
			dispatch(
				actions.createNodes({
					technology: technology,
					data: {
						firmwareId: firmwareId,
						modelId: selectedModel.id,
						uuidRange:
							suggestionRange && suggestionRange.length > 1 ? suggestionRange : range,
						notes: notes,
						callersUserId: user.id
					},
					onHide
				})
			)
				.then(() => {
					setSaving(false)
					onHide()
				})
				.catch(error => {
					console.error("Error saving:", error) // Handle error
					setSaving(false) // Reset saving state on error
				})
		}
	}

	return (
		<Modal show={showNewNodeModal} onHide={onHide} backdrop="static" size="lg">
			<Modal.Header>
				<div
					className="d-flex w-100 align-items-center justify-content-between"
					data-testid="NewNodeModel"
				>
					<div>
						<SVG
							// className="MUICustom_secondary_svg"
							style={{
								fill: "#1C1C3B",
								marginRight: "10px"
							}}
							src={toAbsoluteUrl("/media/svg/icons/General/cube.svg")}
						/>
						<Bold>New Node</Bold>
					</div>
					<div className="col-auto">
						<IconButton
							onClick={onHide}
							style={{ left: "12px" }}
							data-testid="close-button"
							disabled={saving}
						>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</Modal.Header>
			<Modal.Body
				style={{
					padding: "1rem",
					overflow: "hidden"
				}}
			>
				{/* PDF / Excel selector */}
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={EditSchema}
					onSubmit={values => {
						onSubmit(values)
					}}
					data-testid="NewNodeModel"
				>
					{({ setFieldValue, handleSubmit, dirty, values }) => {
						return (
							<Form className="form form-label-right p-3" data-testid="NewNodeModel">
								<div className="form-group row mb-5">
									<div className="drawer_title_container d-flex w-100 pl-3 pt-3 mb-3 align-items-center justify-content-between">
										<span>
											Node Address <sup style={{ color: "red" }}> *</sup>
										</span>
									</div>
								</div>
								<div className="form-group row mb-5">
									<div className="col">
										<Field
											id="model-autocomplete"
											customColor="#ECF1F4"
											name="modelNodeType"
											label="Model Node Type"
											component={Autocomplete_People}
											// requiredField
											placeholder="Model Node Type"
											options={
												modelList.length > 0
													? [...new Set(modelList.map(val => val.nodeType))].map(
															nodeType => ({
																value: nodeType,
																name: nodeType
															})
													  )
													: []
											}
											value={selectedModelNodeType}
											onChange={(event, newValue) => {
												if (!newValue) {
													setSelectedModelNodeType("")
													setFieldValue("modelNodeType", "")
												} else {
													setSelectedModelNodeType(newValue)
													setFieldValue("modelNodeType", newValue)
												}
											}}
											getOptionLabel={option => {
												// Ensure `option` is an object and `option.value` exists
												return option || "" // Return an empty string if name is null/undefined
											}}
										/>
									</div>
									<div className="col">
										<Field
											id="model-autocomplete"
											customColor="#ECF1F4"
											name="model"
											label="Model"
											component={Autocomplete_People}
											requiredField
											placeholder="Model"
											options={
												modelList.length > 0
													? modelList.map(val => {
															const { name, vendor, battery, features, id } = val

															// Create an array of feature strings
															const featureStrings = Object.entries(features).map(
																([key, value]) => `${key}: ${value}`
															)
															const featuresText = featureStrings.join(", ")

															const tooltipText = `Battery: ${battery}, Features: ${featuresText}`

															return {
																value: name,
																name,
																group: vendor,
																tooltip: tooltipText,
																id: id
															}
													  })
													: []
											}
											grouped={true}
											value={selectedModel}
											onChange={(event, newValue) => {
												if (!newValue) {
													setSelectedModel("")
													setFieldValue("model", "")
												} else {
													setSelectedModel(newValue)
													setFieldValue("model", newValue)
												}
											}}
											getOptionLabel={option => {
												// Ensure `option` is an object and `option.value` exists
												return option?.name || "" // Return an empty string if name is null/undefined
											}}
										/>
									</div>
								</div>
								<div className="form-group row mb-5">
									<div className="col-6">
										<Field
											id="vendor-autocomplete"
											customColor="#ECF1F4"
											name="vendor"
											label="Firmware Vendor"
											disabled={!selectedModel || saving}
											component={Autocomplete_People}
											requiredField
											placeholder="Firmware Vendor"
											options={
												firmwareVendorList && firmwareVendorList.length > 0
													? firmwareVendorList.map(val => {
															const { vendor } = val
															return { value: vendor, name: vendor }
													  })
													: []
											}
											value={selectedVendor}
											onChange={(event, newValue) => {
												if (!newValue) {
													setSelectedVendor("")
													setFieldValue("vendor", "")
												} else {
													setSelectedVendor(newValue)
													setFieldValue("vendor", newValue)
												}
											}}
											getOptionLabel={option => {
												// Ensure `option` is an object and `option.value` exists
												return option || "" // Return an empty string if name is null/undefined
											}}
										/>
									</div>
									<div className="col-6">
										<Field
											id="firmware-autocomplete"
											customColor="#ECF1F4"
											name="firmware"
											label="Firmware"
											disabled={!selectedVendor || saving}
											component={Autocomplete_People}
											requiredField
											placeholder="Firmware"
											options={
												firmwareList && firmwareList.length > 0
													? firmwareList.map(val => {
															return { value: val.id, name: val.version }
													  })
													: []
											}
											value={selectedFirmware}
											onChange={(event, newValue) => {
												if (!newValue) {
													setSelectedFirmware("")
													setFieldValue("firmware", "")
												} else {
													setSelectedFirmware(newValue)
													setFieldValue("firmware", newValue)
												}
											}}
											getOptionLabel={option => {
												// Ensure `option` is an object and `option.value` exists
												return option || "" // Return an empty string if name is null/undefined
											}}
										/>
									</div>
								</div>
								<div className="form-group row mb-5">
									<div className="col">
										<Field
											variant="filled"
											name="quantity"
											disabled={minAndMaxState.min || minAndMaxState.max}
											label="Quantity"
											type="number"
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true,
												inputProps: { min: 1, max: 4294967294 }
											}}
											placeholder="Enter quantity"
											onChange={event => {
												if (!event.target.value) {
													setFieldValue("quantity", "")
													setQuantity(null)
												} else {
													setFieldValue("quantity", event.target.value)
													setQuantity(event.target.value)
												}
											}}
											helperText={
												quantity && quantity <= 0
													? `Quantity must be greater than 0.`
													: selectedModelNodeType && selectedModelNodeType == "gateway"
													? ""
													: suggestionRange && suggestionRange.length > 0
													? `Suggestion range: [${suggestionRange[0]},${suggestionRange[1]}]`
													: ""
											}
										/>
									</div>
									<div className="col">
										<Field
											variant="filled"
											disabled={isGateway || quantity ? true : false}
											name="min"
											label="Start"
											type="number"
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true,
												inputProps: { min: 1, max: 4294967294 }
											}}
											placeholder="Enter min"
											onChange={event => {
												if (!event.target.value) {
													setFieldValue("min", "")
													setMinAndMaxState({
														...minAndMaxState,
														min: false
													})
												} else {
													setFieldValue("min", event.target.value)
													setMinAndMaxState({
														...minAndMaxState,
														min: true
													})
												}
											}}
											helperText="Minimum start value is 1"
										/>
									</div>
									<div className="col">
										<Field
											variant="filled"
											disabled={isGateway || quantity ? true : false}
											name="max"
											label="End"
											type="number"
											size="small"
											component={Input}
											InputProps={{
												disableUnderline: true,
												inputProps: { min: 1, max: 4294967294 }
											}}
											placeholder="Enter max"
											onChange={event => {
												if (!event.target.value) {
													setFieldValue("max", "")
													setMinAndMaxState({
														...minAndMaxState,
														max: false
													})
												} else {
													setFieldValue("max", event.target.value)
													setMinAndMaxState({
														...minAndMaxState,
														max: true
													})
												}
											}}
											helperText="Maximum end value is 4294967294"
										/>
									</div>
								</div>
								<div className="form-group row mb-0">
									<div className="col" style={{ marginBottom: "2rem" }}>
										<Field
											variant="filled"
											name="notes"
											label="Notes"
											disabled={saving}
											multiline
											rows={4} // Adjust the number of rows as needed
											component={Input}
											InputProps={{
												disableUnderline: true,
												style: { height: "auto", padding: "6px 12px" }
											}}
											data-testid="Formik_Form_Notes"
										/>
									</div>
								</div>
								<div className="drawer_title_container d-flex w-100 p-3 align-items-center justify-content-end">
									<Button
										color="primary"
										variant="outlined"
										text="Cancel"
										onClick={() => {
											setShowNewNodeModal(false)
										}}
										disabled={saving}
									/>
									<Button
										disabled={
											!selectedModel ||
											!selectedVendor ||
											!selectedFirmware ||
											loadingSuggestion ||
											((!minAndMaxState.min || !minAndMaxState.max) && !suggestionRange)
										}
										variant="contained"
										style={{
											marginLeft: "12px",
											// fontFamily: "Poppins",
											fontWeight: "700",
											backgroundColor: "#3077d3",
											color: "#f4f4f4"
										}}
										// text="Save"
										onClick={() => {
											// Starts the change of the saving UI button like progress bar
											// const buttonToProgressEl = document.getElementById(
											// 	"buttonToProgress_editPoi"
											// )
											// buttonToProgressEl &&
											// 	startButtonProgress(
											// 		buttonToProgressEl,
											// 		"button__progress_editPoi",
											// 		"button__text_editPoi"
											// 	)

											handleSubmit()
										}}
										className="ml-2"
										id="buttonToProgress_editPoi"
										data-progress-text="Saving..."
										data-complete-text="Save complete."
									>
										<div className="button__progress_editPoi"></div>
										<span className="button__text_editPoi">Save</span>
									</Button>
								</div>
							</Form>
						)
					}}
				</Formik>
			</Modal.Body>
		</Modal>
	)
}

export default NewNodeModal
