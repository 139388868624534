import React from "react"
import { makeStyles, TextField, FormHelperText, Button } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
	textField: {
		//  marginTop: "2px",
		backgroundColor: "#FFFFFF",
		borderRadius: "4px",
		margin: 0,

		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "4px"
		},
		"&:focus .MuiOutlinedInput-notchedOutline": {
			borderColor: "red",
			borderRadius: "4px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "4px"
		},
		"& .MuiOutlinedInput-root": {
			"&:focus fieldset": {
				borderColor: "red"
			}
		},

		"& .MuiOutlinedInput-adornedStart": {
			height: "40px",
			border: "none"
		},

		// -------------- \\
		// --- Filled --- \\
		"& .MuiFilledInput-root": {
			backgroundColor: "#ECF1F4",
			borderColor: "rgba(0,0,0,0)",
			borderRadius: "4px"
		},

		"& .MuiFilledInput-inputMarginDense": {
			padding: "0 15px",
			height: "40px",
			verticalAlign: "middle"
		}
	},
	textFieldError: {
		border: "1px solid #F64E60"
	},
	textFieldDefaultSize: {
		"& .MuiInputBase-root": {
			height: "45px",
			padding: "0px 12px",
			display: "flex",
			alignItems: "center"
		},
		"& .MuiInputBase-input": {
			padding: "0"
		}
	},
	helperText: {
		margin: 0,
		color: theme.palette.error.main
	},
	textFieldWithAppend: {
		marginTop: "2px",
		backgroundColor: "#FFFFFF",
		borderRadius: "4px",
		margin: 0,
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "4px",
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "4px",
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0
		},
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0
	},
	button: {
		marginTop: "2px",
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		textTransform: "none",
		float: "right",
		backgroundColor: "#3077d3",
		color: "#f4f4f4",
		padding: "5px",
		fontWeight: "600",
		"&:hover": {
			backgroundColor: "#5d95dc",
			color: "#f4f4f4"
		},
		"&:active": {
			backgroundColor: "#6d9fe0",
			color: "#f4f4f4"
		}
	}
}))

export function SearchBarCustom1({
	field,
	form,
	label,
	withFeedbackLabel = true,
	appendButton,
	size,
	placeholder,
	onSearch,
	onClear,
	searchText,
	...props
}) {
	if (field && form) {
		var { name, value } = field
		var { touched, errors, setFieldValue, setFieldTouched } = form
	}
	const classes = useStyles()
	const error = withFeedbackLabel && touched && touched[name] && errors && errors[name]

	return (
		<>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<TextField
					placeholder={placeholder}
					error={error ? true : false}
					fullWidth
					variant="outlined"
					size={size}
					className={clsx(
						classes.textField,
						error && classes.textFieldError,
						!size && classes.textFieldDefaultSize,
						appendButton && classes.textFieldWithAppend
					)}
					onChange={e => {
						onSearch(e.target.value)
					}}
					{...props}
				/>
				{appendButton && (
					<Button
						style={{ width: props.width ? props.width : "100%" }}
						disableElevation
						variant="contained"
						className={classes.button}
						onClick={appendButton.onClick || null}
					>
						<b
							style={{
								width: "100%",
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis"
							}}
						>
							{appendButton.text}
						</b>
					</Button>
				)}
			</div>
		</>
	)
}
