import React, { useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Redirect } from "react-router-dom"
import { Header } from "./header/Header"
import AnchorsPositioningPage from "./anchors-positioning/AnchorsPositioningPage"
import SensorsPage from "./sensors/SensorsPage"

export const MapInfrastructurePage = ({ history }) => {
	const [page, setPage] = useState("network")

	const { selectedSite, userClaims, permissions } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite,
			userClaims: state.auth.claims,
			permissions: state.auth?.permissions?.permissions
		}),
		shallowEqual
	)

	if (!selectedSite) return <Redirect exact={true} to="/dashboard" />
	return (
		<>
			<Header page={page} setPage={setPage} />
			{selectedSite &&
				page === "network" &&
				permissions?.Operations?.MapInfrastructure?.Network && (
					<AnchorsPositioningPage setPage={setPage} />
				)}
			{selectedSite &&
				page === "sensors" &&
				permissions?.Operations?.MapInfrastructure?.Sensors && (
					<SensorsPage setPage={setPage} />
				)}
		</>
	)
}
