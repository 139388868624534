import React, { useState, useEffect, memo } from "react"
import {
	Drawer,
	List,
	ListItem,
	ListItemText,
	IconButton,
	ListItemIcon,
	Collapse,
	SvgIcon
} from "@material-ui/core"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { useHistory, useLocation } from "react-router-dom"
import { Brand } from "./brand/Brand"
import { makeStyles } from "@material-ui/core/styles"

const MemoizedListItem = memo(ListItem)
const MemoizedListItemIcon = memo(ListItemIcon)
const MemoizedListItemText = memo(ListItemText)

// Define styles
const useStyles = makeStyles({
	listRoot: {
		paddingBottom: "0 "
	},
	mainDrawer: {
		background: "#0A1B3D",
		// maxWidth: 295, // Adjust the width for the main drawer here
		maxWidth: "16%",
		zIndex: 98,
		/* Typo */
		color: "#8C8CA1",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "160%"
	},
	mainDrawerClosed: {
		background: "#0A1B3D",
		width: 85, // Adjust the width for the main drawer here
		overflow: "hidden",
		/* Typo */
		color: "#8C8CA1",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "160%"
	},
	nestedDrawer: {
		position: "fixed",
		background: "#DDE9FD",
		//left: 275, // Adjust this value based on the width of your main drawer
		left: "16%",
		width: 235, // Width of the nested drawer

		/* Typo */
		color: "#8C8CA1",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "160%"
	},
	mainDrawerClosedNestedDrawer: {
		position: "fixed",
		background: "#DDE9FD",
		left: 85, // Adjust this value based on the width of your main drawer
		width: 220, // Width of the nested drawer

		/* Typo */
		color: "#8C8CA1",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "160%"
	},
	listItem: {
		"&:hover": {
			backgroundColor: "#1A2C4B",
			color: "#FFFFFF"
		},
		"& .MuiListItemText-root": {
			marginLeft: "12px"
		},
		"& .MuiListItemIcon-root": {
			color: "#4A4A68",
			minWidth: "10px"
		}
	},
	nestedListItem: {
		// "&:not(:first-child):hover": {
		// 	backgroundColor: "#C6DBFD",
		// 	color: "#0A1B3D"
		// },
		"&:hover": {
			backgroundColor: "#C6DBFD",
			color: "#0A1B3D"
		},
		"& .MuiListItemText-root": {
			marginLeft: "10px",
			color: "#0A1B3D"
		},
		"& .MuiListItemIcon-root": {
			color: "#0A1B3D"
		},
		"& .MuiTypography-body1": {
			fontWeight: "500",
			fontSize: "14px"
		}
	},
	nestedListItemHeader: {
		"& .MuiListItemText-root": {
			color: "#0A1B3D"
		},
		"& .MuiListItemIcon-root": {
			color: "#0A1B3D"
		},
		"& .MuiTypography-body1": {
			fontWeight: "500",
			fontSize: "14px"
		}
	},
	selectedListItem: {
		backgroundColor: "#1A2C4B",
		color: "#FFFFFF"
	},
	selectedNestedListItem: {
		backgroundColor: "#C6DBFD",
		color: "#FFFFFF"
	},
	selectedListIcon: {
		"& .MuiSvgIcon-root": {
			color: "#FFFFFF"
		},
		"& .MuiSvgIcon-root svg": {
			fill: "#2673F0"
		}
	},
	iconSize: {
		"& .MuiSvgIcon-root": {
			width: "1.2em",
			height: "1.2em"
		}
	}
})

// Sidebar component
const Sidebar = ({ options, hideDrawer, handleHideDrawer, matches }) => {
	const classes = useStyles()
	const history = useHistory()
	const location = useLocation()

	const [isNestedDrawerOpen, setNestedDrawerOpen] = useState(false)
	const [currentNestedOptions, setCurrentNestedOptions] = useState([])
	const [selectedOption, setSelectedOption] = useState(null)
	const [selectedNestedOption, setSelectedNestedOption] = useState(null)
	const [selectedMainOption, setSelectedMainOption] = useState(null)
	const [mainDrawerOpened, setMainDrawerOpened] = useState(true)
	const [open, setOpen] = useState(false)

	const handleClick = () => {
		setOpen(!open)
	}

	// Handle click on main options
	const handleMainOptionClick = option => {
		setSelectedMainOption(option.label.redirectTo)
		if (option.nestedOptions) {
			setSelectedNestedOption(option)
			setCurrentNestedOptions(option.nestedOptions)
			setNestedDrawerOpen(true)
		} else if (option.label.redirectTo) {
			history.push(option.label.redirectTo)
			!matches && handleHideDrawer()
		}
	}

	const handleOpenDrawer = () => {
		setMainDrawerOpened(prev => !prev)
	}

	// Handle click on nested options
	const handleNestedOptionClick = redirectTo => {
		setSelectedOption(redirectTo)
		setNestedDrawerOpen(false) // Close the nested drawer
		history.push(redirectTo)
		!matches && handleHideDrawer()
	}

	// Toggle nested drawer open/close
	const toggleNestedDrawer = () => {
		setNestedDrawerOpen(!isNestedDrawerOpen)
	}

	const NestedListItem = ({
		nestedOption,
		handleNestedOptionClick,
		selectedOption,
		classes,
		shouldHaveBullet = false
	}) => {
		return (
			<ListItem
				button
				onClick={() => handleNestedOptionClick(nestedOption.redirectTo)}
				className={
					selectedOption === nestedOption.redirectTo
						? `${classes.nestedListItem} ${classes.selectedNestedListItem}`
						: classes.nestedListItem
				}
			>
				<ListItemText
					primary={nestedOption.title}
					className={classes.listItemText}
					style={
						!shouldHaveBullet
							? null
							: {
									display: "list-item",
									listStyleType: "disc",
									marginLeft: "30px"
							  }
					}
				/>
				{/* <ListItemIcon style={{ placeContent: "end", marginRight: "6px" }}>
				<ArrowForwardIosIcon fontSize="small" />
			</ListItemIcon> */}
			</ListItem>
		)
	}

	const renderNestedOptions = nestedOption => {
		if (nestedOption.nestedOptions) {
			return (
				<List component="div" disablePadding className={classes.listRoot}>
					{nestedOption.nestedOptions.map((nestedOption, nestedIndex) => (
						<NestedListItem
							key={nestedIndex}
							nestedOption={nestedOption}
							handleNestedOptionClick={handleNestedOptionClick}
							selectedOption={selectedOption}
							classes={classes}
							shouldHaveBullet={true}
						/>
					))}
				</List>
			)
		}
		return null
	}

	useEffect(() => {
		// Set the selected option based on the current URL
		const currentPath = location.pathname

		const findSelectedOption = options => {
			for (let option of options) {
				if (option.label.redirectTo === currentPath) {
					setSelectedMainOption(option.label.redirectTo)
					return option.label.redirectTo
				}

				if (option.nestedOptions) {
					for (let nestedOption of option.nestedOptions) {
						if (nestedOption.redirectTo === currentPath) {
							setSelectedMainOption(option.label.redirectTo)
							return nestedOption.redirectTo
						}

						if (nestedOption.nestedOptions) {
							const deepNestedOption = nestedOption.nestedOptions.find(
								deepOption => deepOption.redirectTo === currentPath
							)
							if (deepNestedOption) {
								setSelectedMainOption(option.label.redirectTo)
								return deepNestedOption.redirectTo
							}
						}
					}
				}
			}
			return null
		}

		const selected = findSelectedOption(options)
		setSelectedOption(selected)

		// Don't automatically open the nested drawer on initial load
	}, [location, options, isNestedDrawerOpen])

	return (
		<>
			{/* Main Drawer */}
			<Drawer
				anchor="left"
				variant="persistent"
				open={hideDrawer} // Always open
				classes={{
					paper: matches && mainDrawerOpened ? classes.mainDrawer : classes.mainDrawerClosed
				}} // Apply custom styles here
			>
				<Brand
					handleOpenDrawer={handleOpenDrawer}
					handleHideDrawer={handleHideDrawer}
					mainDrawerOpened={mainDrawerOpened}
					matches={matches}
				/>

				<List>
					{options.map((option, index) => (
						<ListItem
							button
							style={{ maxHeight: "42px" }}
							key={index}
							onClick={() => handleMainOptionClick(option)}
							className={
								selectedMainOption === option.label.redirectTo
									? `${classes.listItem} ${classes.selectedListItem}`
									: classes.listItem
							}
						>
							<ListItemIcon
								style={{ placeContent: "center", marginTop: "5px" }}
								className={
									selectedMainOption === option.label.redirectTo
										? `${classes.selectedListIcon} ${classes.iconSize}`
										: `${classes.iconSize}`
								}
							>
								{option.label.icon}
							</ListItemIcon>
							<ListItemText
								style={{
									textOverflow: "ellipsis",
									overflow: "hidden",
									whiteSpace: "nowrap"
								}}
								primary={option.label.title}
							/>
							<ListItemIcon
								style={{ placeContent: "end" }}
								className={
									selectedMainOption === option.label.redirectTo &&
									`${classes.selectedListIcon}`
								}
							>
								<ArrowForwardIosIcon />
							</ListItemIcon>
						</ListItem>
					))}
				</List>
			</Drawer>

			{/* Nested Drawer */}
			<Drawer
				anchor="left"
				open={isNestedDrawerOpen}
				onClose={toggleNestedDrawer}
				classes={{
					paper:
						matches && mainDrawerOpened
							? classes.nestedDrawer
							: classes.mainDrawerClosedNestedDrawer
				}}
			>
				<IconButton onClick={toggleNestedDrawer} style={{ alignSelf: "end" }}>
					<ArrowIcon />
				</IconButton>

				<List>
					{mainDrawerOpened && selectedNestedOption && (
						<MemoizedListItem className={classes.nestedListItemHeader}>
							<MemoizedListItemIcon
								className={`${classes.selectedListIcon} ${classes.iconSize}`}
								style={{ placeContent: "center", marginTop: "5px" }}
							>
								{selectedNestedOption.label.icon}
							</MemoizedListItemIcon>
							<MemoizedListItemText
								primary={selectedNestedOption.label.title}
								className={classes.listItemText}
							/>
						</MemoizedListItem>
					)}

					{currentNestedOptions.map((nestedOption, index) => {
						if (nestedOption.nestedOptions) {
							return (
								<React.Fragment key={nestedOption.title}>
									<MemoizedListItem
										button
										onClick={handleClick}
										className={
											selectedOption === nestedOption.redirectTo
												? `${classes.nestedListItem} ${classes.selectedNestedListItem}`
												: classes.nestedListItem
										}
									>
										<MemoizedListItemText
											primary={nestedOption.title}
											className={classes.listItemText}
										/>
										<MemoizedListItemIcon style={{ placeContent: "end" }}>
											{!open ? (
												<ExpandLess fontSize="large" />
											) : (
												<ExpandMore fontSize="large" />
											)}
										</MemoizedListItemIcon>
									</MemoizedListItem>
									<Collapse in={open} timeout="auto" unmountOnExit>
										{renderNestedOptions(nestedOption)}
									</Collapse>
								</React.Fragment>
							)
						}

						return (
							<NestedListItem
								key={nestedOption.title}
								nestedOption={nestedOption}
								handleNestedOptionClick={handleNestedOptionClick}
								selectedOption={selectedOption}
								classes={classes}
							/>
						)
					})}
				</List>
			</Drawer>
		</>
	)
}

export default Sidebar

function ArrowIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.5893 5.5886C10.9147 5.26317 10.9147 4.73553 10.5893 4.41009C10.2638 4.08466 9.73618 4.08466 9.41074 4.41009L4.41074 9.41009C4.09526 9.72558 4.08422 10.2336 4.3857 10.5625L8.96903 15.5625C9.28003 15.9017 9.80717 15.9246 10.1464 15.6136C10.4857 15.3027 10.5086 14.7755 10.1976 14.4362L6.15344 10.0244L10.5893 5.5886Z"
					fill="#1C1C3B"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16.4226 5.5886C16.748 5.26317 16.748 4.73553 16.4226 4.41009C16.0972 4.08466 15.5695 4.08466 15.2441 4.41009L10.2441 9.41009C9.9286 9.72558 9.91756 10.2336 10.219 10.5625L14.8024 15.5625C15.1134 15.9017 15.6405 15.9246 15.9798 15.6136C16.319 15.3027 16.342 14.7755 16.031 14.4362L11.9868 10.0244L16.4226 5.5886Z"
					fill="#1C1C3B"
				/>
			</svg>
		</SvgIcon>
	)
}
