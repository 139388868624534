import * as requestFromServer from "./manageGroupsCrud"
import { manageGroupsSlice, callTypes } from "./manageGroupsSlice"
import { convertCollectionSnapshotToMap } from "../_helpers/ActionHelpers"

const { actions } = manageGroupsSlice

export const fetchAllGroups = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	if (!queryParams) {
		dispatch(actions.groupsFetched([]))
	}

	return requestFromServer
		.getAllGroups(queryParams)
		.then(response => response.json())
		.then(result => {
			if (result) {
				dispatch(actions.groupsFetched(result))
			} else {
				dispatch(actions.groupsFetched([]))
			}
		})
		.catch(error => {
			console.log("Fetch groups: ", error)
			error.clientMessage = "Can't find groups"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const updateGroup = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return requestFromServer
		.updateGroup(queryParams)
		.then(response => response.json())
		.then(result => {
			const editedGroup = {
				//missing group name
				customerId: queryParams.customerId,
				sites: queryParams.sitesToAdd,
				members: queryParams.membersToAdd,
				id: queryParams.groupId
			}

			if (result && result.message === "Group updated successfully.") {
				dispatch(actions.groupUpdated(editedGroup))
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Group updated with success" }
				})
			} else {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error updating group" }
				})
			}
			queryParams.onHide()
		})
		.catch(error => {
			console.log("Update group: ", error)
			error.clientMessage = "Error updating groups"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const createGroup = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return requestFromServer
		.createGroup(queryParams)
		.then(response => response.json())
		.then(result => {
			const createdGroup = {
				id: result?.id,
				name: queryParams.name,
				customerId: queryParams.customerId,
				sites: queryParams.sites,
				members: queryParams.members
			}

			if (result && result.message === "Group created successfully") {
				dispatch(actions.groupCreated(createdGroup))
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Group created with success" }
				})
			} else {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error updating group" }
				})
			}
			queryParams.onHide()
		})
		.catch(error => {
			console.log("Create group: ", error)
			error.clientMessage = "Error creating groups"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const deleteGroups = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return requestFromServer
		.deleteGroups(queryParams)
		.then(response => response.json())
		.then(result => {
			if (result && result.message === "Groups deleted successfully.") {
				dispatch(actions.groupsDeleted(queryParams.groupId))
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Group deleted with success" }
				})
			} else {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error deleting group" }
				})
			}
			// queryParams.onHide()
		})
		.catch(error => {
			console.log("Delete group: ", error)
			error.clientMessage = "Error deleting groups"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const disableGroup = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return requestFromServer
		.disableGroup(queryParams)
		.then(response => response.json())
		.then(result => {
			if (result && result.message === "Group disabled successfully.") {
				dispatch(actions.groupDisabled(queryParams.groupId))
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Group disabled with success" }
				})
			} else {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error disabling group" }
				})
			}
			// queryParams.onHide()
		})
		.catch(error => {
			console.log("Disable group: ", error)
			error.clientMessage = "Error disabling groups"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const enableGroup = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return requestFromServer
		.enableGroup(queryParams)
		.then(response => response.json())
		.then(result => {
			if (result && result.message === "Group enabled successfully.") {
				dispatch(actions.groupEnabled(queryParams.groupId))
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Group enabled with success" }
				})
			} else {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error enabling group" }
				})
			}
			// queryParams.onHide()
		})
		.catch(error => {
			console.log("Enable group: ", error)
			error.clientMessage = "Error enabling groups"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchCustomerPeople = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	if (!queryParams) {
		dispatch(actions.customerPeopleFetched([]))
	}

	return requestFromServer
		.getAllPeople(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const people = response

			people && dispatch(actions.customerPeopleFetched(people))
		})
		.catch(error => {
			console.log("Fetch people: ", error)
			error.clientMessage = "Can't find people"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}
