import React from "react"
import * as turf from "@turf/turf"
import PathFinder from "geojson-path-finder"

// ━━━━━━━━━━━━━━━ Onchange Handler to find path ━━━━━━━━━━━━━━━ \\
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\
export function findPathOnChangeHandler(props) {
	// console.log("🚀  props:", props);

	const {
		dispatch,
		// map,
		// locations,
		floorPlan,
		endPoint,
		startPoint,
		//   devices,
		//   bleDevices,
		// setTravelTo,
		geoJsonRoutes,
		setPathFound,
		zones,
		setConnectorAccess,
		floorPlanData,
		// floorPlanId,
		typeByDefault,
		// setGetDirections,
		setCopyOfConnectorCoords,
		setPoiToDoorCoords,
		setPoiToDoorEndPointCoords,
		setSelectedConnector,
		setCopyOfStartPointCoords
	} = props

	let finalFeaturesLines = []
	let finalFeaturesPoints = []
	geoJsonRoutes &&
		geoJsonRoutes.features.forEach((feature, index) => {
			if (feature.geometry.type === "LineString") {
				let coordinates = []
				feature.geometry.coordinates.forEach(coords => {
					coordinates.push([coords.longitude, coords.latitude])
				})
				finalFeaturesLines.push({
					type: geoJsonRoutes.features[index].type,
					properties: {
						...geoJsonRoutes.features[index].properties
					},
					geometry: {
						type: geoJsonRoutes.features[index].geometry.type,
						coordinates: coordinates
					}
				})
			}
			if (feature.geometry.type === "Point") {
				let coordinates = []
				feature.geometry.coordinates.forEach(coords => {
					coordinates.push(coords.longitude, coords.latitude)
				})
				finalFeaturesPoints.push({
					type: geoJsonRoutes.features[index].type,
					properties: {
						...geoJsonRoutes.features[index].properties
					},
					geometry: {
						type: geoJsonRoutes.features[index].geometry.type,
						coordinates: coordinates
					}
				})
			}
		})

	// console.log("🚀 ~ finalFeaturesLines", finalFeaturesLines);
	// console.log("🚀 ~ finalFeaturesPoints", finalFeaturesPoints);

	const finalLayerLines = {
		type: "FeatureCollection",
		features: finalFeaturesLines
	}
	// console.log(finalLayerLines, "finalLayerLines");

	// ↓ turn lines into little chunks.
	const chunk = turf.lineChunk(finalLayerLines, 0.0005, {
		units: "kilometers"
	})
	// console.log("🚀 ~ chunk", chunk);

	const pathFinderOnSameFloor = new PathFinder(chunk, { precision: 1e-5 })
	// console.log("🚀 ~ pathFinderOnSameFloor", pathFinderOnSameFloor);

	const points = []

	for (const [key, value] of Object.entries(pathFinderOnSameFloor._graph.vertices)) {
		// console.log("Key: ", key);
		const latlong = key.split(",")
		const lng = parseFloat(latlong[0])
		const lat = parseFloat(latlong[1])
		points.push(turf.point([lng, lat]))
	}
	// console.log("Points: ", JSON.stringify(points));

	if (endPoint && startPoint) {
		// console.log("🚀🚀🚀🚀🚀  startPoint:", startPoint)
		// console.log("🎯🎯🎯🎯🎯  endPoint:", endPoint);

		// ↓ End Point (point selected where the user want to go)
		let destinationPointTurfed = null
		// ↓ Start Point (point selected where the user want to go)
		let startPointTurfed = null

		//━━━━━━━━━━━━━━━ EndPoint.group === "Points of Interest" ━━━━━━━━━━━━━━━\\
		//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\
		if (endPoint.group === "Points of Interest") {
			// resets the poiToDoorCoords state
			setPoiToDoorCoords()
			setPoiToDoorEndPointCoords()

			// ━━━━━━━━━━━━━━━ Endpoint on same floorPlan
			if (endPoint.floorPlanId === floorPlan.id) {
				// console.log("floorPlanId EQUAL Directions Control - endPoint");

				if (!floorPlan.geoJson) {
					return
				}

				// If POI is inside a polygon
				const poiInsidePolyCoords = []
				floorPlan &&
					floorPlan.geoJson.features.forEach(feature => {
						let coordinates = []
						if (feature.properties?.name === "Base Layer") {
							return
						}
						feature.geometry.coordinates.forEach(coords => {
							coordinates.push([coords.longitude, coords.latitude])
						})

						// console.log("🚀 ~ coordinates", coordinates);
						const poly = turf.polygon([coordinates])

						// console.log(
						//   turf.booleanPointInPolygon(
						//     [endPoint?.coords?.longitude, endPoint?.coords?.latitude],
						//     poly
						//   ),
						//   "BOOLEAN EXISTS"
						// );
						const existsInsidePolygon = turf.booleanPointInPolygon(
							[endPoint?.coords?.longitude, endPoint?.coords?.latitude],
							poly
						)
						if (existsInsidePolygon) {
							// console.log(feature, "feature that exists POI inside polygon");
							// console.log(value, "value that exists POI inside polygon");
							const fetchZoneWithMarkerCoords =
								zones && zones.find(zone => zone.id === feature.properties?.areaId)
							// console.log(
							//   "🚀 ~ fetchZoneWithMarkerCoords 3",
							//   fetchZoneWithMarkerCoords
							// );
							poiInsidePolyCoords.push(fetchZoneWithMarkerCoords)
						}
					})

				// console.log(poiInsidePolyCoords.length, "length");
				// console.log(poiInsidePolyCoords, "poiInsidePolyCoords");

				// if POI not inside a polygon
				if (poiInsidePolyCoords.length === 0) {
					destinationPointTurfed = turf.point([
						endPoint?.coords?.longitude,
						endPoint?.coords?.latitude
					])
				}

				// if POI is inside a polygon
				if (poiInsidePolyCoords.length !== 0) {
					setPoiToDoorEndPointCoords([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])

					destinationPointTurfed = turf.point([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])
				}

				// console.log("🚀  destinationPointTurfed:", destinationPointTurfed);
			}
			// ━━━━━━━━━━━━━━━ Endpoint not on same floorPlan
			if (endPoint.floorPlanId !== floorPlan.id) {
				// console.log("floorPlanId NOT EQUAL Directions Control - endPoint ");

				// ↓ ━━━━━ Find Connectors on other floor of end point
				const rightFeatures = []
				floorPlanData &&
					floorPlanData.forEach(floor => {
						floor.geoJsonRoutes?.features &&
							floor.geoJsonRoutes.features.forEach(f => {
								if (f.properties?.linkToFloors?.includes(endPoint.floorPlanId)) {
									// console.log(f, "f");

									let coords = []
									f.geometry.coordinates.forEach(coordinates => {
										coords.push(coordinates.longitude, coordinates.latitude)
									})
									let finalFeature = {
										...f,
										geometry: {
											...f.geometry,
											coordinates: coords
										}
									}
									rightFeatures.push(finalFeature)
								}
							})
					})
				// console.log(
				//   "🚀 ~ rightFeatures - Connectors on other floors, endPoint floor",
				//   rightFeatures
				// );

				// Find and choose the connector with the type select
				const selectedFeature =
					rightFeatures &&
					rightFeatures.filter(feature => feature.properties?.connectorType === typeByDefault)
				// console.log("🎃🎃🎃🎃 ~ selectedFeature", selectedFeature); // ← connector type selected by user
				// console.log("🚀 ~ typeByDefault", typeByDefault);

				// ↓ Get connectorPositionOnFloors coords in selected floor if they have this coords array, else use coords of geoJsonRoutes
				const connectorPositionInSelectedFloor =
					selectedFeature[0]?.properties?.connectorPositionOnFloors &&
					selectedFeature[0]?.properties?.connectorPositionOnFloors.find(
						marker => marker.floorPlanId === endPoint.floorPlanId
					)
				// console.log(
				//   "🚀 ~ connectorPositionInSelectedFloor",
				//   connectorPositionInSelectedFloor
				// );

				const connectorPositionFetchedCoords = connectorPositionInSelectedFloor && [
					connectorPositionInSelectedFloor.coords.longitude,
					connectorPositionInSelectedFloor.coords.latitude
				]
				// console.log(
				//   "🚀 ~ connectorPositionFetchedCoords",
				//   connectorPositionFetchedCoords
				// );

				// console.log(floorPlan.id, "FLOORPLAN ID 1 antes do showmap CLICK");

				const conditionalCoords = connectorPositionFetchedCoords
					? connectorPositionFetchedCoords
					: selectedFeature[0]?.geometry.coordinates
				// console.log("🚀 ~ conditionalCoords 1", conditionalCoords);

				// ↓ Copy this connector coords to state, to pass them to addGeoJsonLayerToMapWithNewPath()
				selectedFeature[0] && setCopyOfConnectorCoords(conditionalCoords)

				// ↓ Copy this connector coords to state, to pass them to addPathOnDifferentFloorplanWithStartingPoint()
				selectedFeature[0] && setSelectedConnector(selectedFeature[0])

				// Opens connectors markers so the user can click on them to travel to the next floorplan with the path
				// If startPoint and endPoint both on a different floorplan do not open the connector marker, else open the marker
				if (startPoint.floorPlanId !== floorPlan.id && endPoint.floorPlanId !== floorPlan.id) {
					setConnectorAccess({
						clicked: false,
						zIndex: "0",
						id: null
					})
					setCopyOfConnectorCoords()
					setSelectedConnector()
					return
				} else {
					selectedFeature[0] &&
						setConnectorAccess({
							clicked: true,
							zIndex: "2",
							id: selectedFeature[0].properties?.connectorType
						})

					// ↓ If connector type selected is linked to the endPoint floor condition
					const isLinked =
						selectedFeature[0] &&
						selectedFeature[0].properties?.linkToFloors.some(
							floor => floor === endPoint.floorPlanId
						)
					// console.log("🚀 ~ isLinked connector linked to this floor", isLinked);

					// Find end Point floor name
					const endPointName =
						floorPlanData && floorPlanData.find(f => f.id === endPoint.floorPlanId)
					// console.log("🚀 ~ endPointName", endPointName);

					// if the end point selected floorplan doesn't have a connector connected to the floorplan of starting point display message to user and return
					if (!isLinked) {
						const paragraphToaster = (
							<p style={{ textAlign: "center" }}>
								This connector, <b>{selectedFeature[0].properties?.connectorName}</b>, is
								not linked to {endPointName.name}. <br />
								Please select another connector.
							</p>
						)

						dispatch({
							type: "SNACKBAR_ERROR",
							payload: paragraphToaster
						})

						return
					}
				}
				return // don't create Path because its now done the rest on show map button
			}
		}
		//━━━━━━━━━━━━━━━ StartPoint.group === "Points of Interest" ━━━━━━━━━━━━━━━\\
		//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\
		if (startPoint.group === "Points of Interest") {
			// ━━━━━━━━━━━━━━━ Startpoint on same floorPlan
			if (startPoint.floorPlanId === floorPlan.id) {
				// console.log("🎃🎃🎃 floorPlanId EQUAL Directions Control - startPoint");

				if (!floorPlan.geoJson) {
					return
				}

				// If POI is inside a polygon
				const poiInsidePolyCoords = []
				floorPlan &&
					floorPlan.geoJson.features.forEach(feature => {
						let coordinates = []
						if (feature.properties?.name === "Base Layer") {
							return
						}
						feature.geometry.coordinates.forEach(coords => {
							coordinates.push([coords.longitude, coords.latitude])
						})

						// console.log("🚀 ~ coordinates", coordinates);
						const poly = turf.polygon([coordinates])
						// console.log(
						//   turf.booleanPointInPolygon(
						//     [startPoint.coords.longitude, startPoint.coords.latitude],
						//     poly
						//   ),
						//   "BOOLEAN EXISTS"
						// );
						const existsInsidePolygon = turf.booleanPointInPolygon(
							[startPoint.coords.longitude, startPoint.coords.latitude],
							poly
						)
						if (existsInsidePolygon) {
							// console.log(feature, "feature that exists POI inside polygon");
							// console.log(value, "value that exists POI inside polygon");
							const fetchZoneWithMarkerCoords =
								zones && zones.find(zone => zone.id === feature.properties?.areaId)
							// console.log(
							//   "🚀 ~ fetchZoneWithMarkerCoords 5",
							//   fetchZoneWithMarkerCoords
							// );
							poiInsidePolyCoords.push(fetchZoneWithMarkerCoords)
						}
					})

				// console.log(poiInsidePolyCoords.length, "length");
				// console.log(startPoint, "startPoint ");
				// console.log(poiInsidePolyCoords, "poiInsidePolyCoords");

				// if POI not inside a polygon
				if (poiInsidePolyCoords.length === 0) {
					startPointTurfed = turf.point([
						startPoint.coords.longitude,
						startPoint.coords.latitude
					])
				}

				// if POI is inside a polygon
				if (poiInsidePolyCoords.length !== 0) {
					setPoiToDoorCoords([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])

					startPointTurfed = turf.point([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])
				}
				// console.log("🚀  startPointTurfed:", startPointTurfed);
			}

			// ━━━━━━━━━━━━━━━ Startpoint not on same floorPlan
			if (startPoint.floorPlanId !== floorPlan.id) {
				// console.log(
				//   "🎃🎃🎃 floorPlanId NOT EQUAL Directions Control - startPoint"
				// );

				// ↓ ━━━━━ Find Connectors on other floor of start point
				const rightFeatures = []
				floorPlanData &&
					floorPlanData.forEach(floor => {
						floor.geoJsonRoutes?.features &&
							floor.geoJsonRoutes.features.forEach(f => {
								if (f.properties?.linkToFloors?.includes(startPoint.floorPlanId)) {
									// console.log(f, "f");

									let coords = []
									f.geometry.coordinates.forEach(coordinates => {
										coords.push(coordinates.longitude, coordinates.latitude)
									})
									let finalFeature = {
										...f,
										geometry: {
											...f.geometry,
											coordinates: coords
										}
									}
									rightFeatures.push(finalFeature)
								}
							})
					})
				// console.log(
				//   "🚀 ~ rightFeatures - Connectors on other floors, startpoint floor",
				//   rightFeatures
				// );

				// Find and choose the connector with the type select
				const selectedFeature =
					rightFeatures &&
					rightFeatures.filter(feature => feature.properties?.connectorType === typeByDefault)
				// console.log("🚀 ~ selectedFeature", selectedFeature); // ← connector type selected by user
				// console.log("🚀 ~ typeByDefault", typeByDefault);

				// ↓ Get connectorPositionOnFloors coords in selected floor if they have this coords array, else use coords of geoJsonRoutes
				const connectorPositionInSelectedFloor =
					selectedFeature[0]?.properties?.connectorPositionOnFloors &&
					selectedFeature[0]?.properties?.connectorPositionOnFloors.find(
						marker => marker.floorPlanId === startPoint.floorPlanId
					)
				// console.log(
				//   "🚀 ~ connectorPositionInSelectedFloor",
				//   connectorPositionInSelectedFloor
				// );

				const connectorPositionFetchedCoords = connectorPositionInSelectedFloor && [
					connectorPositionInSelectedFloor.coords.longitude,
					connectorPositionInSelectedFloor.coords.latitude
				]
				// console.log(
				//   "🚀 ~ connectorPositionFetchedCoords",
				//   connectorPositionFetchedCoords
				// );

				// console.log(floorPlan.id, "FLOORPLAN ID 1 antes do showmap CLICK");

				const conditionalCoords = connectorPositionFetchedCoords
					? connectorPositionFetchedCoords
					: selectedFeature[0]?.geometry.coordinates
				// console.log("🚀 ~ conditionalCoords 1", conditionalCoords);

				// ↓ Copy this connector coords to state, to pass them to addGeoJsonLayerToMapWithNewPath()
				selectedFeature[0] && setCopyOfConnectorCoords(conditionalCoords)

				// ↓ Copy this connector coords to state, to pass them to addPathOnDifferentFloorplanWithStartingPoint()
				selectedFeature[0] && setSelectedConnector(selectedFeature[0])

				// Opens connectors markers so the user can click on them to travel to the next floorplan with the path
				// If startPoint and endPoint both on a different floorplan do not open the connector marker, else open the marker
				if (startPoint.floorPlanId !== floorPlan.id && endPoint.floorPlanId !== floorPlan.id) {
					setConnectorAccess({
						clicked: false,
						zIndex: "0",
						id: null
					})
					setCopyOfConnectorCoords()
					setSelectedConnector()
					return
				} else {
					selectedFeature[0] &&
						setConnectorAccess({
							clicked: true,
							zIndex: "2",
							id: selectedFeature[0].properties?.connectorType
						})

					// ↓ If connector type selected is linked to the start point floor condition
					const isLinked =
						selectedFeature[0] &&
						selectedFeature[0].properties?.linkToFloors.some(
							floor => floor === startPoint.floorPlanId
						)
					// console.log("🚀 ~ isLinked connector linked to this floor", isLinked);

					// Find start Point floor name
					const startPointName =
						floorPlanData && floorPlanData.find(f => f.id === startPoint.floorPlanId)
					// console.log("🚀 ~ startPointName", startPointName);

					// if the start point selected floorplan doesn't have a connector connected to the floorplan of starting point display message to user and return
					if (!isLinked) {
						const paragraphToaster = (
							<p style={{ textAlign: "center" }}>
								This connector, <b>{selectedFeature[0].properties?.connectorName}</b>, is
								not linked to {startPointName.name}. <br />
								Please select another connector.
							</p>
						)

						dispatch({
							type: "SNACKBAR_ERROR",
							payload: paragraphToaster
						})

						return
					}
				}

				return // don't create Path because its now done the rest on show map button
			}
		}

		//━━━━━━━━━━━━━━━ EndPoint.group === "Zones" / Areas ━━━━━━━━━━━━━━━\\
		//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\
		if (endPoint.group === "Areas") {
			// resets the poiToDoorCoords state
			setPoiToDoorCoords()
			setPoiToDoorEndPointCoords()

			// ━━━━━━━━━━━━━━━ Endpoint on same floorPlan
			if (endPoint.floorPlanId === floorPlan.id) {
				// console.log("floorPlanId EQUAL Directions Control - endPoint");

				destinationPointTurfed = turf.point([
					endPoint.doorMarker?.longitude,
					endPoint.doorMarker?.latitude
				])
			}
			// ━━━━━━━━━━━━━━━ Endpoint not on same floorPlan
			if (endPoint.floorPlanId !== floorPlan.id) {
				// console.log("floorPlanId NOT EQUAL Directions Control - endPoint ");

				// ↓ ━━━━━ Find Connectors on other floor of end point
				const rightFeatures = []
				floorPlanData &&
					floorPlanData.forEach(floor => {
						floor.geoJsonRoutes?.features &&
							floor.geoJsonRoutes.features.forEach(f => {
								if (f.properties?.linkToFloors?.includes(endPoint.floorPlanId)) {
									// console.log(f, "f");

									let coords = []
									f.geometry.coordinates.forEach(coordinates => {
										coords.push(coordinates.longitude, coordinates.latitude)
									})
									let finalFeature = {
										...f,
										geometry: {
											...f.geometry,
											coordinates: coords
										}
									}
									rightFeatures.push(finalFeature)
								}
							})
					})
				// console.log(
				//   "🚀 ~ rightFeatures - Connectors on other floors, endPoint floor",
				//   rightFeatures
				// );

				// Find and choose the connector with the type select
				const selectedFeature =
					rightFeatures &&
					rightFeatures.filter(feature => feature.properties?.connectorType === typeByDefault)
				// console.log("🎃🎃🎃🎃 ~ selectedFeature", selectedFeature); // ← connector type selected by user
				// console.log("🚀 ~ typeByDefault", typeByDefault);

				// ↓ Get connectorPositionOnFloors coords in selected floor if they have this coords array, else use coords of geoJsonRoutes
				const connectorPositionInSelectedFloor =
					selectedFeature[0]?.properties?.connectorPositionOnFloors &&
					selectedFeature[0]?.properties?.connectorPositionOnFloors.find(
						marker => marker.floorPlanId === endPoint.floorPlanId
					)
				// console.log(
				//   "🚀 ~ connectorPositionInSelectedFloor",
				//   connectorPositionInSelectedFloor
				// );

				const connectorPositionFetchedCoords = connectorPositionInSelectedFloor && [
					connectorPositionInSelectedFloor.coords.longitude,
					connectorPositionInSelectedFloor.coords.latitude
				]
				// console.log(
				//   "🚀 ~ connectorPositionFetchedCoords",
				//   connectorPositionFetchedCoords
				// );

				// console.log(floorPlan.id, "FLOORPLAN ID 1 antes do showmap CLICK");

				const conditionalCoords = connectorPositionFetchedCoords
					? connectorPositionFetchedCoords
					: selectedFeature[0]?.geometry.coordinates
				// console.log("🚀 ~ conditionalCoords 1", conditionalCoords);

				// ↓ Copy this connector coords to state, to pass them to addGeoJsonLayerToMapWithNewPath()
				selectedFeature[0] && setCopyOfConnectorCoords(conditionalCoords)

				// ↓ Copy this connector coords to state, to pass them to addPathOnDifferentFloorplanWithStartingPoint()
				selectedFeature[0] && setSelectedConnector(selectedFeature[0])

				// Opens connectors markers so the user can click on them to travel to the next floorplan with the path
				// If startPoint and endPoint both on a different floorplan do not open the connector marker, else open the marker
				if (startPoint.floorPlanId !== floorPlan.id && endPoint.floorPlanId !== floorPlan.id) {
					setConnectorAccess({
						clicked: false,
						zIndex: "0",
						id: null
					})
					setCopyOfConnectorCoords()
					setSelectedConnector()
					return
				} else {
					selectedFeature[0] &&
						setConnectorAccess({
							clicked: true,
							zIndex: "2",
							id: selectedFeature[0].properties?.connectorType
						})

					// ↓ If connector type selected is linked to the endPoint floor condition
					const isLinked =
						selectedFeature[0] &&
						selectedFeature[0].properties?.linkToFloors.some(
							floor => floor === endPoint.floorPlanId
						)
					// console.log("🚀 ~ isLinked connector linked to this floor", isLinked);

					// Find end Point floor name
					const endPointName =
						floorPlanData && floorPlanData.find(f => f.id === endPoint.floorPlanId)
					// console.log("🚀 ~ endPointName", endPointName);

					// if the end point selected floorplan doesn't have a connector connected to the floorplan of starting point display message to user and return
					if (!isLinked) {
						const paragraphToaster = (
							<p style={{ textAlign: "center" }}>
								This connector,
								<b>{selectedFeature && selectedFeature[0]?.properties?.connectorName}</b>,
								is not linked to {endPointName && endPointName?.name}. <br />
								Please select another connector.
							</p>
						)

						dispatch({
							type: "SNACKBAR_ERROR",
							payload: paragraphToaster
						})

						return
					}
				}
				return // don't create Path because its now done the rest on show map button
			}
		}

		//━━━━━━━━━━━━━━━ StartPoint.group === "Zones" / Areas ━━━━━━━━━━━━━━━\\
		//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\
		if (startPoint.group === "Areas") {
			// ━━━━━━━━━━━━━━━ Startpoint on same floorPlan
			if (startPoint.floorPlanId === floorPlan.id) {
				// console.log("🎃🎃🎃 floorPlanId EQUAL Directions Control - startPoint");

				setCopyOfStartPointCoords([
					startPoint.doorMarker?.longitude,
					startPoint.doorMarker?.latitude
				])

				startPointTurfed = turf.point([
					startPoint.doorMarker?.longitude,
					startPoint.doorMarker?.latitude
				])
			}

			// ━━━━━━━━━━━━━━━ Startpoint not on same floorPlan
			if (startPoint.floorPlanId !== floorPlan.id) {
				// console.log(
				//   "🎃🎃🎃 floorPlanId NOT EQUAL Directions Control - startPoint"
				// );

				// ↓ ━━━━━ Find Connectors on other floor of start point
				const rightFeatures = []
				floorPlanData &&
					floorPlanData.forEach(floor => {
						floor.geoJsonRoutes?.features &&
							floor.geoJsonRoutes.features.forEach(f => {
								if (f.properties?.linkToFloors?.includes(startPoint.floorPlanId)) {
									// console.log(f, "f");

									let coords = []
									f.geometry.coordinates.forEach(coordinates => {
										coords.push(coordinates.longitude, coordinates.latitude)
									})
									let finalFeature = {
										...f,
										geometry: {
											...f.geometry,
											coordinates: coords
										}
									}
									rightFeatures.push(finalFeature)
								}
							})
					})
				// console.log(
				//   "🚀 ~ rightFeatures - Connectors on other floors, startpoint floor",
				//   rightFeatures
				// );

				// Find and choose the connector with the type select
				const selectedFeature =
					rightFeatures &&
					rightFeatures.filter(feature => feature.properties?.connectorType === typeByDefault)
				// console.log("🚀 ~ selectedFeature", selectedFeature); // ← connector type selected by user
				// console.log("🚀 ~ typeByDefault", typeByDefault);

				// ↓ Get connectorPositionOnFloors coords in selected floor if they have this coords array, else use coords of geoJsonRoutes
				const connectorPositionInSelectedFloor =
					selectedFeature[0]?.properties?.connectorPositionOnFloors &&
					selectedFeature[0]?.properties?.connectorPositionOnFloors.find(
						marker => marker.floorPlanId === startPoint.floorPlanId
					)
				// console.log(
				//   "🚀 ~ connectorPositionInSelectedFloor",
				//   connectorPositionInSelectedFloor
				// );

				const connectorPositionFetchedCoords = connectorPositionInSelectedFloor && [
					connectorPositionInSelectedFloor.coords.longitude,
					connectorPositionInSelectedFloor.coords.latitude
				]
				// console.log(
				//   "🚀 ~ connectorPositionFetchedCoords",
				//   connectorPositionFetchedCoords
				// );

				// console.log(floorPlan.id, "FLOORPLAN ID 1 antes do showmap CLICK");

				const conditionalCoords = connectorPositionFetchedCoords
					? connectorPositionFetchedCoords
					: selectedFeature[0]?.geometry.coordinates
				// console.log("🚀 ~ conditionalCoords 1", conditionalCoords);

				// ↓ Copy this connector coords to state, to pass them to addGeoJsonLayerToMapWithNewPath()
				selectedFeature[0] && setCopyOfConnectorCoords(conditionalCoords)

				// ↓ Copy this connector coords to state, to pass them to addPathOnDifferentFloorplanWithStartingPoint()
				selectedFeature[0] && setSelectedConnector(selectedFeature[0])

				// Opens connectors markers so the user can click on them to travel to the next floorplan with the path
				// If startPoint and endPoint both on a different floorplan do not open the connector marker, else open the marker
				if (startPoint.floorPlanId !== floorPlan.id && endPoint.floorPlanId !== floorPlan.id) {
					setConnectorAccess({
						clicked: false,
						zIndex: "0",
						id: null
					})
					setCopyOfConnectorCoords()
					setSelectedConnector()
					return
				} else {
					selectedFeature[0] &&
						setConnectorAccess({
							clicked: true,
							zIndex: "2",
							id: selectedFeature[0]?.properties?.connectorType
						})

					// ↓ If connector type selected is linked to the start point floor condition
					const isLinked =
						selectedFeature[0] &&
						selectedFeature[0].properties?.linkToFloors?.some(
							floor => floor === startPoint?.floorPlanId
						)
					// console.log("🚀 ~ isLinked connector linked to this floor", isLinked);

					// Find start Point floor name
					const startPointName =
						floorPlanData && floorPlanData.find(f => f.id === startPoint?.floorPlanId)
					// console.log("🚀 ~ startPointName", startPointName);

					// if the start point selected floorplan doesn't have a connector connected to the floorplan of starting point display message to user and return
					if (!isLinked) {
						const paragraphToaster = (
							<p style={{ textAlign: "center" }}>
								This connector, <b>{selectedFeature[0]?.properties?.connectorName}</b>, is
								not linked to {startPointName.name}. <br />
								Please select another connector.
							</p>
						)

						dispatch({
							type: "SNACKBAR_ERROR",
							payload: paragraphToaster
						})

						return
					}
				}

				return // don't create Path because its now done the rest on show map button
			}
		}

		//━━━━━━━━━━━━━━━ EndPoint.group === "People" ━━━━━━━━━━━━━━━\\
		//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\
		if (
			endPoint.group === "People" ||
			endPoint.group === "Assets" ||
			endPoint.group === "Unassigned"
		) {
			// resets the poiToDoorCoords state
			setPoiToDoorCoords()
			setPoiToDoorEndPointCoords()

			// ━━━━━━━━━━━━━━━ Endpoint on same floorPlan
			if (endPoint.floorPlanId === floorPlan.id) {
				// console.log("floorPlanId EQUAL Directions Control - endPoint people")
				if (!floorPlan.geoJson) {
					return
				}

				// If POI is inside a polygon
				const poiInsidePolyCoords = []
				floorPlan &&
					floorPlan.geoJson.features.forEach(feature => {
						let coordinates = []
						if (feature.properties?.name === "Base Layer") {
							return
						}
						feature.geometry.coordinates.forEach(coords => {
							coordinates.push([coords.longitude, coords.latitude])
						})

						// console.log("🚀 ~ coordinates", coordinates);
						const poly = turf.polygon([coordinates])

						// console.log(
						// 	turf.booleanPointInPolygon(
						// 		[endPoint?.gpsData?.longitude, endPoint?.gpsData?.latitude],
						// 		poly
						// 	),
						// 	"BOOLEAN EXISTS"
						// )
						const existsInsidePolygon = turf.booleanPointInPolygon(
							[endPoint?.gpsData?.longitude, endPoint?.gpsData?.latitude],
							poly
						)
						if (existsInsidePolygon) {
							// console.log(feature, "feature that exists POI inside polygon");
							// console.log(value, "value that exists POI inside polygon");
							const fetchZoneWithMarkerCoords =
								zones && zones.find(zone => zone.id === feature.properties?.areaId)
							// console.log("🚀 ~ fetchZoneWithMarkerCoords 3", fetchZoneWithMarkerCoords)
							poiInsidePolyCoords.push(fetchZoneWithMarkerCoords)
						}
					})

				// console.log(poiInsidePolyCoords.length, "length")
				// console.log(poiInsidePolyCoords, "poiInsidePolyCoords")

				// if POI not inside a polygon
				if (poiInsidePolyCoords.length === 0) {
					destinationPointTurfed = turf.point([
						endPoint?.gpsData?.longitude,
						endPoint?.gpsData?.latitude
					])
				}

				// if POI is inside a polygon
				if (poiInsidePolyCoords.length !== 0) {
					setPoiToDoorEndPointCoords([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])

					destinationPointTurfed = turf.point([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])
				}

				// console.log("🚀  destinationPointTurfed:", destinationPointTurfed)

				// old (not going to door of zones)
				// destinationPointTurfed = turf.point([
				// 	endPoint.gpsData?.longitude,
				// 	endPoint.gpsData?.latitude
				// ])
			}
			// ━━━━━━━━━━━━━━━ Endpoint not on same floorPlan
			if (endPoint.floorPlanId !== floorPlan.id) {
				// ↓ ━━━━━ Find Connectors on other floor of end point
				const rightFeatures = []
				floorPlanData &&
					floorPlanData.forEach(floor => {
						floor.geoJsonRoutes?.features &&
							floor.geoJsonRoutes.features.forEach(f => {
								if (f.properties?.linkToFloors?.includes(endPoint.floorPlanId)) {
									// console.log(f, "f");

									let coords = []
									f.geometry.coordinates.forEach(coordinates => {
										coords.push(coordinates.longitude, coordinates.latitude)
									})
									let finalFeature = {
										...f,
										geometry: {
											...f.geometry,
											coordinates: coords
										}
									}
									rightFeatures.push(finalFeature)
								}
							})
					})
				// console.log(
				//   "🚀 ~ rightFeatures - Connectors on other floors, endPoint floor",
				//   rightFeatures
				// );

				// Find and choose the connector with the type select
				const selectedFeature =
					rightFeatures &&
					rightFeatures.filter(feature => feature.properties?.connectorType === typeByDefault)
				// console.log("🎃🎃🎃🎃 ~ selectedFeature", selectedFeature); // ← connector type selected by user
				// console.log("🚀 ~ typeByDefault", typeByDefault);

				// ↓ Get connectorPositionOnFloors coords in selected floor if they have this coords array, else use coords of geoJsonRoutes
				const connectorPositionInSelectedFloor =
					selectedFeature[0]?.properties?.connectorPositionOnFloors &&
					selectedFeature[0]?.properties?.connectorPositionOnFloors.find(
						marker => marker.floorPlanId === endPoint.floorPlanId
					)
				// console.log(
				//   "🚀 ~ connectorPositionInSelectedFloor",
				//   connectorPositionInSelectedFloor
				// );

				const connectorPositionFetchedCoords = connectorPositionInSelectedFloor && [
					connectorPositionInSelectedFloor.coords.longitude,
					connectorPositionInSelectedFloor.coords.latitude
				]
				// console.log(
				//   "🚀 ~ connectorPositionFetchedCoords",
				//   connectorPositionFetchedCoords
				// );

				// console.log(floorPlan.id, "FLOORPLAN ID 1 antes do showmap CLICK");

				const conditionalCoords = connectorPositionFetchedCoords
					? connectorPositionFetchedCoords
					: selectedFeature[0]?.geometry.coordinates
				// console.log("🚀 ~ conditionalCoords 1", conditionalCoords);

				// ↓ Copy this connector coords to state, to pass them to addGeoJsonLayerToMapWithNewPath()
				selectedFeature[0] && setCopyOfConnectorCoords(conditionalCoords)

				// ↓ Copy this connector coords to state, to pass them to addPathOnDifferentFloorplanWithStartingPoint()
				selectedFeature[0] && setSelectedConnector(selectedFeature[0])

				// Opens connectors markers so the user can click on them to travel to the next floorplan with the path
				// If startPoint and endPoint both on a different floorplan do not open the connector marker, else open the marker
				if (startPoint.floorPlanId !== floorPlan.id && endPoint.floorPlanId !== floorPlan.id) {
					setConnectorAccess({
						clicked: false,
						zIndex: "0",
						id: null
					})
					setCopyOfConnectorCoords()
					setSelectedConnector()
					return
				} else {
					selectedFeature[0] &&
						setConnectorAccess({
							clicked: true,
							zIndex: "2",
							id: selectedFeature[0].properties?.connectorType
						})

					// ↓ If connector type selected is linked to the endPoint floor condition
					const isLinked =
						selectedFeature[0] &&
						selectedFeature[0].properties?.linkToFloors.some(
							floor => floor === endPoint.floorPlanId
						)
					// console.log("🚀 ~ isLinked connector linked to this floor", isLinked);

					// Find end Point floor name
					const endPointName =
						floorPlanData && floorPlanData.find(f => f.id === endPoint.floorPlanId)
					// console.log("🚀 ~ endPointName", endPointName);

					// if the end point selected floorplan doesn't have a connector connected to the floorplan of starting point display message to user and return
					if (!isLinked) {
						const paragraphToaster = (
							<p style={{ textAlign: "center" }}>
								This connector, <b>{selectedFeature[0].properties?.connectorName}</b>, is
								not linked to {endPointName.name}. <br />
								Please select another connector.
							</p>
						)

						dispatch({
							type: "SNACKBAR_ERROR",
							payload: paragraphToaster
						})

						return
					}
				}
				return // don't create Path because its now done the rest on show map button
			}
		}

		//━━━━━━━━━━━━━━━ StartPoint.group === "People" ━━━━━━━━━━━━━━━\\
		//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\
		if (
			startPoint.group === "People" ||
			startPoint.group === "Assets" ||
			startPoint.group === "Unassigned"
		) {
			// ━━━━━━━━━━━━━━━ Startpoint on same floorPlan
			if (startPoint.floorPlanId === floorPlan.id) {
				// console.log("🎃🎃🎃 floorPlanId EQUAL Directions Control - startPoint");

				if (!floorPlan.geoJson) {
					return
				}

				// If POI is inside a polygon
				const poiInsidePolyCoords = []
				floorPlan &&
					floorPlan.geoJson.features.forEach(feature => {
						let coordinates = []
						if (feature.properties?.name === "Base Layer") {
							return
						}
						feature.geometry.coordinates.forEach(coords => {
							coordinates.push([coords.longitude, coords.latitude])
						})

						// console.log("🚀 ~ coordinates", coordinates);
						const poly = turf.polygon([coordinates])
						// console.log(
						//   turf.booleanPointInPolygon(
						//     [startPoint.gpsData.longitude, startPoint.gpsData.latitude],
						//     poly
						//   ),
						//   "BOOLEAN EXISTS"
						// );
						const existsInsidePolygon = turf.booleanPointInPolygon(
							[startPoint.gpsData.longitude, startPoint.gpsData.latitude],
							poly
						)
						if (existsInsidePolygon) {
							// console.log(feature, "feature that exists POI inside polygon");
							// console.log(value, "value that exists POI inside polygon");
							const fetchZoneWithMarkerCoords =
								zones && zones.find(zone => zone.id === feature.properties?.areaId)
							// console.log(
							// 	"🚀 ~ fetchZoneWithMarkerCoords 5 starting point",
							// 	fetchZoneWithMarkerCoords
							// )
							poiInsidePolyCoords.push(fetchZoneWithMarkerCoords)
						}
					})

				// console.log(poiInsidePolyCoords.length, "length");
				// console.log(startPoint, "startPoint ");
				// console.log(poiInsidePolyCoords, "poiInsidePolyCoords");

				// if POI not inside a polygon
				if (poiInsidePolyCoords.length === 0) {
					startPointTurfed = turf.point([
						startPoint.gpsData.longitude,
						startPoint.gpsData.latitude
					])
				}

				// if POI is inside a polygon
				if (poiInsidePolyCoords.length !== 0) {
					setPoiToDoorCoords([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])

					startPointTurfed = turf.point([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])
				}
				// console.log("🚀  startPointTurfed:", startPointTurfed)

				setCopyOfStartPointCoords([startPoint.gpsData?.longitude, startPoint.gpsData?.latitude])

				// old (not going to door of zones)
				// startPointTurfed = turf.point([
				// 	startPoint.gpsData?.longitude,
				// 	startPoint.gpsData?.latitude
				// ])
			}
			// ━━━━━━━━━━━━━━━ Startpoint not on same floorPlan
			if (startPoint.floorPlanId !== floorPlan.id) {
				console.log("🎃🎃🎃 floorPlanId NOT EQUAL Directions Control - startPoint people")

				// ↓ ━━━━━ Find Connectors on other floor of start point
				const rightFeatures = []
				floorPlanData &&
					floorPlanData.forEach(floor => {
						floor.geoJsonRoutes?.features &&
							floor.geoJsonRoutes.features.forEach(f => {
								if (f.properties?.linkToFloors?.includes(startPoint.floorPlanId)) {
									// console.log(f, "f");

									let coords = []
									f.geometry.coordinates.forEach(coordinates => {
										coords.push(coordinates.longitude, coordinates.latitude)
									})
									let finalFeature = {
										...f,
										geometry: {
											...f.geometry,
											coordinates: coords
										}
									}
									rightFeatures.push(finalFeature)
								}
							})
					})
				// console.log(
				//   "🚀 ~ rightFeatures - Connectors on other floors, startpoint floor",
				//   rightFeatures
				// );

				// Find and choose the connector with the type select
				const selectedFeature =
					rightFeatures &&
					rightFeatures.filter(feature => feature.properties?.connectorType === typeByDefault)
				// console.log("🚀 ~ selectedFeature", selectedFeature); // ← connector type selected by user
				// console.log("🚀 ~ typeByDefault", typeByDefault);

				// ↓ Get connectorPositionOnFloors coords in selected floor if they have this coords array, else use coords of geoJsonRoutes
				const connectorPositionInSelectedFloor =
					selectedFeature[0]?.properties?.connectorPositionOnFloors &&
					selectedFeature[0]?.properties?.connectorPositionOnFloors.find(
						marker => marker.floorPlanId === startPoint.floorPlanId
					)
				// console.log(
				//   "🚀 ~ connectorPositionInSelectedFloor",
				//   connectorPositionInSelectedFloor
				// );

				const connectorPositionFetchedCoords = connectorPositionInSelectedFloor && [
					connectorPositionInSelectedFloor.coords.longitude,
					connectorPositionInSelectedFloor.coords.latitude
				]
				// console.log(
				//   "🚀 ~ connectorPositionFetchedCoords",
				//   connectorPositionFetchedCoords
				// );

				// console.log(floorPlan.id, "FLOORPLAN ID 1 antes do showmap CLICK");

				const conditionalCoords = connectorPositionFetchedCoords
					? connectorPositionFetchedCoords
					: selectedFeature[0]?.geometry.coordinates
				// console.log("🚀 ~ conditionalCoords 1", conditionalCoords);

				// ↓ Copy this connector coords to state, to pass them to addGeoJsonLayerToMapWithNewPath()
				selectedFeature[0] && setCopyOfConnectorCoords(conditionalCoords)

				// ↓ Copy this connector coords to state, to pass them to addPathOnDifferentFloorplanWithStartingPoint()
				selectedFeature[0] && setSelectedConnector(selectedFeature[0])

				// Opens connectors markers so the user can click on them to travel to the next floorplan with the path
				// If startPoint and endPoint both on a different floorplan do not open the connector marker, else open the marker
				if (startPoint.floorPlanId !== floorPlan.id && endPoint.floorPlanId !== floorPlan.id) {
					setConnectorAccess({
						clicked: false,
						zIndex: "0",
						id: null
					})
					setCopyOfConnectorCoords()
					setSelectedConnector()
					return
				} else {
					selectedFeature[0] &&
						setConnectorAccess({
							clicked: true,
							zIndex: "2",
							id: selectedFeature[0].properties?.connectorType
						})

					// ↓ If connector type selected is linked to the start point floor condition
					const isLinked =
						selectedFeature[0] &&
						selectedFeature[0].properties?.linkToFloors.some(
							floor => floor === startPoint.floorPlanId
						)
					// console.log("🚀 ~ isLinked connector linked to this floor", isLinked);

					// Find start Point floor name
					const startPointName =
						floorPlanData && floorPlanData.find(f => f.id === startPoint.floorPlanId)
					// console.log("🚀 ~ startPointName", startPointName);

					// if the start point selected floorplan doesn't have a connector connected to the floorplan of starting point display message to user and return
					if (!isLinked) {
						const paragraphToaster = (
							<p style={{ textAlign: "center" }}>
								This connector, <b>{selectedFeature[0].properties?.connectorName}</b>, is
								not linked to {startPointName.name}. <br />
								Please select another connector.
							</p>
						)

						dispatch({
							type: "SNACKBAR_ERROR",
							payload: paragraphToaster
						})

						return
					}
				}

				return // don't create Path because its now done the rest on show map button
			}
		}

		//━━━━━━━━━━━━━━━━━━━━━━━ Set pathFound state result ━━━━━━━━━━━━━━━━━━━━━━━━━━━\\
		// console.log("🚀 ~ destinationPointTurfed", destinationPointTurfed);
		// console.log("🚀 ~ startPointTurfed", startPointTurfed);

		const nearestStartPoint =
			startPointTurfed && turf.nearestPoint(startPointTurfed, turf.featureCollection(points))
		// console.log("🚀 ~ nearestStartPoint", nearestStartPoint);

		const nearestEndPoint =
			destinationPointTurfed &&
			turf.nearestPoint(destinationPointTurfed, turf.featureCollection(points))
		// console.log("🚀 ~ nearestEndPoint", nearestEndPoint);

		const start = nearestStartPoint && turf.point(nearestStartPoint.geometry.coordinates)
		// console.log("🚀 ~ start", start);

		const end = nearestEndPoint && turf.point(nearestEndPoint.geometry.coordinates)
		// console.log("🚀 ~ end", end);

		const path = start && end && pathFinderOnSameFloor.findPath(start, end)
		// console.log("Path: ", path);

		// ↓ Sets Path
		if (path?.path) {
			setPathFound(path) // sets path to state to be drawed

			//  (read me: ↓ this is now passed to the "show on map" button)
			// // ↓ Closes directions box
			// setGetDirections({
			//   type: null,
			//   poi: null,
			//   clicked: false,
			// });
		} else {
			dispatch({
				type: "SNACKBAR_WARNING",
				payload: { message: "No routes or connectors available in this floor." }
			})
		}
	}
}

// ━━━━━━━━━━━━━━━ addPathOnDifferentFloorplanWithStartingPointAndEndingPoint ━━━━━━━━━━━━━━━ \\
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\
export function addPathOnDifferentFloorplanWithStartingPointAndEndingPoint(props) {
	const {
		dispatch,
		floorPlan,
		endPoint,
		startPoint,
		geoJsonRoutes,
		setPathFound,
		zones,
		setPoiToDoorCoords,
		setPoiToDoorEndPointCoords,
		setCopyOfStartPointCoords
	} = props

	let finalFeaturesLines = []
	let finalFeaturesPoints = []
	geoJsonRoutes &&
		geoJsonRoutes.features.forEach((feature, index) => {
			if (feature.geometry.type === "LineString") {
				let coordinates = []
				feature.geometry.coordinates.forEach(coords => {
					coordinates.push([coords.longitude, coords.latitude])
				})
				finalFeaturesLines.push({
					type: geoJsonRoutes.features[index].type,
					properties: {
						...geoJsonRoutes.features[index].properties
					},
					geometry: {
						type: geoJsonRoutes.features[index].geometry.type,
						coordinates: coordinates
					}
				})
			}
			if (feature.geometry.type === "Point") {
				let coordinates = []
				feature.geometry.coordinates.forEach(coords => {
					coordinates.push(coords.longitude, coords.latitude)
				})
				finalFeaturesPoints.push({
					type: geoJsonRoutes.features[index].type,
					properties: {
						...geoJsonRoutes.features[index].properties
					},
					geometry: {
						type: geoJsonRoutes.features[index].geometry.type,
						coordinates: coordinates
					}
				})
			}
		})

	// console.log("🚀 ~ finalFeaturesLines", finalFeaturesLines);
	// console.log("🚀 ~ finalFeaturesPoints", finalFeaturesPoints);

	const finalLayerLines = {
		type: "FeatureCollection",
		features: finalFeaturesLines
	}
	// console.log(finalLayerLines, "finalLayerLines");

	// ↓ turn lines into little chunks.
	const chunk = turf.lineChunk(finalLayerLines, 0.0005, {
		units: "kilometers"
	})
	// console.log("🚀 ~ chunk", chunk);

	const pathFinderOnSameFloor = new PathFinder(chunk, { precision: 1e-5 })
	// console.log("🚀 ~ pathFinderOnSameFloor", pathFinderOnSameFloor);

	const points = []

	for (const [key, value] of Object.entries(pathFinderOnSameFloor._graph.vertices)) {
		// console.log("Key: ", key);
		const latlong = key.split(",")
		const lng = parseFloat(latlong[0])
		const lat = parseFloat(latlong[1])
		points.push(turf.point([lng, lat]))
	}
	// console.log("Points: ", JSON.stringify(points));

	if (endPoint && startPoint) {
		// console.log("🚀🚀🚀🚀🚀  startPoint:", startPoint)
		// console.log("🎯🎯🎯🎯🎯  endPoint:", endPoint)

		// ↓ End Point (point selected where the user want to go)
		let destinationPointTurfed = null

		// ↓ Start Point (point selected where the user want to go)
		let startPointTurfed = null

		//━━━━━━━━━━━━━━━ EndPoint.group === "Points of Interest" ━━━━━━━━━━━━━━━\\
		if (endPoint.group === "Points of Interest") {
			// resets the poiToDoorCoords state
			setPoiToDoorCoords()
			setPoiToDoorEndPointCoords()

			// ━━━━━━━━━━━━━━━ Endpoint on same floorPlan
			if (endPoint.floorPlanId === floorPlan.id) {
				// console.log("floorPlanId EQUAL Directions Control - endPoint");

				if (!floorPlan.geoJson) {
					return
				}

				// If POI is inside a polygon
				const poiInsidePolyCoords = []
				floorPlan &&
					floorPlan.geoJson.features.forEach(feature => {
						let coordinates = []
						if (feature.properties?.name === "Base Layer") {
							return
						}
						feature.geometry.coordinates.forEach(coords => {
							coordinates.push([coords.longitude, coords.latitude])
						})

						// console.log("🚀 ~ coordinates", coordinates);
						const poly = turf.polygon([coordinates])

						// console.log(
						//   turf.booleanPointInPolygon(
						//     [endPoint?.coords?.longitude, endPoint?.coords?.latitude],
						//     poly
						//   ),
						//   "BOOLEAN EXISTS"
						// );
						const existsInsidePolygon = turf.booleanPointInPolygon(
							[endPoint?.coords?.longitude, endPoint?.coords?.latitude],
							poly
						)
						if (existsInsidePolygon) {
							// console.log(feature, "feature that exists POI inside polygon");
							// console.log(value, "value that exists POI inside polygon");
							const fetchZoneWithMarkerCoords =
								zones && zones.find(zone => zone.id === feature.properties?.areaId)
							// console.log(
							//   "🚀 ~ fetchZoneWithMarkerCoords 3",
							//   fetchZoneWithMarkerCoords
							// );
							poiInsidePolyCoords.push(fetchZoneWithMarkerCoords)
						}
					})

				// console.log(poiInsidePolyCoords.length, "length");
				// console.log(poiInsidePolyCoords, "poiInsidePolyCoords");

				// if POI not inside a polygon
				if (poiInsidePolyCoords.length === 0) {
					destinationPointTurfed = turf.point([
						endPoint?.coords?.longitude,
						endPoint?.coords?.latitude
					])
				}

				// if POI is inside a polygon
				if (poiInsidePolyCoords.length !== 0) {
					setPoiToDoorEndPointCoords([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])

					destinationPointTurfed = turf.point([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])
				}

				// console.log("🚀  destinationPointTurfed:", destinationPointTurfed);
			}
		}

		//━━━━━━━━━━━━━━━ startPoint.group === "Points of Interest" ━━━━━━━━━━━━━━━\\
		if (startPoint.group === "Points of Interest") {
			// ━━━━━━━━━━━━━━━ Startpoint on same floorPlan
			if (startPoint.floorPlanId === floorPlan.id) {
				// console.log("🎃🎃🎃 floorPlanId EQUAL Directions Control - startPoint");

				if (!floorPlan.geoJson) {
					return
				}

				// If POI is inside a polygon
				const poiInsidePolyCoords = []
				floorPlan &&
					floorPlan.geoJson.features.forEach(feature => {
						let coordinates = []
						if (feature.properties?.name === "Base Layer") {
							return
						}
						feature.geometry.coordinates.forEach(coords => {
							coordinates.push([coords.longitude, coords.latitude])
						})

						// console.log("🚀 ~ coordinates", coordinates);
						const poly = turf.polygon([coordinates])
						// console.log(
						//   turf.booleanPointInPolygon(
						//     [startPoint.coords.longitude, startPoint.coords.latitude],
						//     poly
						//   ),
						//   "BOOLEAN EXISTS"
						// );
						const existsInsidePolygon = turf.booleanPointInPolygon(
							[startPoint.coords.longitude, startPoint.coords.latitude],
							poly
						)
						if (existsInsidePolygon) {
							// console.log(feature, "feature that exists POI inside polygon");
							// console.log(value, "value that exists POI inside polygon");
							const fetchZoneWithMarkerCoords =
								zones && zones.find(zone => zone.id === feature.properties?.areaId)
							// console.log(
							//   "🚀 ~ fetchZoneWithMarkerCoords 5",
							//   fetchZoneWithMarkerCoords
							// );
							poiInsidePolyCoords.push(fetchZoneWithMarkerCoords)
						}
					})

				// console.log(poiInsidePolyCoords.length, "length");
				// console.log(startPoint, "startPoint ");
				// console.log(poiInsidePolyCoords, "poiInsidePolyCoords");

				// if POI not inside a polygon
				if (poiInsidePolyCoords.length === 0) {
					startPointTurfed = turf.point([
						startPoint.coords.longitude,
						startPoint.coords.latitude
					])
				}

				// if POI is inside a polygon
				if (poiInsidePolyCoords.length !== 0) {
					setPoiToDoorCoords([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])

					startPointTurfed = turf.point([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])
				}
				// console.log("🚀  startPointTurfed:", startPointTurfed);
			}
		}

		//━━━━━━━━━━━━━━━ EndPoint.group === "Zones / Areas" ━━━━━━━━━━━━━━━\\
		if (endPoint.group === "Areas") {
			// resets the poiToDoorCoords state
			setPoiToDoorCoords()
			setPoiToDoorEndPointCoords()

			// ━━━━━━━━━━━━━━━ Endpoint on same floorPlan
			if (endPoint.floorPlanId === floorPlan.id) {
				// console.log("floorPlanId EQUAL Directions Control - endPoint");

				destinationPointTurfed = turf.point([
					endPoint.doorMarker?.longitude,
					endPoint.doorMarker?.latitude
				])

				// console.log("🚀  destinationPointTurfed:", destinationPointTurfed);
			}
		}

		//━━━━━━━━━━━━━━━ startPoint.group === "Zones / Areas" ━━━━━━━━━━━━━━━\\
		if (startPoint.group === "Areas") {
			// ━━━━━━━━━━━━━━━ Startpoint on same floorPlan
			if (startPoint.floorPlanId === floorPlan.id) {
				// console.log("🎃🎃🎃 floorPlanId EQUAL Directions Control - startPoint");

				setCopyOfStartPointCoords([
					startPoint.doorMarker?.longitude,
					startPoint.doorMarker?.latitude
				])

				startPointTurfed = turf.point([
					startPoint.doorMarker?.longitude,
					startPoint.doorMarker?.latitude
				])
				// console.log("🚀  startPointTurfed:", startPointTurfed);
			}
		}

		//	━━ EndPoint.group === "People" ━━━━━━━━━━━━━━━\\
		if (endPoint.group === "People") {
			// resets the poiToDoorCoords state
			setPoiToDoorCoords()
			setPoiToDoorEndPointCoords()

			// ━━━━━━━━━━━━━━━ Endpoint on same floorPlan
			if (endPoint.floorPlanId === floorPlan.id) {
				// console.log("floorPlanId EQUAL Directions Control - endPoint");

				if (!floorPlan.geoJson) {
					return
				}

				// If POI is inside a polygon
				const poiInsidePolyCoords = []
				floorPlan &&
					floorPlan.geoJson.features.forEach(feature => {
						let coordinates = []
						if (feature.properties?.name === "Base Layer") {
							return
						}
						feature.geometry.coordinates.forEach(coords => {
							coordinates.push([coords.longitude, coords.latitude])
						})

						// console.log("🚀 ~ coordinates", coordinates);
						const poly = turf.polygon([coordinates])

						// console.log(
						//   turf.booleanPointInPolygon(
						//     [endPoint?.gpsData?.longitude, endPoint?.gpsData?.latitude],
						//     poly
						//   ),
						//   "BOOLEAN EXISTS"
						// );
						const existsInsidePolygon = turf.booleanPointInPolygon(
							[endPoint?.gpsData?.longitude, endPoint?.gpsData?.latitude],
							poly
						)
						if (existsInsidePolygon) {
							// console.log(feature, "feature that exists POI inside polygon");
							// console.log(value, "value that exists POI inside polygon");
							const fetchZoneWithMarkerCoords =
								zones && zones.find(zone => zone.id === feature.properties?.areaId)
							// console.log(
							//   "🚀 ~ fetchZoneWithMarkerCoords 3",
							//   fetchZoneWithMarkerCoords
							// );
							poiInsidePolyCoords.push(fetchZoneWithMarkerCoords)
						}
					})

				// console.log(poiInsidePolyCoords.length, "length");
				// console.log(poiInsidePolyCoords, "poiInsidePolyCoords");

				// if POI not inside a polygon
				if (poiInsidePolyCoords.length === 0) {
					destinationPointTurfed = turf.point([
						endPoint?.gpsData?.longitude,
						endPoint?.gpsData?.latitude
					])
				}

				// if POI is inside a polygon
				if (poiInsidePolyCoords.length !== 0) {
					setPoiToDoorEndPointCoords([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])

					destinationPointTurfed = turf.point([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])
				}

				// console.log("🚀  destinationPointTurfed:", destinationPointTurfed);
			}
		}

		//━━━━━━━━━━━━━━━ startPoint.group === "People" ━━━━━━━━━━━━━━━\\
		if (startPoint.group === "People") {
			// ━━━━━━━━━━━━━━━ Startpoint on same floorPlan
			if (startPoint.floorPlanId === floorPlan.id) {
				// console.log("🎃🎃🎃 floorPlanId EQUAL Directions Control - startPoint");

				if (!floorPlan.geoJson) {
					return
				}

				// If POI is inside a polygon
				const poiInsidePolyCoords = []
				floorPlan &&
					floorPlan.geoJson.features.forEach(feature => {
						let coordinates = []
						if (feature.properties?.name === "Base Layer") {
							return
						}
						feature.geometry.coordinates.forEach(coords => {
							coordinates.push([coords.longitude, coords.latitude])
						})

						// console.log("🚀 ~ coordinates", coordinates);
						const poly = turf.polygon([coordinates])
						// console.log(
						//   turf.booleanPointInPolygon(
						//     [startPoint.gpsData.longitude, startPoint.gpsData.latitude],
						//     poly
						//   ),
						//   "BOOLEAN EXISTS"
						// );
						const existsInsidePolygon = turf.booleanPointInPolygon(
							[startPoint.gpsData.longitude, startPoint.gpsData.latitude],
							poly
						)
						if (existsInsidePolygon) {
							// console.log(feature, "feature that exists POI inside polygon");
							// console.log(value, "value that exists POI inside polygon");
							const fetchZoneWithMarkerCoords =
								zones && zones.find(zone => zone.id === feature.properties?.areaId)
							// console.log(
							//   "🚀 ~ fetchZoneWithMarkerCoords 5",
							//   fetchZoneWithMarkerCoords
							// );
							poiInsidePolyCoords.push(fetchZoneWithMarkerCoords)
						}
					})

				// console.log(poiInsidePolyCoords.length, "length");
				// console.log(startPoint, "startPoint ");
				// console.log(poiInsidePolyCoords, "poiInsidePolyCoords");

				// if POI not inside a polygon
				if (poiInsidePolyCoords.length === 0) {
					startPointTurfed = turf.point([
						startPoint.gpsData.longitude,
						startPoint.gpsData.latitude
					])
				}

				// if POI is inside a polygon
				if (poiInsidePolyCoords.length !== 0) {
					setPoiToDoorCoords([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])

					startPointTurfed = turf.point([
						poiInsidePolyCoords[0]?.doorMarker?.longitude,
						poiInsidePolyCoords[0]?.doorMarker?.latitude
					])
				}

				setCopyOfStartPointCoords([startPoint.gpsData?.longitude, startPoint.gpsData?.latitude])
				// console.log("🚀  startPointTurfed:", startPointTurfed);
			}
		}

		//━━━━━━━━━━━━━━━━━━━━━━━ Set pathFound state result ━━━━━━━━━━━━━━━━━━━━━━━━━━━\\
		// console.log("🚀 ~ destinationPointTurfed", destinationPointTurfed);
		// console.log("🚀 ~ startPointTurfed", startPointTurfed);

		const nearestStartPoint =
			startPointTurfed && turf.nearestPoint(startPointTurfed, turf.featureCollection(points))
		// console.log("🚀 ~ nearestStartPoint", nearestStartPoint);

		const nearestEndPoint =
			destinationPointTurfed &&
			turf.nearestPoint(destinationPointTurfed, turf.featureCollection(points))
		// console.log("🚀 ~ nearestEndPoint", nearestEndPoint);

		const start = nearestStartPoint && turf.point(nearestStartPoint.geometry.coordinates)
		// console.log("🚀 ~ start", start);

		const end = nearestEndPoint && turf.point(nearestEndPoint.geometry.coordinates)
		// console.log("🚀 ~ end", end);

		const path = start && end && pathFinderOnSameFloor.findPath(start, end)
		// console.log("Path: ", path);

		// ↓ Sets Path
		if (path?.path) {
			setPathFound(path) // sets path to state to be drawed
		} else {
			dispatch({
				type: "SNACKBAR_WARNING",
				payload: { message: "No routes or connectors available in this floor." }
			})
		}
	}
}

// ━━━━━━━━━━━━━━━ addPathOnDifferentFloorplanWithStartingPoint ━━━━━━━━━━━━━━━ \\
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\
export function addPathOnDifferentFloorplanWithStartingPoint(props) {
	const {
		selectedConnector,
		startingPoint,
		geoJsonRoutes,
		dispatch,
		setPathFound,
		setCopyOfStartPointCoords,
		floorPlan,
		zones,
		setPoiToDoorEndPointCoords,
		setPoiToDoorCoords
	} = props

	// ━━━━━━━━━━━━━━━ Add new Path after travelling to another level by connector ━━━━━━━━━━━━━━━ \\
	let finalFeaturesLines = []
	geoJsonRoutes &&
		geoJsonRoutes.features.forEach((feature, index) => {
			if (feature.geometry.type === "LineString") {
				let coordinates = []
				feature.geometry.coordinates.forEach(coords => {
					coordinates.push([coords.longitude, coords.latitude])
				})
				finalFeaturesLines.push({
					type: geoJsonRoutes.features[index].type,
					properties: {
						...geoJsonRoutes.features[index].properties
					},
					geometry: {
						type: geoJsonRoutes.features[index].geometry.type,
						coordinates: coordinates
					}
				})
			}
		})

	// console.log("🚀 ~ finalFeaturesLines", finalFeaturesLines);

	const finalLayerLines = {
		type: "FeatureCollection",
		features: finalFeaturesLines
	}
	// console.log(finalLayerLines, "finalLayerLines");

	const chunk = turf.lineChunk(finalLayerLines, 0.0005, {
		// ← turn lines into little chunks.
		units: "kilometers"
	})
	// console.log("🚀 ~ chunk", chunk);

	const pathFinderOnSameFloor = new PathFinder(chunk, { precision: 1e-5 })
	// console.log("🚀 ~ pathFinderOnSameFloor", pathFinderOnSameFloor);

	const points = []

	for (const [key /*value*/] of Object.entries(pathFinderOnSameFloor._graph.vertices)) {
		// console.log("Key: ", key);
		const latlong = key.split(",")
		const lng = parseFloat(latlong[0])
		const lat = parseFloat(latlong[1])
		points.push(turf.point([lng, lat]))
	}
	// console.log("Points: ", JSON.stringify(points));

	//━━━━━━━━━━━━━━━ selectedConnector && Starting Point ━━━━━━━━━━━━━━━\\
	//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\
	if (selectedConnector && startingPoint) {
		// console.log(startingPoint, "🚀🚀🚀 starting point on NEW FUNCTION !!!!");
		// console.log(
		//   selectedConnector,
		//   "🛗🛗🛗🛗 selectedConnector on NEW FUNCTION !!!!"
		// );

		let startPointTurfed = null

		// ↓ Start Point (point selected where the user want to start)
		let coordsStartPoint = []

		//━━━━━━━━━━━━━━━ startingPoint.group === "Points of Interest" ━━━━━━━━━━━━━━━\\
		if (startingPoint.group === "Points of Interest") {
			if (!floorPlan.geoJson) {
				return
			}

			// If POI is inside a polygon
			const poiInsidePolyCoords = []
			floorPlan &&
				floorPlan.geoJson.features.forEach(feature => {
					let coordinates = []
					if (feature.properties?.name === "Base Layer") {
						return
					}
					feature.geometry.coordinates.forEach(coords => {
						coordinates.push([coords.longitude, coords.latitude])
					})

					// console.log("🚀 ~ coordinates", coordinates);
					const poly = turf.polygon([coordinates])
					// console.log(
					//   turf.booleanPointInPolygon(
					//     [
					//       startingPoint?.coords?.longitude,
					//       startingPoint?.coords?.latitude,
					//     ],
					//     poly
					//   ),
					//   "BOOLEAN EXISTS"
					// );
					const existsInsidePolygon = turf.booleanPointInPolygon(
						[startingPoint?.coords?.longitude, startingPoint?.coords?.latitude],
						poly
					)
					if (existsInsidePolygon) {
						// console.log(feature, "feature that exists POI inside polygon");
						// console.log(
						//   startingPoint,
						//   "startingPoint that exists POI inside polygon"
						// );

						const fetchZoneWithMarkerCoords =
							zones && zones.find(zone => zone.id === feature.properties?.areaId)
						// console.log(
						//   "🚀 ~ fetchZoneWithMarkerCoords 4",
						//   fetchZoneWithMarkerCoords
						// );

						poiInsidePolyCoords.push(fetchZoneWithMarkerCoords)
					}
				})

			// console.log(floorPlan.id, "FLOORPLAN ID 2 ");
			// console.log("🚀 ~ finalFeaturesLines", finalFeaturesLines);
			// console.log("🚀 ~ poiInsidePolyCoords", poiInsidePolyCoords);

			// if POI not inside a polygon
			if (poiInsidePolyCoords.length === 0) {
				coordsStartPoint = [startingPoint.coords?.longitude, startingPoint.coords?.latitude]
			}

			// if POI is inside a polygon
			if (poiInsidePolyCoords.length !== 0) {
				setPoiToDoorCoords([
					poiInsidePolyCoords[0]?.doorMarker?.longitude,
					poiInsidePolyCoords[0]?.doorMarker?.latitude
				])
				coordsStartPoint = [
					poiInsidePolyCoords[0]?.doorMarker?.longitude,
					poiInsidePolyCoords[0]?.doorMarker?.latitude
				]
			}

			setCopyOfStartPointCoords() // need to reset the copy of the starting points
			// ↓ Start Point (point selected where the user want to start) (marker of door if inside area or if not starting point coords)
			startPointTurfed = turf.point(coordsStartPoint)
		}
		//━━━━━━━━━━━━━━━ startingPoint.group === "Zones / Areas" ━━━━━━━━━━━━━━━\\
		if (startingPoint.group === "Areas") {
			// not needed anymore ↓
			// setPoiToDoorCoords([
			//   startingPoint.doorMarker?.longitude,
			//   startingPoint.doorMarker?.latitude,
			// ]);

			setPoiToDoorCoords()

			startPointTurfed = turf.point([
				startingPoint.doorMarker?.longitude,
				startingPoint.doorMarker?.latitude
			])

			setCopyOfStartPointCoords([
				startingPoint.doorMarker?.longitude,
				startingPoint.doorMarker?.latitude
			])
		}

		//━━━━━━━━━━━━━━━ startingPoint.group === "People" ━━━━━━━━━━━━━━━\\
		if (startingPoint.group === "People") {
			if (!floorPlan.geoJson) {
				return
			}

			// If POI is inside a polygon
			const poiInsidePolyCoords = []
			floorPlan &&
				floorPlan.geoJson.features.forEach(feature => {
					let coordinates = []
					if (feature.properties?.name === "Base Layer") {
						return
					}
					feature.geometry.coordinates.forEach(coords => {
						coordinates.push([coords.longitude, coords.latitude])
					})

					// console.log("🚀 ~ coordinates", coordinates);
					const poly = turf.polygon([coordinates])
					// console.log(
					//   turf.booleanPointInPolygon(
					//     [
					//       startingPoint?.gpsData?.longitude,
					//       startingPoint?.gpsData?.latitude,
					//     ],
					//     poly
					//   ),
					//   "BOOLEAN EXISTS"
					// );
					const existsInsidePolygon = turf.booleanPointInPolygon(
						[startingPoint?.gpsData?.longitude, startingPoint?.gpsData?.latitude],
						poly
					)
					if (existsInsidePolygon) {
						// console.log(feature, "feature that exists POI inside polygon");
						// console.log(
						//   startingPoint,
						//   "startingPoint that exists POI inside polygon"
						// );

						const fetchZoneWithMarkerCoords =
							zones && zones.find(zone => zone.id === feature.properties?.areaId)
						// console.log(
						//   "🚀 ~ fetchZoneWithMarkerCoords 4",
						//   fetchZoneWithMarkerCoords
						// );

						poiInsidePolyCoords.push(fetchZoneWithMarkerCoords)
					}
				})

			// console.log("🚀 ~ finalFeaturesLines", finalFeaturesLines);
			// console.log("🚀 ~ poiInsidePolyCoords", poiInsidePolyCoords);

			// if POI not inside a polygon
			if (poiInsidePolyCoords.length === 0) {
				coordsStartPoint = [startingPoint.gpsData?.longitude, startingPoint.gpsData?.latitude]
			}

			// if POI is inside a polygon
			if (poiInsidePolyCoords.length !== 0) {
				setPoiToDoorCoords([
					poiInsidePolyCoords[0]?.doorMarker?.longitude,
					poiInsidePolyCoords[0]?.doorMarker?.latitude
				])
				coordsStartPoint = [
					poiInsidePolyCoords[0]?.doorMarker?.longitude,
					poiInsidePolyCoords[0]?.doorMarker?.latitude
				]
			}

			setCopyOfStartPointCoords([
				startingPoint.gpsData?.longitude,
				startingPoint.gpsData?.latitude
			])
			// setCopyOfStartPointCoords() // need to reset the copy of the starting points
			// ↓ Start Point (point selected where the user want to start) (marker of door if inside area or if not starting point coords)
			startPointTurfed = turf.point(coordsStartPoint)
		}

		// console.log(
		//   "⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ startPointTurfed:",
		//   startPointTurfed
		// );

		//━━━━━━━━━━━━━━━ EndPoint for all groups ━━━━━━━━━━━━━━━\\
		//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\
		setPoiToDoorEndPointCoords() // this tells the end is the connector so it needs to be empty state
		// setPoiToDoorCoords(coordsStartPoint); // old
		// setCopyOfStartPointCoords(conditionalCoords); // not needed

		// ↓ End Point (point selected where the user want to go) (the connector )
		const coordsEndPoint = []
		selectedConnector &&
			coordsEndPoint.push(
				selectedConnector.geometry.coordinates[0],
				selectedConnector.geometry.coordinates[1]
			)

		// console.log("🚀 ~ selectedConnector", selectedConnector);

		// ↓ Get connectorPositionOnFloors coords in selected floor if they have this coords array, else use coords of geoJsonRoutes
		const connectorPositionInSelectedFloor =
			selectedConnector?.properties?.connectorPositionOnFloors &&
			selectedConnector?.properties?.connectorPositionOnFloors.find(
				marker => marker.floorPlanId === startingPoint.floorPlanId
			)
		// console.log(
		//   "🚀 ~ connectorPositionInSelectedFloor",
		//   connectorPositionInSelectedFloor
		// );

		const connectorPositionFetchedCoords = connectorPositionInSelectedFloor && [
			connectorPositionInSelectedFloor.coords.longitude,
			connectorPositionInSelectedFloor.coords.latitude
		]
		// console.log(
		//   "🚀 ~ connectorPositionFetchedCoords",
		//   connectorPositionFetchedCoords
		// );

		const conditionalCoords = connectorPositionFetchedCoords
			? connectorPositionFetchedCoords
			: coordsEndPoint
		// console.log("🚀 ~ conditionalCoords 2", conditionalCoords);

		const destinationPointTurfed = turf.point(conditionalCoords)
		// console.log(
		//   "⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ ~ destinationPointTurfed",
		//   destinationPointTurfed
		// );

		// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ Find Path results ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\

		const nearestStartPoint =
			startPointTurfed && turf.nearestPoint(startPointTurfed, turf.featureCollection(points))
		// console.log("🚀 ~ nearestStartPoint", nearestStartPoint);

		const nearestEndPoint =
			destinationPointTurfed &&
			turf.nearestPoint(destinationPointTurfed, turf.featureCollection(points))
		// console.log("🚀 ~ nearestEndPoint", nearestEndPoint);

		const start = nearestStartPoint && turf.point(nearestStartPoint.geometry.coordinates)
		// console.log("🚀 ~ start", start);

		const end = nearestEndPoint && turf.point(nearestEndPoint.geometry.coordinates)
		// console.log("🚀 ~ end", end);

		const path = start && end && pathFinderOnSameFloor.findPath(start, end)
		// console.log("Path: ", path);

		// ↓ Sets path on different floor
		if (path?.path) {
			// ↓ Set path to state
			setPathFound(path)

			// ↓ Closes directions box
			// path?.path &&
			//   setGetDirections({
			//     type: null,
			//     poi: null,
			//     clicked: false,
			//   });
		} else {
			dispatch({
				type: "SNACKBAR_WARNING",
				payload: { message: "Something went wrong with this path." }
			})
		}
	}
}

// ━━━━━━━━━━━━━━━ addPathOnDifferentFloorplanWithEndingPoint ━━━━━━━━━━━━━━━ \\
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\
export function addPathOnDifferentFloorplanWithEndingPoint(props) {
	const {
		selectedConnector,
		startingPoint,
		geoJsonRoutes,
		dispatch,
		setPathFound,
		setCopyOfStartPointCoords,
		floorPlan,
		zones,
		setPoiToDoorEndPointCoords,
		setPoiToDoorCoords,
		setCopyOfConnectorCoords
	} = props

	// ━━━━━━━━━━━━━━━ Add new Path after travelling to another level by connector ━━━━━━━━━━━━━━━ \\
	let finalFeaturesLines = []
	geoJsonRoutes &&
		geoJsonRoutes.features.forEach((feature, index) => {
			if (feature.geometry.type === "LineString") {
				let coordinates = []
				feature.geometry.coordinates.forEach(coords => {
					coordinates.push([coords.longitude, coords.latitude])
				})
				finalFeaturesLines.push({
					type: geoJsonRoutes.features[index].type,
					properties: {
						...geoJsonRoutes.features[index].properties
					},
					geometry: {
						type: geoJsonRoutes.features[index].geometry.type,
						coordinates: coordinates
					}
				})
			}
		})

	// console.log("🚀 ~ finalFeaturesLines", finalFeaturesLines);

	const finalLayerLines = {
		type: "FeatureCollection",
		features: finalFeaturesLines
	}
	// console.log(finalLayerLines, "finalLayerLines");

	const chunk = turf.lineChunk(finalLayerLines, 0.0005, {
		// ← turn lines into little chunks.
		units: "kilometers"
	})
	// console.log("🚀 ~ chunk", chunk);

	const pathFinderOnSameFloor = new PathFinder(chunk, { precision: 1e-5 })
	// console.log("🚀 ~ pathFinderOnSameFloor", pathFinderOnSameFloor);

	const points = []

	for (const [key /*value*/] of Object.entries(pathFinderOnSameFloor._graph.vertices)) {
		// console.log("Key: ", key);
		const latlong = key.split(",")
		const lng = parseFloat(latlong[0])
		const lat = parseFloat(latlong[1])
		points.push(turf.point([lng, lat]))
	}
	// console.log("Points: ", JSON.stringify(points));

	//━━━━━━━━━━━━━━━ selectedConnector && Ending Point ━━━━━━━━━━━━━━━\\
	//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\
	if (selectedConnector && startingPoint) {
		// console.log(
		//   startingPoint,
		//   "🚀🚀🚀 startingPoint point on NEW FUNCTION !!!!"
		// );
		// console.log(
		//   selectedConnector,
		//   "🛗🛗🛗🛗 selectedConnector on NEW FUNCTION !!!!"
		// );

		let startPointTurfed = null
		// ↓ Start Point (point selected where the user want to start)
		let coordsStartPoint = []

		//━━━━━━━━━━━━━━━ startingPoint.group === "Points of Interest" ━━━━━━━━━━━━━━━\\
		if (startingPoint.group === "Points of Interest") {
			if (!floorPlan.geoJson) {
				return
			}
			// If POI is inside a polygon
			const poiInsidePolyCoords = []
			floorPlan &&
				floorPlan.geoJson.features.forEach(feature => {
					let coordinates = []
					if (feature.properties?.name === "Base Layer") {
						return
					}
					feature.geometry.coordinates.forEach(coords => {
						coordinates.push([coords.longitude, coords.latitude])
					})
					// console.log("🚀 ~ coordinates", coordinates);
					const poly = turf.polygon([coordinates])
					// console.log(
					//   turf.booleanPointInPolygon(
					//     [
					//       startingPoint?.coords?.longitude,
					//       startingPoint?.coords?.latitude,
					//     ],
					//     poly
					//   ),
					//   "BOOLEAN EXISTS"
					// );
					const existsInsidePolygon = turf.booleanPointInPolygon(
						[startingPoint?.coords?.longitude, startingPoint?.coords?.latitude],
						poly
					)
					if (existsInsidePolygon) {
						// console.log(feature, "feature that exists POI inside polygon");
						// console.log(
						//   startingPoint,
						//   "startingPoint that exists POI inside polygon"
						// );
						const fetchZoneWithMarkerCoords =
							zones && zones.find(zone => zone.id === feature.properties?.areaId)
						// console.log(
						//   "🚀 ~ fetchZoneWithMarkerCoords 4",
						//   fetchZoneWithMarkerCoords
						// );
						poiInsidePolyCoords.push(fetchZoneWithMarkerCoords)
					}
				})
			// console.log(floorPlan.id, "FLOORPLAN ID 2 ");
			// console.log("🚀 ~ finalFeaturesLines", finalFeaturesLines);
			// console.log("🚀 ~ poiInsidePolyCoords", poiInsidePolyCoords);

			// if POI not inside a polygon
			if (poiInsidePolyCoords.length === 0) {
				coordsStartPoint = [startingPoint.coords?.longitude, startingPoint.coords?.latitude]
			}
			// if POI is inside a polygon
			if (poiInsidePolyCoords.length !== 0) {
				setPoiToDoorCoords([
					poiInsidePolyCoords[0]?.doorMarker?.longitude,
					poiInsidePolyCoords[0]?.doorMarker?.latitude
				])
				coordsStartPoint = [
					poiInsidePolyCoords[0]?.doorMarker?.longitude,
					poiInsidePolyCoords[0]?.doorMarker?.latitude
				]
			}
			setCopyOfStartPointCoords() // need to reset the copy of the starting points
			// ↓ Start Point (point selected where the user want to start) (marker of door if inside area or if not starting point coords)
			startPointTurfed = turf.point(coordsStartPoint)
		}
		// console.log(
		//   "⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ startPointTurfed:",
		//   startPointTurfed
		// );

		//━━━━━━━━━━━━━━━ startingPoint.group === "Zones / Areas" ━━━━━━━━━━━━━━━\\
		if (startingPoint.group === "Areas") {
			setPoiToDoorCoords()

			startPointTurfed = turf.point([
				startingPoint.doorMarker?.longitude,
				startingPoint.doorMarker?.latitude
			])

			setCopyOfStartPointCoords([
				startingPoint.doorMarker?.longitude,
				startingPoint.doorMarker?.latitude
			])
		}

		//━━━━━━━━━━━━━━━ startingPoint.group === "People" ━━━━━━━━━━━━━━━\\
		if (
			startingPoint.group === "People" ||
			startingPoint.group === "Assets" ||
			startingPoint.group === "Unassigned"
		) {
			if (!floorPlan.geoJson) {
				return
			}
			// If POI is inside a polygon
			const poiInsidePolyCoords = []
			floorPlan &&
				floorPlan.geoJson.features.forEach(feature => {
					let coordinates = []
					if (feature.properties?.name === "Base Layer") {
						return
					}
					feature.geometry.coordinates.forEach(coords => {
						coordinates.push([coords.longitude, coords.latitude])
					})
					// console.log("🚀 ~ coordinates", coordinates);
					const poly = turf.polygon([coordinates])
					// console.log(
					//   turf.booleanPointInPolygon(
					//     [
					//       startingPoint?.gpsData?.longitude,
					//       startingPoint?.gpsData?.latitude,
					//     ],
					//     poly
					//   ),
					//   "BOOLEAN EXISTS"
					// );
					const existsInsidePolygon = turf.booleanPointInPolygon(
						[startingPoint?.gpsData?.longitude, startingPoint?.gpsData?.latitude],
						poly
					)
					if (existsInsidePolygon) {
						// console.log(feature, "feature that exists POI inside polygon");
						// console.log(
						//   startingPoint,
						//   "startingPoint that exists POI inside polygon"
						// );
						const fetchZoneWithMarkerCoords =
							zones && zones.find(zone => zone.id === feature.properties?.areaId)
						// console.log(
						//   "🚀 ~ fetchZoneWithMarkerCoords 4",
						//   fetchZoneWithMarkerCoords
						// );
						poiInsidePolyCoords.push(fetchZoneWithMarkerCoords)
					}
				})
			// console.log(floorPlan.id, "FLOORPLAN ID 2 ");
			// console.log("🚀 ~ finalFeaturesLines", finalFeaturesLines);
			// console.log("🚀 ~ poiInsidePolyCoords", poiInsidePolyCoords);

			// if POI not inside a polygon
			if (poiInsidePolyCoords.length === 0) {
				coordsStartPoint = [startingPoint.gpsData?.longitude, startingPoint.gpsData?.latitude]
			}
			// if POI is inside a polygon
			if (poiInsidePolyCoords.length !== 0) {
				setPoiToDoorCoords([
					poiInsidePolyCoords[0]?.doorMarker?.longitude,
					poiInsidePolyCoords[0]?.doorMarker?.latitude
				])
				coordsStartPoint = [
					poiInsidePolyCoords[0]?.doorMarker?.longitude,
					poiInsidePolyCoords[0]?.doorMarker?.latitude
				]
			}

			setCopyOfStartPointCoords([
				startingPoint.gpsData?.longitude,
				startingPoint.gpsData?.latitude
			])

			// ↓ Start Point (point selected where the user want to start) (marker of door if inside area or if not starting point coords)
			startPointTurfed = turf.point(coordsStartPoint)
		}

		// console.log(
		//   "⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ startPointTurfed:",
		//   startPointTurfed
		// );

		//━━━━━━━━━━━━━━━ EndPoint for all groups ━━━━━━━━━━━━━━━\\
		//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\
		setPoiToDoorEndPointCoords() // this tells the end is the connector so it needs to be empty state

		// ↓ End Point (point selected where the user want to go) (the connector )
		const coordsEndPoint = []
		selectedConnector &&
			coordsEndPoint.push(
				selectedConnector.geometry.coordinates[0],
				selectedConnector.geometry.coordinates[1]
			)
		// console.log("🚀 ~ selectedConnector", selectedConnector);

		// ↓ Get connectorPositionOnFloors coords in selected floor if they have this coords array, else use coords of geoJsonRoutes
		const connectorPositionInSelectedFloor =
			selectedConnector?.properties?.connectorPositionOnFloors &&
			selectedConnector?.properties?.connectorPositionOnFloors.find(
				marker => marker.floorPlanId === startingPoint.floorPlanId
			)
		// console.log(
		//   "🚀 ~ connectorPositionInSelectedFloor",
		//   connectorPositionInSelectedFloor
		// );

		const connectorPositionFetchedCoords = connectorPositionInSelectedFloor && [
			connectorPositionInSelectedFloor.coords.longitude,
			connectorPositionInSelectedFloor.coords.latitude
		]
		// console.log(
		//   "🚀 ~ connectorPositionFetchedCoords",
		//   connectorPositionFetchedCoords
		// );
		const conditionalCoords = connectorPositionFetchedCoords
			? connectorPositionFetchedCoords
			: coordsEndPoint
		// console.log("🚀 ~ conditionalCoords 2", conditionalCoords);

		// ↓ Copy this connector coords to state, to pass them to addGeoJsonLayerToMapWithNewPath()
		selectedConnector && setCopyOfConnectorCoords(conditionalCoords)

		// // ↓ Copy this connector coords to state, to pass them to addPathOnDifferentFloorplanWithStartingPoint()
		// selectedConnector && setSelectedConnector(selectedConnector);

		const destinationPointTurfed = turf.point(conditionalCoords)
		// console.log(
		//   "⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️⭐️ ~ destinationPointTurfed",
		//   destinationPointTurfed
		// );
		// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ Find Path results ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\
		const nearestStartPoint =
			startPointTurfed && turf.nearestPoint(startPointTurfed, turf.featureCollection(points))
		// console.log("🚀 ~ nearestStartPoint", nearestStartPoint);

		const nearestEndPoint =
			destinationPointTurfed &&
			turf.nearestPoint(destinationPointTurfed, turf.featureCollection(points))
		// console.log("🚀 ~ nearestEndPoint", nearestEndPoint);

		const start = nearestStartPoint && turf.point(nearestStartPoint.geometry.coordinates)
		// console.log("🚀 ~ start", start);

		const end = nearestEndPoint && turf.point(nearestEndPoint.geometry.coordinates)
		// console.log("🚀 ~ end", end);

		const path = start && end && pathFinderOnSameFloor.findPath(start, end)
		// console.log("Path: ", path);

		// ↓ Sets path on different floor
		if (path?.path) {
			// ↓ Set path to state
			setPathFound(path)
		} else {
			dispatch({
				type: "SNACKBAR_WARNING",
				payload: { message: "Something went wrong with this path." }
			})
		}
	}
}
