import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual, useDispatch, connect } from "react-redux"
import { useLocation } from "react-router-dom"
import { FormControl, ListSubheader, MenuItem, Select } from "@material-ui/core"
import { profileSlice } from "../../../../app/redux/profile/profileSlice"
import { firebaseApp, storage, initializeFirestore } from "../../../../firebase"
import {
	doc,
	getDoc,
	collection,
	getDocs,
	onSnapshot,
	getFirestore,
	query,
	where
} from "firebase/firestore"

import { ref, getDownloadURL } from "firebase/storage"
import BusinessIcon from "@material-ui/icons/Business"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import ClearAllIcon from "@material-ui/icons/ClearAll"
import PersonIcon from "@material-ui/icons/Person"
import { Button } from "../../../_partials/Button"
import * as authRedux from "../../../modules/Auth/_redux/authRedux"
import { injectIntl } from "react-intl"
import { ModalProgressBar } from "../../../_partials/ModalProgressBar"

const db = getFirestore(firebaseApp)

const { actions } = profileSlice

function HeaderSelections(props) {
	const dispatch = useDispatch()
	const location = useLocation()

	const { userClaims, floorPlans, currentCustomer, currentSite, currentFloorPlan } = useSelector(
		state => ({
			userClaims: state.auth.claims,
			floorPlans: state.basePage.floorPlans,
			currentCustomer: state.profile.currentCustomer,
			currentSite: state.profile.currentSite,
			currentFloorPlan: state.profile.currentFloorPlan
		}),
		shallowEqual
	)

	const [customers, setCustomers] = useState()
	const [selectedCustomer, setSelectedCustomer] = useState(currentCustomer?.id || null)
	const [selectedSite, setSelectedSite] = useState(currentSite?.id || null)
	const [selectedFloorPlan, setSelectedFloorPlan] = useState(currentFloorPlan?.id || null)
	const [isReporting, setIsReporting] = useState(false)

	const [loadingCustomers, setLoadingCustomers] = useState(true)
	const [loadingSites, setLoadingSites] = useState(true)
	const [loadingFloorPlans, setLoadingFloorPlans] = useState(true)

	const useDocument = path => {
		const [data, setData] = useState(null)
		const [loading, setLoading] = useState(true)

		useEffect(() => {
			if (!path) return

			const documentRef = doc(db, path)
			setLoading(true)

			const unsubscribe = onSnapshot(
				documentRef,
				snapshot => {
					if (snapshot) {
						setData({ id: snapshot.id, ...snapshot.data() })
					} else {
						setData(null)
					}
					setLoading(false)
				},
				error => {
					console.error("Error fetching document:", error)
					setData(null)
					setLoading(false)
				}
			)

			return () => unsubscribe()
		}, [path])

		return { data, loading }
	}

	const { data: customer, loading } = useDocument(
		selectedCustomer ? `Customers/${selectedCustomer}` : null
	)

	useEffect(() => {
		if (!selectedCustomer || !customers) return

		const findCustomer = customers?.find(val => val.id === selectedCustomer)
		initializeFirestore(findCustomer?.databaseId)

		// setSelectedSite(null)
		// setSelectedFloorPlan(null)
	}, [selectedCustomer, customers])

	useEffect(() => {
		if (!customer) {
			dispatch(actions.customerSelected(null))
			return
		}

		const newCustomer = {
			...customer,
			sites: customer?.sites?.filter(val => {
				if (val.disabled) return
				if (userClaims.isSuper) return true
				if (userClaims.sites && !userClaims.sites.includes(val.id)) return
				return true
			})
		}

		dispatch(actions.customerSelected(newCustomer))
	}, [customer])

	//if it has customers pre select the first one
	useEffect(() => {
		if (!customers) return

		if (
			(customers && !selectedCustomer) ||
			selectedCustomer === null ||
			selectedCustomer === ""
		) {
			const selectedId = customers && customers?.[0]?.id
			setSelectedCustomer(selectedId)
			return
		}
	}, [customers])

	// If there is only one site, auto select that one
	useEffect(() => {
		if (
			selectedSite ||
			!currentCustomer?.sites ||
			currentCustomer?.sites.length > 1 ||
			currentCustomer?.sites.length === 0
		)
			return

		setSelectedSite(currentCustomer?.sites[0].id)
	}, [currentCustomer])

	// If there is no floorplan selected, auto select one
	useEffect(() => {
		if (!floorPlans || floorPlans.length === 0) return

		const selectedId = floorPlans[0]?.id

		if (!selectedFloorPlan) {
			setSelectedFloorPlan(selectedId)
			return
		}

		if (selectedFloorPlan && floorPlans.some(val => val.id === selectedFloorPlan)) return

		if (selectedId !== null) {
			setSelectedFloorPlan(selectedId)
			return
		}

		// console.log("Maybe Did this?")
		setSelectedFloorPlan(null)
	}, [floorPlans, selectedFloorPlan])

	useEffect(() => {
		const fetchSiteData = async () => {
			if (!selectedSite) {
				dispatch(actions.siteSelected(null))
				setLoadingSites(false)
				return
			}

			try {
				setLoadingSites(true)
				const siteDoc = doc(db, "Sites", selectedSite)
				const docSnap = await getDoc(siteDoc)

				if (!docSnap) {
					dispatch(actions.siteSelected(null))
					setLoadingSites(false)
					return
				}

				dispatch(actions.siteSelected({ id: docSnap.id, ...docSnap.data() }))
				setLoadingSites(false)
			} catch (error) {
				console.error("Error fetching data from Firestore:", error)
				dispatch(actions.siteSelected(null))
				setLoadingSites(false)
			}
		}

		fetchSiteData()

		// if (floorPlans?.length === 1) {
		// 	console.log("🚀 ~ useEffect ~ floorPlans:", floorPlans)
		// 	setSelectedFloorPlan(floorPlans[0])
		// 	onChangeFloorPlanHandler(floorPlans[0])
		// }
	}, [selectedSite])

	useEffect(() => {
		const fetchFloorPlanData = async () => {
			if (!currentSite || !selectedFloorPlan) {
				dispatch(actions.floorPlanSelected())
				setLoadingFloorPlans(false)
				return
			}

			try {
				setLoadingFloorPlans(true)

				const floorPlanDoc = doc(
					collection(db, "Sites", currentSite.id, "FloorPlans"),
					selectedFloorPlan
				)
				const docSnap = await getDoc(floorPlanDoc)

				if (!docSnap) return

				const floor = docSnap.data()
				let imageUrl = ""

				if (floor.imagePath) {
					try {
						imageUrl = await getDownloadURL(ref(storage, floor.imagePath))
					} catch (error) {
						console.error("Error fetching image URL:", error)
					}
				}

				dispatch(
					actions.floorPlanSelected({
						id: docSnap.id,
						...floor,
						imageUrl
					})
				)
				setLoadingFloorPlans(false)
			} catch (error) {
				setLoadingFloorPlans(false)
				console.error("Error fetching floor plan data:", error)
				dispatch(actions.floorPlanSelected())
			}
		}

		fetchFloorPlanData()
	}, [currentSite, selectedFloorPlan])

	// async function getCustomers(db, userClaims) {
	// 	try {
	// 		const customersCollection = collection(db, "Customers")
	// 		let customersQuery

	// 		// If profile level is 3, filter by customer IDs
	// 		if (userClaims.profileLvl === 3) {
	// 			// Use "in" to query by customer IDs if profileLvl is 3
	// 			customersQuery = query(
	// 				customersCollection,
	// 				where("tenantID", "in", userClaims.customerIds),
	// 				where("customerType", "==", "healthcare")
	// 			)
	// 		} else {
	// 			// Default query (for other profile levels)
	// 			customersQuery = query(customersCollection, where("customerType", "==", "healthcare"))
	// 		}

	// 		const snapshot = await getDocs(customersQuery)
	// 		const data = snapshot.docs.map(doc => ({
	// 			...doc.data(),
	// 			id: doc.id
	// 		}))

	// 		return data
	// 	} catch (error) {
	// 		console.error("Error validating Firestore database:", error)
	// 		return []
	// 	}
	// }

	// useEffect(() => {
	// 	const fetchCustomers = async () => {
	// 		try {
	// 			const data = await getCustomers(db, userClaims)

	// 			if (userClaims.isSuper) setCustomers(data) //isSuper
	// 			else if (userClaims?.profileLvl === 2) {
	// 				//isUser/isAdmin
	// 				setSelectedCustomer(userClaims.customerId)
	// 			} else if (userClaims?.profileLvl === 3) {
	// 				// isMSP
	// 				const filteredData = data.filter(customer =>
	// 					userClaims.customerIds.includes(customer.id)
	// 				)
	// 				setCustomers(filteredData)
	// 			}
	// 		} catch (error) {
	// 			console.error("Error fetching customers:", error)
	// 		}
	// 	}

	// 	fetchCustomers()
	// }, [currentCustomer, userClaims])

	function getCustomersSnapshot(db, userClaims, setCustomers) {
		try {
			const customersCollection = collection(db, "Customers")
			let customersQuery

			// If profile level is 3, filter by customer IDs
			if (userClaims.profileLvl === 3) {
				// Use "in" to query by customer IDs if profileLvl is 3
				customersQuery = query(
					customersCollection,
					where("tenantID", "in", userClaims.customerIds),
					where("customerType", "==", "healthcare")
				)
			} else {
				// Default query (for other profile levels)
				customersQuery = query(customersCollection, where("customerType", "==", "healthcare"))
			}

			const unsubscribe = onSnapshot(
				customersQuery,
				snapshot => {
					const data = snapshot.docs.map(doc => ({
						...doc.data(),
						id: doc.id
					}))
					setCustomers(data) // Update state with real-time data
					setLoadingCustomers(false)
				},
				error => {
					console.error("Error fetching customers snapshot:", error)
					setCustomers([]) // Fallback to empty list on error
					setLoadingCustomers(false)
				}
			)

			// Return unsubscribe function to clean up listener
			return unsubscribe
		} catch (error) {
			console.error("Error setting up customer snapshot:", error)
			setLoadingCustomers(false)
			return null
		}
	}
	useEffect(() => {
		if (!db || !userClaims) return

		setLoadingCustomers(false)

		const unsubscribe = getCustomersSnapshot(db, userClaims, data => {
			if (userClaims.isSuper) {
				setCustomers(data) // isSuper
			} else if (userClaims?.profileLvl === 2) {
				// isUser/isAdmin
				setSelectedCustomer(userClaims.customerId)
			} else if (userClaims?.profileLvl === 3) {
				// isMSP
				const filteredData = data.filter(customer =>
					userClaims.customerIds.includes(customer.id)
				)
				setCustomers(filteredData)
			}
			setLoadingCustomers(false)
		})

		setLoadingCustomers(false)

		// Cleanup the subscription on unmount
		return () => {
			if (unsubscribe) unsubscribe()
		}
	}, [db, userClaims])

	useEffect(() => {
		setIsReporting(location.pathname.includes("reporting"))
	}, [location.pathname])

	const onChangeFloorPlanHandler = floorId => {
		const foundedFloorPlan = floorPlans && floorPlans.find(f => f.id === floorId)

		foundedFloorPlan && dispatch(actions.floorPlanSelected(foundedFloorPlan)) // changes floorplan
	}

	return (
		<div className="header-selections">
			{/*\★━━━━━━━━━━━★ Select customer ★━━━━━━━━━━━★\*/}
			{customers && userClaims.profileLvl >= 3 && (
				<div style={{ marginLeft: "10px" }}>
					<BusinessIcon />
					<FormControl size="small" style={{ marginLeft: "5px" }}>
						<Select
							id="select_customer_header_dropdown"
							displayEmpty
							disableUnderline
							value={selectedCustomer || ""}
							onChange={e => {
								setSelectedCustomer(e.target.value)
								// Make current customer null so that auto site selection does not select from old customer
								dispatch(actions.customerSelected(null))
								setSelectedSite(null)
								setSelectedFloorPlan()
							}}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 48 * 3 + 8,
										width: 250
									}
								},
								getContentAnchorEl: null,
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "left"
								}
							}}
						>
							<MenuItem value="" disabled>
								Select customer
							</MenuItem>
							{customers.map(val => {
								return (
									<MenuItem key={val.id} value={val.id}>
										{val.customerInfo?.name}
									</MenuItem>
								)
							})}
						</Select>
					</FormControl>
					{loadingCustomers && <ModalProgressBar />}
				</div>
			)}

			{userClaims.profileLvl >= 1 && (
				<div style={{ marginLeft: "10px" }}>
					{/*\ ━━━━━━━━━━━  Select location  ━━━━━━━━━━━ \*/}
					<LocationOnIcon />
					<FormControl size="small" style={{ marginLeft: "5px" }}>
						<Select
							id="select_site_header_dropdown"
							displayEmpty
							disableUnderline
							value={currentSite?.id || ""}
							onChange={e => {
								if (e.target.value && e.target.value === currentSite?.id) return

								if (e.target.value === "") {
									dispatch(actions.siteSelected(null)) // set selectSite to null when clicking on Select Location option
								}

								setSelectedSite(e.target.value)
							}}
						>
							<MenuItem value="" disabled={currentCustomer?.sites?.length < 2}>
								Select location
							</MenuItem>
							<ListSubheader
								style={{
									fontSize: "13px",
									fontWeight: 500,
									userSelect: "none",
									pointerEvents: "none"
								}}
							>
								Active Sites:
							</ListSubheader>

							{currentCustomer?.sites &&
								currentCustomer.sites.map(val => {
									if (!val.siteFinished) {
										return (
											<MenuItem key={val.id} value={val.id}>
												{val.name}
											</MenuItem>
										)
									}
								})}
							{currentCustomer?.sites &&
								currentCustomer.sites.some(site => site.siteFinished) && (
									<ListSubheader
										style={{
											fontSize: "13px",
											fontWeight: 500,
											userSelect: "none",
											pointerEvents: "none"
										}}
									>
										Completed Sites:
									</ListSubheader>
								)}
							{currentCustomer?.sites &&
								currentCustomer.sites.map(val => {
									if (val.siteFinished) {
										return (
											<MenuItem key={val.id} value={val.id}>
												{val.name}
											</MenuItem>
										)
									}
								})}
						</Select>
					</FormControl>
					{loadingSites && <ModalProgressBar />}
				</div>
			)}

			{userClaims.profileLvl >= 1 && !isReporting && (
				<div style={{ marginLeft: "10px" }}>
					{/*\ ━━━━━━━━━━━  Select level  ━━━━━━━━━━━ \*/}
					<ClearAllIcon />
					<FormControl size="small" style={{ marginLeft: "5px" }}>
						<Select
							id="select_floorplan_header_dropdown"
							displayEmpty
							disableUnderline
							value={
								floorPlans.length > 0 && currentFloorPlan?.id ? currentFloorPlan?.id : ""
							}
							onChange={e => {
								setSelectedFloorPlan(e.target.value)
								onChangeFloorPlanHandler(e.target.value) // need this to match the selectedFloorPlan and currentFloorPlan
							}}
						>
							<MenuItem value="" disabled>
								Select floorplan
							</MenuItem>
							{floorPlans &&
								floorPlans.map((val, i) => {
									return (
										<MenuItem key={i} value={val?.id}>
											{val?.name}
										</MenuItem>
									)
								})}
						</Select>
					</FormControl>
					{loadingFloorPlans && <ModalProgressBar />}
				</div>
			)}

			{userClaims.isSuper && (
				<div style={{ marginLeft: "10px" }}>
					{/*\ ━━━━━━━━━━━  Select level  ━━━━━━━━━━━ \*/}
					<PersonIcon />
					<FormControl size="small" style={{ marginLeft: "5px" }}>
						<Select
							displayEmpty
							disableUnderline
							value={
								userClaims.profileLvl === 3
									? "msp"
									: userClaims.profileLvl === 2
									? "admin"
									: userClaims.profileLvl === 1
									? "user"
									: ""
							}
							onChange={e => {
								if (!userClaims.isSuper) return
								if (e.target.value === "msp") {
									const claims = {
										...userClaims,
										profileLvl: 3
									}
									props.setUserClaims(claims)
								} else if (e.target.value === "admin") {
									const claims = {
										...userClaims,
										profileLvl: 2
									}
									props.setUserClaims(claims)
								} else if (e.target.value === "user") {
									const claims = {
										...userClaims,
										profileLvl: 1
									}
									props.setUserClaims(claims)
								} else {
									const claims = {
										...userClaims,
										profileLvl: authRedux.getProfileLvl(userClaims)
									}
									props.setUserClaims(claims)
								}
							}}
						>
							<MenuItem value="">Original role</MenuItem>
							<MenuItem value="msp">MSP</MenuItem>
							<MenuItem value="admin">Admin</MenuItem>
							<MenuItem value="user">User</MenuItem>
						</Select>
					</FormControl>
				</div>
			)}
		</div>
	)
}

export default injectIntl(connect(null, authRedux.actions)(HeaderSelections))
