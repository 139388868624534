import React from "react"
import ReactDOM from "react-dom"
import { DesignStudioSlice } from "../../../_redux/admin/designStudio/DesignStudioSlice"
import mapboxgl from "!mapbox-gl"
import { OverlayTrigger, Tooltip, Carousel } from "react-bootstrap"
import PopupStickOnHoverManageInfrastructure from "./PopupStickOnHoverManageInfrastructure"
import "./AnchorsPositioning.css"
import moment from "moment"
import { Button } from "../../../../_partials/Button"
import { BiEditAlt } from "react-icons/bi"

const actionsDesignStudio = DesignStudioSlice.actions

// Progress button #buttonToProgress2 and 3
export function startButtonProgress(button, progressClassName, textClassName) {
	if (button && progressClassName && textClassName) {
		// if (button.id === "buttonToProgress_createPoi") {
		const textElement = button.querySelector(`.${textClassName}`)
		// Simulate counting to 99%
		let percent = 0
		for (var j = 0; j < 100; j++) {
			percent = j
			button.querySelector(`.${progressClassName}`).style.width = `${percent}%`

			if (percent >= 0) {
				textElement.textContent = button.dataset.progressText
			}
			if (percent >= 100) {
				textElement.textContent = button.dataset.completeText
			}
		}
		// }
	}
}

// Finish progress button #buttonToProgress2 and 3
export function finishButtonProgress(button, progressClassName, textClassName, actionFinished) {
	if (button && progressClassName && textClassName && actionFinished === 100) {
		// if (button.id === "buttonToProgress_createPoi") {
		const textElement = button.querySelector(`.${textClassName}`)
		// Simulate counting to 100%
		let percent = actionFinished
		button.querySelector(`.${progressClassName}`).style.width = `${percent}%`

		if (percent >= 0) {
			textElement.textContent = button.dataset.progressText
		}
		if (percent >= 100) {
			textElement.textContent = button.dataset.completeText
		}
		// }
	}
}

export async function addAnchorsToMap(params) {
	const {
		map,
		selectedSite,
		currentFloorPlan,
		anchorsData,
		anchorsMarkers,
		setAnchorsMarkers,
		showAnchorsOnline,
		showAnchorsRemoved,
		showAnchorsLost,
		setShowActiveModal,
		setActiveAnchorToUpdate,
		setShowBigImageModalActive,
		setSelectedBigImageActive,
		setSelectedImageIndexActive,
		removePopupFunction
	} = params

	if (!map || !selectedSite || !currentFloorPlan) return

	// Remove anchor markers
	anchorsMarkers && anchorsMarkers.forEach(marker => marker.remove())
	setAnchorsMarkers()

	var _anchors = anchorsData
		? anchorsData
				.filter(
					val =>
						val?.floorPlanId &&
						val?.floorPlanId === currentFloorPlan.id &&
						val?.status &&
						val?.status != "assigned" &&
						val?.status != "created" &&
						val?.status != "unassigned"
				)
				.filter(val => {
					if (!showAnchorsOnline && !showAnchorsRemoved && !showAnchorsLost) {
						return false // Exclude all statuses if all flags are false
					} else if (!showAnchorsOnline && !showAnchorsRemoved) {
						return val.status !== "online" && val.status !== "removed"
					} else if (!showAnchorsOnline && !showAnchorsLost) {
						return val.status !== "online" && val.status !== "lost"
					} else if (!showAnchorsRemoved && !showAnchorsLost) {
						return val.status !== "removed" && val.status !== "lost"
					} else if (!showAnchorsOnline) {
						return val.status !== "online"
					} else if (!showAnchorsRemoved) {
						return val.status !== "removed"
					} else if (!showAnchorsLost) {
						return val.status !== "lost"
					}
					return true // Include all statuses by default if no flags are false
				})
		: []

	// Add marker for each active anchor
	const newMarkers =
		_anchors &&
		_anchors
			.map(val => {
				// Check if all the needed data is present
				if (!val.gpsData) return null

				// check if is a firestore timestamp or string
				const parsedTimestampFirstSeen =
					val && val.firstSeen && val.firstSeen.toDate
						? moment(val.firstSeen.toDate()).format("hh:mm A")
						: null

				const parsedTimestampLastSeen =
					val && val.lastSeen && val.lastSeen.toDate
						? moment(val.lastSeen.toDate()).format("hh:mm A")
						: null

				const checkIn =
					val && val?.firstSeen && parsedTimestampFirstSeen
						? parsedTimestampFirstSeen
						: val && val?.firstSeen
						? moment(val.firstSeen).format("hh:mm A")
						: "--:--"

				const lastSeen =
					val && val?.lastSeen && parsedTimestampLastSeen
						? parsedTimestampLastSeen
						: val && val?.lastSeen
						? moment(val.lastSeen).format("hh:mm A")
						: "--:--"

				const div = document.createElement("div")
				div.className = "connectorsMarkerDashboard"
				div.style.cursor = "pointer"

				const popupStickNew = (
					<PopupStickOnHoverManageInfrastructure
						component={
							<div
								style={{
									background: "#ECF1F4",
									boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
									borderRadius: "8px",
									fontFamily: "Poppins",
									fontStyle: "normal",
									color: "#4A4A68",
									paddingBottom: "2px",
									width: "276px",
									marginBottom: "-5px"
								}}
							>
								<Carousel
									indicators={false}
									controls={val?.imagesUrls?.length > 1}
									interval={1500}
								>
									{val?.imagesUrls?.length > 0 &&
										val.imagesUrls.map((arrayUrl, i) => (
											<Carousel.Item key={i} interval={1000}>
												<img
													src={arrayUrl}
													alt="anchor"
													style={{
														borderRadius: "5px 5px 0px 0px",
														height: "200px",
														width: "276px",
														objectFit: "cover",
														objectPosition: "center",
														cursor: "pointer"
													}}
													onClick={e => {
														setSelectedBigImageActive(val)
														setSelectedImageIndexActive(i)
														setShowBigImageModalActive(true)
														removePopupFunction("popup_stick_active_anchors") // remove popup onClick
													}}
												/>
											</Carousel.Item>
										))}{" "}
								</Carousel>
								<div className="d-flex justify-content-between align-items-center popup_title">
									<span>{val.uuid}</span>
									<div>
										<OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
											<Button
												className="anchor_active_popup_edit_btn"
												color="primaryLight"
												startIcon={<BiEditAlt />}
												onClick={() => {
													setShowActiveModal(true)
													setActiveAnchorToUpdate(val) // updatePointOfInterest(poi.id)
													removePopupFunction("popup_stick_active_anchors")
												}}
											/>
										</OverlayTrigger>
									</div>
								</div>
								<TimeInfo anchor={val} checkIn={checkIn} lastSeen={lastSeen} />
								{val?.description && val?.description !== "" && (
									<DescriptionInfo anchor={val} />
								)}
							</div>
						}
						placement="top"
						onMouseEnter={() => {}}
						delay={500}
						className="popup_stick_active_anchors"
					>
						<div
							className="popupLineRoutes"
							style={{
								backgroundColor:
									val.status === "online"
										? "#2649AA"
										: val.status === "lost"
										? "#a43912"
										: val.status === "removed"
										? "#ff0000"
										: "#3B7E38",
								borderRadius: "50%",
								width: "34px",
								height: "34px",
								outline: "2px solid #ffffff"
							}}
						>
							<img
								src="/markers/anchor.svg"
								alt="anchor icon"
								className="iconImageAnchorsPosition"
							/>
						</div>
					</PopupStickOnHoverManageInfrastructure>
				)

				ReactDOM.render(popupStickNew, div)

				const marker = new mapboxgl.Marker(div, {
					draggable: false
				})

				marker.setLngLat([val.gpsData.longitude, val.gpsData.latitude]).addTo(map)
				return marker
			})
			.filter(val => val)

	// console.log("🚀 ~ addAnchorsToMap ~ newMarkers:", newMarkers)
	setAnchorsMarkers(newMarkers)
	return
}

export async function addSensorsToMap(params) {
	const {
		map,
		selectedSite,
		currentFloorPlan,
		sensorsData,
		sensorsMarkers,
		setSensorsMarkers,
		showSensorsOnline,
		showSensorsRemoved,
		showSensorsLost,
		setShowActiveModal,
		setActiveSensorToUpdate,
		setShowBigImageModalActive,
		setSelectedBigImageActive,
		setSelectedImageIndexActive,
		removePopupFunction
	} = params

	if (!map || !selectedSite || !currentFloorPlan) return

	// Remove sensor markers
	sensorsMarkers && sensorsMarkers.forEach(marker => marker.remove())
	setSensorsMarkers()

	var _sensors = sensorsData
		? sensorsData
				.filter(
					val =>
						val?.floorPlanId &&
						val?.floorPlanId === currentFloorPlan.id &&
						val?.status &&
						val?.status != "assigned" &&
						val?.status != "created" &&
						val?.status != "unassigned"
				)
				.filter(val => {
					if (!showSensorsOnline && !showSensorsRemoved && !showSensorsLost) {
						return false // Exclude all statuses if all flags are false
					} else if (!showSensorsOnline && !showSensorsRemoved) {
						return val.status !== "online" && val.status !== "removed"
					} else if (!showSensorsOnline && !showSensorsLost) {
						return val.status !== "online" && val.status !== "lost"
					} else if (!showSensorsRemoved && !showSensorsLost) {
						return val.status !== "removed" && val.status !== "lost"
					} else if (!showSensorsOnline) {
						return val.status !== "online"
					} else if (!showSensorsRemoved) {
						return val.status !== "removed"
					} else if (!showSensorsLost) {
						return val.status !== "lost"
					}
					return true // Include all statuses by default if no flags are false
				})
		: []

	// Add marker for each active sensor
	const newMarkers =
		_sensors &&
		_sensors
			.map(val => {
				// Check if all the needed data is present
				if (!val.gpsData) return null

				// check if is a firestore timestamp or string
				const parsedTimestampFirstSeen =
					val && val.firstSeen && val.firstSeen.toDate
						? moment(val.firstSeen.toDate()).format("hh:mm A")
						: null

				const parsedTimestampLastSeen =
					val && val.lastSeen && val.lastSeen.toDate
						? moment(val.lastSeen.toDate()).format("hh:mm A")
						: null

				const checkIn =
					val && val?.firstSeen && parsedTimestampFirstSeen
						? parsedTimestampFirstSeen
						: val && val?.firstSeen
						? moment(val.firstSeen).format("hh:mm A")
						: "--:--"

				const lastSeen =
					val && val?.lastSeen && parsedTimestampLastSeen
						? parsedTimestampLastSeen
						: val && val?.lastSeen
						? moment(val.lastSeen).format("hh:mm A")
						: "--:--"

				const div = document.createElement("div")
				div.className = "connectorsMarkerDashboard"
				div.style.cursor = "pointer"

				const popupStickNew = (
					<PopupStickOnHoverManageInfrastructure
						component={
							<div
								style={{
									background: "#ECF1F4",
									boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
									borderRadius: "8px",
									fontFamily: "Poppins",
									fontStyle: "normal",
									color: "#4A4A68",
									paddingBottom: "2px",
									width: "276px"
								}}
							>
								<Carousel
									indicators={false}
									controls={val?.imagesUrls?.length > 1}
									interval={1500}
								>
									{val?.imagesUrls?.length > 0 &&
										val.imagesUrls.map((arrayUrl, i) => (
											<Carousel.Item key={i} interval={1000}>
												<img
													src={arrayUrl}
													alt="sensor"
													style={{
														borderRadius: "5px 5px 0px 0px",
														height: "200px",
														width: "276px",
														objectFit: "cover",
														objectPosition: "center",
														cursor: "pointer"
													}}
													onClick={e => {
														setSelectedBigImageActive(val)
														setSelectedImageIndexActive(i)
														setShowBigImageModalActive(true)
														removePopupFunction("popup_stick_active_sensors") // remove popup onClick
													}}
												/>
											</Carousel.Item>
										))}{" "}
								</Carousel>
								<div className="d-flex justify-content-between align-items-center popup_title">
									<span>{val.uuid}</span>
									<div>
										<OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
											<Button
												className="sensor_active_popup_edit_btn"
												color="primary"
												startIcon={
													<BiEditAlt
														style={{
															fontSize: "17px"
														}}
													/>
												}
												onClick={() => {
													setShowActiveModal(true)
													setActiveSensorToUpdate(val) // updatePointOfInterest(poi.id)
													removePopupFunction("popup_stick_active_sensors")
												}}
											/>
										</OverlayTrigger>
									</div>
								</div>
								<TimeInfo anchor={val} checkIn={checkIn} lastSeen={lastSeen} />
								{val?.description && val?.description !== "" && (
									<DescriptionInfo anchor={val} />
								)}
							</div>
						}
						placement="top"
						onMouseEnter={() => {}}
						delay={500}
						className="popup_stick_active_sensors"
					>
						<div
							className="popupLineRoutes"
							style={{
								backgroundColor:
									val.status === "online"
										? "#2649AA"
										: val.status === "lost"
										? "#a43912"
										: val.status === "removed"
										? "#ff0000"
										: "#3B7E38",
								borderRadius: "50%",
								width: "34px",
								height: "34px",
								outline: "2px solid #ffffff"
							}}
						>
							<img
								src="/markers/sensor.svg"
								alt="sensor icon"
								className="iconImageSensorsPosition"
							/>
						</div>
					</PopupStickOnHoverManageInfrastructure>
				)

				ReactDOM.render(popupStickNew, div)

				const marker = new mapboxgl.Marker(div, {
					draggable: false
				})

				marker.setLngLat([val.gpsData.longitude, val.gpsData.latitude]).addTo(map)
				return marker
			})
			.filter(val => val)

	// console.log("🚀 ~ addSensorsToMap ~ newMarkers:", newMarkers)
	setSensorsMarkers(newMarkers)
	return
}

function TimeInfo(props) {
	const { anchor, checkIn, lastSeen } = props

	return (
		<div className="d-flex" style={{ padding: "0px 10px 8px 10px" }}>
			<div
				className="flex-fill mr-2"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Status
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "11px",
						color: "#4A4A68"
					}}
				>
					{anchor?.status || ""}
				</div>
			</div>
			<div
				className="flex-fill"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					First Seen
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "11px",
						color: "#4A4A68"
					}}
				>
					{checkIn}
				</div>
			</div>
			<div
				className="flex-fill ml-2"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Last Seen
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "11px",
						color: "#4A4A68"
					}}
				>
					{lastSeen}
				</div>
			</div>
		</div>
	)
}

function DescriptionInfo(props) {
	const { anchor } = props

	return (
		<div className="d-flex" style={{ padding: "0px 10px 10px 10px" }}>
			<div
				className="flex-fill"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px",
					overflowY: "auto", // Enable vertical scrolling
					maxHeight: "75px" // Set the maximum height to display 2 lines of text
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Description
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "11px",
						color: "#4A4A68"
					}}
				>
					{anchor.description}
				</div>
			</div>
		</div>
	)
}
