import React, { useEffect, useState, useMemo, useRef } from "react"
import { Card, CardBody, CardHeader } from "../../../_partials/Card"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import * as actions from "../../_redux/contactTracing/contactTracingActions"
import { ContactTracingTable } from "./contactTracingTable/ContactTracingTable"
import { Toolbar } from "./contactTracingTable/toolbar/Toolbar"
import AuditToolMapWidget from "./AuditToolMapWidget/AuditToolMapWidget"
import AuditSlider from "./AuditSlider/AuditSlider"
import { Alert } from "@material-ui/lab"
import { firestore as db } from "../../../../firebase"
import { collection, doc, getDocs } from "firebase/firestore"
//for data grid
import { useReactToPrint } from "react-to-print"
import moment from "moment"
import * as XLSX from "xlsx"
import { useUIContext } from "./UIContext"
import { Button } from "../../../_partials/Button"
import { ExportModal } from "./export-modal/ExportModal"
import ContactTracingTableToPrint from "./table-to-print/ContactTracingTableToPrint"
import CurrentContactTracingTableToPrint from "./table-to-print/CurrentContactTracingTableToPrint"
// import AssetsTableToPrint from "./assets-table-to-print/AssetsTableToPrint"
// import CurrentPageAssetsTableToPrint from "./assets-table-to-print/CurrentPageAssetsTableToPrint"

export function ContactTracingCard({ person, dashboardAreas }) {
	//note: var person is coming from ContactTracingPage and going to toolbar
	const dispatch = useDispatch()

	// ━━━━━━━━━━━━━━━━━ useUIContext ━━━━━━━━━━━━━━━━━
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			//for data grid
			ids: UIContext.ids,
			setIds: UIContext.setIds,
			columnsOrder: UIContext.columnsOrder,
			rowsToUse: UIContext.rowsToUse,
			columnVisibilityModel: UIContext.columnVisibilityModel,
			setColumnVisibilityModel: UIContext.setColumnVisibilityModel,
			currentTablePageInfo: UIContext.currentTablePageInfo
		}
	}, [UIContext])

	// ━━━━━━━━━━━━━━━━━ Selectors ━━━━━━━━━━━━━━━━━
	const {
		selectedCustomer,
		selectedSite,
		selectedFloorPlan,
		isLoading,
		floorPlans,
		// areas,
		auditToolPersonLocation,
		entities
	} = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite,
			selectedFloorPlan: state.profile?.currentFloorPlan,
			isLoading: state.contactTracing.listLoading,
			floorPlans: state.basePage.floorPlans,
			// areas: state.basePage?.areas,
			auditToolPersonLocation: state.contactTracing.auditToolPersonLocation,
			entities: state.contactTracing.contactTrace
		}),
		shallowEqual
	)

	// ━━━━━━━━━━━━━━━  States  ━━━━━━━━━━━━━━━ \\
	const [map, setMap] = useState()
	const [updatedCurrentFloorPlan, setUpdatedCurrentFloorPlan] = useState() // updated current floorplan with all data updated from redux (geoJson / geoJson routes / areas) without need of refreshing after creating on design studio
	const [sliderValue, setSliderValue] = useState(0)
	const [data, setData] = useState([])
	const [allFetchedData, setAllFetchedData] = useState([])
	const [pressSubmitButton, setPressSubmitButton] = useState(false)
	const [warningText, setWarningText] = useState(null)
	const [areasUpdated, setAreasUpdated] = useState([])
	const [areasUpdatedDashboard, setAreasUpdatedDashboard] = useState([])
	const [playing, setPlaying] = useState(false)
	const [selectedUser, setSelectedUser] = useState(null)
	//for data grid
	const [optionsOfExport, setOptionsOfExport] = useState(null)
	const [contactTracingData, setContactTracingData] = useState()
	const [showExporModal, setShowExporModal] = useState(false)
	const [columnVisibilityModelBeforePrint, setColumnVisibilityModelBeforePrint] = useState({})
	const [rows, setRows] = useState([])

	// ━━━━━━━━━━━━━━━━━ printing ━━━━━━━━━━━━━━━━━
	//for data grid
	const componentRef = useRef()
	const currentPageComponentRef = useRef()

	const getPageMargins = () => {
		return `@page { margin: 2rem 2rem !important; }`
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: () => getPageMargins()
	})

	const handleCurrentPagePrint = useReactToPrint({
		content: () => currentPageComponentRef.current,
		pageStyle: () => getPageMargins()
	})

	// changed for data grid
	const exportToExcel = (currentTablePageInfo = null) => {
		if (UIProps.rowsToUse) {
			const filteredDisabled =
				UIProps.rowsToUse &&
				UIProps.rowsToUse
					.filter(val => !val.disabled)
					.filter(val => val?.assetDetails?.name.trim() !== "")

			if (filteredDisabled.length === 0) {
				console.warn("No data available for export.")
				return
			}

			// field: "disabled",
			// dataField: "assetDetails.name",
			// dataField: "assetDetails.description",
			// dataField: "assetDetails.type",
			// dataField: "companyId",
			// dataField: "uuid",

			const filteredDataAfterSort =
				filteredDisabled &&
				filteredDisabled.map(val => ({
					date: val.date || "",
					area: val.area || "",
					time: val.time || ""
				}))

			const filteredDataAfterColumnsChange =
				filteredDataAfterSort &&
				filteredDataAfterSort.map(data => {
					// Filter out fields based on columnVisibilityModel
					return Object.entries(data)
						.filter(([key]) => UIProps.columnVisibilityModel[key] !== false)
						.reduce((acc, [key, value]) => {
							acc[key] = value
							return acc
						}, {})
				})

			let finalData = []
			if (currentTablePageInfo) {
				finalData =
					filteredDataAfterColumnsChange &&
					filteredDataAfterColumnsChange.slice(
						currentTablePageInfo?.page * currentTablePageInfo?.pageSize,
						(currentTablePageInfo?.page + 1) * currentTablePageInfo?.pageSize
					)
			} else {
				finalData = filteredDataAfterColumnsChange && filteredDataAfterColumnsChange
			}

			const workbook = XLSX.utils.book_new()
			const worksheet = XLSX.utils.json_to_sheet(finalData) // send Data to Excel

			// Set column widths manually (in pixels)
			const columnWidths = [
				{ wch: 50 }, // Width of the first column (Name)
				{ wch: 50 }, // Width of the first column (Company)
				{ wch: 50 } // Width of the first column (Email)
			]

			const dateToday = moment().format("ddd DD MMM")

			worksheet["!cols"] = columnWidths

			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

			const excelBuffer = XLSX.write(workbook, { type: "buffer" })
			const blob = new Blob([excelBuffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			})
			const url = URL.createObjectURL(blob)
			const a = document.createElement("a")
			a.href = url
			a.download = selectedCustomer
				? `${selectedCustomer?.customerInfo?.name}_${selectedSite?.siteInfo?.name}_Contact_Tracing_${dateToday}.xlsx`
				: `Workers_${dateToday}.xlsx`
			a.click()
			URL.revokeObjectURL(url)
		}
	}

	useEffect(() => {
		if (!entities) return

		entities && setContactTracingData(entities)
	}, [entities])

	//changed for data grid
	useEffect(() => {
		if (!entities) return

		if (optionsOfExport === "print_all_pages") {
			handlePrint()
		}
		if (optionsOfExport === "print_current_page") {
			handleCurrentPagePrint()
		}
		if (optionsOfExport === "excel_all_pages") {
			exportToExcel()
		}
		if (optionsOfExport === "excel_current_page") {
			exportToExcel(UIProps.currentTablePageInfo)
		}
	}, [optionsOfExport])

	useEffect(() => {
		setOptionsOfExport(null)
	}, [optionsOfExport])

	// ━━━━━━━━━━━━━━━  Functions  ━━━━━━━━━━━━━━━ \\
	function handleSliderChange(e, newValue) {
		setSliderValue(newValue)

		// Filter only one element from the data array based on the slider value (index)
		const selectedData = allFetchedData[newValue]
		// console.log("selectedData ->", selectedData)

		selectedData && setData([selectedData])
	}

	// Backward / Reset / Stop slider
	const handleStopClick = e => {
		setSliderValue(0) // Reset the value to the minimum value.
		setPlaying(false)
		handleSliderChange(e, 0)
	}

	// ━━━━━━━━━━━━━━━  UseEffects  ━━━━━━━━━━━━━━━ \\

	// if no selectedUser stop playing and reset slider.
	useEffect(() => {
		if (!selectedUser) {
			handleStopClick()
			setData([])
		}
	}, [selectedUser])

	// store areas from dashboard in a state (so we can clean state after searching for new person on this page)
	useEffect(() => {
		if (!dashboardAreas) {
			return
		} else {
			setAreasUpdatedDashboard(dashboardAreas)
		}
	}, [dashboardAreas])

	// Fetch all updated areas and set to state
	// useEffect(() => {
	// 	let isMounted = true

	// 	const fetchAllAreas = async () => {
	// 		try {
	// 			const _areas = await firestoreOld
	// 				.collection("Sites")
	// 				.doc(selectedSite.id)
	// 				.collection(`Areas`)
	// 				.get()

	// 			const allAreasFetched = _areas.docs.map(doc => ({
	// 				id: doc.id,
	// 				...doc.data()
	// 			}))

	// 			if (isMounted) {
	// 				setAreasUpdated(allAreasFetched)
	// 			}
	// 		} catch (error) {
	// 			// Handle errors
	// 		}
	// 	}

	// 	if (selectedSite) {
	// 		fetchAllAreas()
	// 	}

	// 	return () => {
	// 		isMounted = false
	// 	}
	// }, [selectedSite])
	useEffect(() => {
		let isMounted = true

		const fetchAllAreas = async () => {
			try {
				if (!selectedSite?.id) return // Early return if selectedSite is not available

				// Reference to the "Areas" collection within the selected site
				const areasRef = collection(doc(db, "Sites", selectedSite.id), "Areas")

				// Fetch all documents from the "Areas" collection
				const areasSnapshot = await getDocs(areasRef)

				// Extract area data
				const allAreasFetched = areasSnapshot.docs.map(doc => ({
					id: doc.id,
					...doc.data()
				}))

				if (isMounted) {
					setAreasUpdated(allAreasFetched)
				}
			} catch (error) {
				console.error("Error fetching areas:", error)
				// Optionally handle errors here
			}
		}

		if (selectedSite) {
			fetchAllAreas()
		}

		return () => {
			isMounted = false
		}
	}, [selectedSite])

	// fetch users
	useEffect(() => {
		if (!selectedCustomer && !selectedSite) return

		dispatch(actions.fetchUsersSelectedSite({ customerId: selectedCustomer?.id, selectedSite }))
	}, [selectedCustomer, selectedSite])

	// Set fetched data to state
	useEffect(() => {
		// if (!auditDataSmall) return

		// console.log(auditDataSmall, "auditDataSmall")

		// const fetchedDataClone = [...auditDataSmall]

		if (!auditToolPersonLocation) return

		if (auditToolPersonLocation.length === 0) {
			// const paragraphToaster = (
			// 	<p style={{ textAlign: "center" }}>
			// 		Not receiving any data from this person. <br />
			// 	</p>
			// )
			setWarningText("No data for the person at the selected timeframe")
			setAllFetchedData([])
			setData([])
			// dispatch({
			// 	type: "SNACKBAR_WARNING",
			// 	payload: paragraphToaster
			// })

			return
		}

		setWarningText()
		const fetchedDataClone = [...auditToolPersonLocation]

		if (pressSubmitButton) {
			setData([fetchedDataClone[0]])
			setAllFetchedData(fetchedDataClone)
		} else {
			setData([])
		}
	}, [
		// auditDataSmall,
		auditToolPersonLocation,
		pressSubmitButton
	])

	return (
		<Card isLoading={isLoading} data-testid="card-ContactTracingCard">
			<CardHeader title="Audit Tool" />
			<CardBody padding="15px">
				<div data-testid="div-toolbar-ContactTracingCard">
					<Toolbar
						person={person}
						setPressSubmitButton={setPressSubmitButton}
						areas={areasUpdated}
						setWarningText={setWarningText}
						isLoading={isLoading}
						areasUpdatedDashboard={areasUpdatedDashboard}
						setAreasUpdatedDashboard={setAreasUpdatedDashboard}
						handleStopClick={handleStopClick}
						selectedUser={selectedUser}
						setSelectedUser={setSelectedUser}
						data={data}
					/>
				</div>
				{warningText && selectedUser && (
					<div className="px-4 pt-4" data-testid="div-alert-ContactTracingCard">
						<Alert className="d-flex align-items-center" severity="warning">
							{warningText}
						</Alert>
					</div>
				)}
				<div data-testid="div-Auditslider-ContactTracingCard">
					<AuditSlider
						data={data}
						allFetchedData={allFetchedData}
						handleSliderChange={handleSliderChange}
						sliderValue={sliderValue}
						setSliderValue={setSliderValue}
						playing={playing}
						setPlaying={setPlaying}
						handleStopClick={handleStopClick}
						isLoading={isLoading}
						selectedUser={selectedUser}
					/>
				</div>
				<div data-testid="div-AuditToolMapWidget-ContactTracingCard">
					<AuditToolMapWidget
						map={map}
						setMap={setMap}
						floorPlans={floorPlans}
						selectedFloorPlan={selectedFloorPlan}
						updatedCurrentFloorPlan={updatedCurrentFloorPlan}
						setUpdatedCurrentFloorPlan={setUpdatedCurrentFloorPlan}
						selectedCustomer={selectedCustomer}
						selectedSite={selectedSite}
						areas={areasUpdated}
						// auditToolPersonLocation={auditToolPersonLocation}
						data={data}
					/>
				</div>
				<div className="mt-5" data-testid="div-ContactTracingTable-ContactTracingCard">
					<ContactTracingTable
						data-testid="ContactTracingTable"
						//for data grid
						contactTracingData={contactTracingData}
						rows={rows}
						setRows={setRows}
						isLoading={isLoading}
						setShowExporModal={setShowExporModal}
					/>
					{/* added for data grid */}
					{UIProps?.rowsToUse && UIProps?.rowsToUse.length > 0 && (
						<ExportModal
							show={showExporModal}
							onHide={() => setShowExporModal(false)}
							contentClassName="bg-transparent"
							columnsForExport={["date", "area", "time"]}
							setOptionsOfExport={setOptionsOfExport}
							columnVisibilityModelBeforePrint={columnVisibilityModelBeforePrint}
							setColumnVisibilityModelBeforePrint={setColumnVisibilityModelBeforePrint}
						/>
					)}
				</div>
				{/* changed for data grid */}
				<div style={{ overflow: "hidden", height: "0" }}>
					<ContactTracingTableToPrint
						rowsToUse={rows}
						columnVisibilityModel={UIProps.columnVisibilityModel}
						ref={componentRef}
					/>
					<CurrentContactTracingTableToPrint
						rowsToUse={UIProps.rowsToUse}
						currentTablePageInfo={UIProps.currentTablePageInfo}
						columnVisibilityModel={UIProps.columnVisibilityModel}
						ref={currentPageComponentRef}
					/>
				</div>
				{/* // note: Chart done  only with mockData */}
				{/* <div className='m-5'>
         			 <ContactCharts />
       				 </div> */}
			</CardBody>
		</Card>
	)
}
