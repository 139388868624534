import moment from "moment"

//filter an array data based on a search string and specified fields.
//Then put this function within a useEffect hook to update the filtered rows when either the arrayToSearch or searchString changes.
export const filterBySearchString = (arrayToSearch, searchString, fieldsToSearch) => {
	if (
		!searchString ||
		searchString.trim() === "" ||
		!Array.isArray(fieldsToSearch) ||
		fieldsToSearch.length === 0
	) {
		return arrayToSearch // No filtering needed
	}

	const searchText = searchString.trim().toLowerCase()

	return arrayToSearch.filter(row => {
		return fieldsToSearch.some(field => {
			const fieldParts = field.split(".")
			let fieldValue = row

			for (const part of fieldParts) {
				fieldValue = fieldValue?.[part]
				if (fieldValue === undefined || fieldValue === null) {
					break // Stop further access if any part is undefined or null
				}
			}

			if (typeof fieldValue === "string") {
				const fieldWords = fieldValue.split(" ")
				return fieldWords.some(word => word.toLowerCase().startsWith(searchText))
			} else if (typeof fieldValue === "number") {
				// Convert number to string and check if it starts with searchText
				return fieldValue.toString().startsWith(searchText)
			}

			return false // If fieldValue is not a string or number, no match found
		})
	})
}

// Custom sorting function to compare time strings data example on people data
export const timeStringComparator = (v1, v2) => {
	const parseTime = timeString => {
		const [time, period] = timeString.split(" ")
		let [hours, minutes] = time.split(":").map(Number)

		if (period === "PM" && hours !== 12) hours += 12
		if (period === "AM" && hours === 12) hours = 0

		return hours * 60 + minutes
	}

	return parseTime(v1) - parseTime(v2)
}

// Custom sorting function to compare total hours strings data example on people data
export const totalHoursComparator = (v1, v2) => {
	const parseTotalHours = totalHoursString => {
		let hours = 0
		let minutes = 0

		if (totalHoursString.includes("h")) {
			const hoursMatch = totalHoursString.match(/(\d+)h/)
			if (hoursMatch) hours = parseInt(hoursMatch[1], 10)
		}

		if (totalHoursString.includes("m")) {
			const minutesMatch = totalHoursString.match(/(\d+)\s*min/) // Updated regex
			if (minutesMatch) minutes = parseInt(minutesMatch[1], 10)
		}

		return hours * 60 + minutes
	}

	const totalMinutes1 = parseTotalHours(v1)
	const totalMinutes2 = parseTotalHours(v2)

	// console.log(`Comparing ${v1} (${totalMinutes1} mins) with ${v2} (${totalMinutes2} mins)`)

	return totalMinutes1 - totalMinutes2
}

//to sort date data value like Thu Jun 6
export const dayMonthYearComparator = (a, b) => {
	// Parse the date strings using moment with the specified format and locale
	const dateA = moment(a, "ddd MMM D", "en")
	const dateB = moment(b, "ddd MMM D", "en")

	// Compare the dates
	if (dateA.isBefore(dateB)) {
		return -1
	}
	if (dateA.isAfter(dateB)) {
		return 1
	}
	return 0
}
