import React, { useMemo, useEffect, useCallback } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../_redux/reporting/people/peopleActions"
import { useUIContext } from "../UIContext"
// import { AttendanceByRole, AttendenceByRole } from "./Charts/AttendanceByRole"
// import { TimesheetPerRole } from "./Charts/TimesheetPerRole"
// import { TimesheetDaily } from "./Charts/TimesheetDaily"
import { AttendanceTracking } from "./Charts/AttendanceTracking"
import { TotalContractedWorkers } from "../occupancy/FloorNew/TotalContractedWorkers"
import { TotalPeople } from "./Charts/TotalPeople"
import { AverageTimeSpent } from "./Charts/AverageTimeSpent"
import { PeopleDistribution } from "./Charts/PeopleDistribution"
import { LocationSummary } from "./Charts/LocationSummary"
import { debounce } from "lodash"

export function PeopleSummaryPage() {
	const dispatch = useDispatch()

	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			queryParams: UIContext?.queryParams,
			setQueryParams: UIContext?.setQueryParams
		}
	}, [UIContext])

	const {
		group,
		customer,
		selectedSite,
		siteId,
		floorPlans,
		people,
		roles,
		vendors,
		permissions
	} = useSelector(
		state => ({
			group: state.persistentReporting?.group,
			customer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite,
			siteId: state.profile?.currentSite?.id,
			floorPlans: state.basePage?.floorPlans,
			people: state.basePage?.people || [],
			roles: state.profile?.currentCustomer?.roles || [],
			vendors: state.profile?.currentCustomer?.vendors,
			permissions: state.auth?.permissions?.permissions
		}),
		shallowEqual
	)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fetchSummary = useCallback(
		debounce(() => {
			if (!customer || !selectedSite || !floorPlans) return

			dispatch(
				actions.fetchPeopleSummary({
					customerId: customer.id,
					siteId,
					selectedSite,
					floorPlans,
					people,
					roles,
					vendors,
					nodeType: "people",
					...UIProps.queryParams
				})
			)
		}, 300), // Adjust the debounce delay as needed
		[customer, UIProps.queryParams, selectedSite, siteId, floorPlans, people, roles, vendors]
	)

	useEffect(() => {
		fetchSummary()

		// Cleanup the debounce on component unmount
		return () => {
			fetchSummary.cancel()
		}
	}, [fetchSummary])

	// useEffect(() => {
	// 	if (!customer || !selectedSite || !floorPlans) return

	// 	console.log("👻 👻 👻 👻 👻 👻 peopleSummaryPage useEffect")

	// 	dispatch(
	// 		actions.fetchPeopleSummary({
	// 			customerId: customer.id,
	// 			siteId,
	// 			selectedSite,
	// 			floorPlans,
	// 			people: people,
	// 			roles: roles,
	// 			vendors: vendors,
	// 			...UIProps.queryParams
	// 		})
	// 	)
	// }, [customer, UIProps.queryParams, selectedSite, siteId, floorPlans, people, roles, vendors])

	// useEffect(() => {
	// 	console.log("👻 👻 👻 👻 👻 👻 UIProps.queryParams ", UIProps.queryParams)
	// }, [UIProps.queryParams])

	return (
		<div className="row" id="people_summary_main_div" data-testid="people_summary_main_page">
			<>
				{/* <div className="col-md-12 col-xxl-4">
					<TotalPeople />
				</div> */}
				{permissions?.Reporting?.People?.Summary?.Top5AttendanceTracking && (
					<div
						className="col-12"
						// className="col-md-12 col-xxl-8"
					>
						<AttendanceTracking />
					</div>
				)}
				{permissions?.Reporting?.People?.Summary?.LocationSummary && (
					<div className="col-12">
						<LocationSummary />
					</div>
				)}
				{permissions?.Reporting?.People?.Summary?.PeopleDistributionPerArea && (
					<div className="col-12">
						<AverageTimeSpent />
					</div>
				)}
				{permissions?.Reporting?.People?.Summary?.PeopleDistributionPerFloor && (
					<div className="col-12">
						<PeopleDistribution />
					</div>
				)}
			</>
		</div>
	)
}
