import * as requestFromServer from "./InventoryCrud"
import { inventorySlice, callTypes } from "./InventorySlice"
import { actionTypes } from "../../../../globalUI/snackBar.duck"

const { actions } = inventorySlice

//#1 get tehcnology list
export const getTechnologyList = () => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return requestFromServer
		.getTechnologyList()
		.then(response => {
			if (!response) throw Error("Can't make a request for server")
			// dispatch(actions.inventoryNodesFetched(response))
			// console.log("🚀 ~ getTechnologyList ~ response:", response)
			return response
		})
		.catch(error => {
			console.log("Fetch Inventory: ", error)
			error.clientMessage = "Can't fetch Inventory"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

//#2 get all data for table //* With API
export const getNodes = queryParams => dispatch => {
	if (!queryParams || !queryParams.technology) return

	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.getNodes({ ...queryParams })
		.then(response => {
			// console.log("🪲 ~ getNodes ~ response:", response)
			if (!response) throw Error("Can't make a request for server")
			return response.json()
		})
		.then(result => {
			// console.log("🪲 ~ getNodes ~ result:", result)
			if (!result.message) dispatch(actions.inventoryNodesFetched(result))
			return
		})
		.catch(error => {
			// console.log("🪲 ~ getNodes ~ error:", error)
			console.log("Get Nodes: ", error)
			error.clientMessage = "Can't get nodes"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
			// queryParams.onHide()
		})
}
export const getModels = queryParams => dispatch => {
	if (!queryParams || !queryParams.technology) return

	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.getModels({ ...queryParams })
		.then(response => {
			if (!response) throw Error("Can't make a request for server")
			// dispatch(actions.inventoryModelsFetched(response))
			// return response
			return response.json()
		})
		.then(result => {
			if (!result.message) dispatch(actions.inventoryModelsFetched(result))
			return
		})
		.catch(error => {
			console.log("Get models: ", error)
			error.clientMessage = "Can't get models"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
			// queryParams.onHide()
		})
}
export const getFirmwares = queryParams => dispatch => {
	if (!queryParams || !queryParams.technology) return

	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.getFirmwares({ ...queryParams })
		.then(response => {
			if (!response) throw Error("Can't make a request for server")

			return response.json()
		})
		.then(result => {
			// console.log("🚀 ~ getFirmwares ~ result:", result)
			if (!result.message) dispatch(actions.inventoryFirmwaresFetched(result))
			return
		})
		.catch(error => {
			console.log("Get Firmwares: ", error)
			error.clientMessage = "Can't get Firmwares"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
			// queryParams.onHide()
		})
}
export const getCustomersAndSites = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.getCustomersAndSites()
		.then(response => {
			if (!response) throw Error("Can't make a request for server")

			return response.json()
		})
		.then(result => {
			// console.log("🚀 ~ getCustomersAndSites ~ result:", result)
			if (!result.message) dispatch(actions.inventoryCustomersFetched(result))
			return
		})
		.catch(error => {
			console.log("Get Firmwares: ", error)
			error.clientMessage = "Can't get Firmwares"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
			// queryParams.onHide()
		})
}

/*** NODES ***/
//* With API
export const createNodes = queryParams => dispatch => {
	if (
		!queryParams ||
		!queryParams.technology ||
		!queryParams.data ||
		!queryParams.data.callersUserId
	) {
		return Promise.reject(new Error("Invalid params"))
	}

	// console.log("🚀 ~ createNodes ~ queryParams:", queryParams)

	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.createNodes({ ...queryParams })
		.then(response => {
			if (!response) throw Error("Can't make a request for server")

			if (response.status === 200) {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Success creating new node." }
				})
			} else if (response.status === 400) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error creating new node." }
				})
				return Promise.reject(new Error("Error creating new nodes."))
			} else if (response.status === 500) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Internal server error." }
				})
				return Promise.reject(new Error("Error creating new nodes."))
			}

			queryParams.onHide()

			return response.json()
		})
		.then(result => {
			// console.log("🚀 ~ editNodes and update table ~ result:", result)
			const { data } = result
			if (result.data && result.data.length > 0) {
				dispatch(
					actions.createdNodes({
						data
					})
				)
			}
			return
		})
		.catch(error => {
			console.log("Create node: ", error)
			error.clientMessage = "Can't create node"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
			queryParams.onHide()
		})
}
export const editNodes = queryParams => dispatch => {
	const { ids, firmwareId, modelId, notes, technology, callersUserId } = queryParams

	dispatch(actions.startCall({ callType: callTypes.action }))

	const data = {
		nodes: ids,
		firmwareId,
		modelId,
		notes,
		callersUserId
	}
	return requestFromServer
		.updateNodes({ data, technology })
		.then(response => {
			// console.log("🚀 ~ editNodes ~ response:", response)

			if (!response) throw Error("Can't make a request for server")

			if (response.status === 200) {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Success updating new nodes." }
				})
				queryParams.onHide()
			} else if (response.status === 400) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error updating new nodes." }
				})
				return Promise.reject(new Error("Error updating new nodes."))
			} else if (response.status === 500) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Internal server error." }
				})
				return Promise.reject(new Error("Error updating new nodes."))
			}

			return response.json()
		})
		.then(result => {
			if (result.data && result.data.length > 0) {
				dispatch(
					actions.updatedNodes({
						data
					})
				)
			}
			return
		})
		.catch(error => {
			console.log("Edit nodes: ", error)
			error.clientMessage = "Can't edit nodes"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
			queryParams.onHide()
		})
}
export const assignInventoryNodes = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.assignInventoryNodes({ ...queryParams })
		.then(response => {
			if (!response) throw Error("Can't make a request for server")

			if (response.status === 200) {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Success assigning nodes." }
				})
				// queryParams.setReFetchData(true)
				queryParams.onHide()
			} else if (response.status === 400) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error assigning nodes." }
				})
				return Promise.reject(new Error("Error assigning new nodes."))
			} else if (response.status === 500) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Internal server error." }
				})
				return Promise.reject(new Error("Error assigning new nodes."))
			}

			return response.json()
		})
		.then(result => {
			const { data } = queryParams
			if (data && result.message != "Error assigning nodes.") {
				dispatch(
					actions.assignedNodes({
						data
					})
				)
			}
			return
		})
		.catch(error => {
			console.log("assign Inventory Nodes: ", error)
			error.clientMessage = "Can't assign Inventory Nodes"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
			queryParams.onHide()
		})
}
export const unassignInventoryNodes = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.unassignInventoryNodes({ ...queryParams })
		.then(response => {
			// console.log("🚀 ~ unassignInventoryNodes ~ response:", response)

			if (!response) throw Error("Can't make a request for server")

			if (response.status === 200) {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Success unassigning nodes." }
				})
				queryParams.onHide()
			} else if (response.status === 400) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error unassigning nodes." }
				})
				return Promise.reject(new Error("Error unassigning new nodes."))
			} else if (response.status === 500) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Internal server error." }
				})
			}

			return response.json()
		})
		.then(result => {
			// console.log("🚀 ~ unassignInventoryNodes ~ result:", result)
			const { data } = queryParams
			if (data) {
				dispatch(
					actions.unassignedNodes({
						data
					})
				)
			}
			return
		})
		.catch(error => {
			console.log("unassign Inventory Nodes: ", error)
			error.clientMessage = "Can't unassign Inventory Nodes"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
			queryParams.onHide()
		})
}
export const deleteInventoryNodes = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.deleteInventoryNodes({ ...queryParams })
		.then(response => {
			console.log("🚀 ~ deleteInventoryNodes ~ response:", response)

			if (!response) throw Error("Can't make a request for server")

			if (response.status === 200) {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Success deleting nodes." }
				})
				// queryParams.setReFetchData(true)
				queryParams.onHide()
			} else if (response.status === 400) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error deleting nodes." }
				})
				return Promise.reject(new Error("Error deleting new nodes."))
			} else if (response.status === 500) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Internal server error." }
				})
				return Promise.reject(new Error("Error deleting new nodes."))
			}

			return response.json()
		})
		.then(result => {
			if (result.message === "Cannot delete assigned nodes") {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Cannot delete assigned nodes" }
				})
			}

			if (result.message === "Nodes deleted with success") {
				dispatch(
					actions.deletedNode({
						...queryParams
					})
				)
			}
			return
		})
		.catch(error => {
			// console.log("delete Inventory Nodes: ", error)
			error.clientMessage = "Can't delete Inventory Nodes"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
			queryParams.onHide()
		})
}

/*** MODEL ***/
//* With API
export const createModel = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	// console.log("here2")
	return requestFromServer
		.createModel({ ...queryParams })
		.then(response => {
			// console.log("🪲 ~ createModel ~ response:", response)

			if (!response) throw Error("Can't make a request for server")

			if (response.status === 200) {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Success creating new model." }
				})

				// queryParams.setReFetchData(true)
				queryParams.onHide()
			} else if (response.status === 400) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error creating new model." }
				})
				return Promise.reject(new Error("Error creating new models."))
			} else if (response.status === 500) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Internal server error." }
				})
				return Promise.reject(new Error("Error creating new models."))
			}

			return response.json()
		})
		.then(result => {
			const { data } = result
			if (result.data) {
				dispatch(
					actions.createdModel({
						data
					})
				)
			}
			return
		})
		.catch(error => {
			console.log("Create model: ", error)
			error.clientMessage = "Can't create model"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
			queryParams.onHide()
		})
}
export const updateModel = queryParams => dispatch => {
	// console.log("🪲 ~ updateModel ~ queryParams:", queryParams)
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updateModel({ ...queryParams })
		.then(response => {
			if (!response) throw Error("Can't make a request for server")

			if (response.status === 200) {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Success editing new model." }
				})
				// queryParams.setReFetchData(true)
				queryParams.onHide()
			} else if (response.status === 400) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error editing new model." }
				})
				return Promise.reject(new Error("Error editing models."))
			} else if (response.status === 500) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Internal server error." }
				})
				return Promise.reject(new Error("Error editing models."))
			}

			return response.json()
		})
		.then(result => {
			// console.log("🚀 ~ updateModel ~ result:", result)
			if (result.data) {
				const { data } = result
				dispatch(
					actions.updatedModel({
						data
					})
				)
			}
			return
		})
		.catch(error => {
			console.log("Edit model: ", error)
			error.clientMessage = "Can't edit model"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
			queryParams.onHide()
		})
}
export const deleteModels = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.deleteModels({ ...queryParams })
		.then(response => {
			if (!response) throw Error("Can't make a request for server")

			if (response.status === 200) {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Success delete new model." }
				})
				// queryParams.setReFetchData(true)
				queryParams.onHide()
			} else if (response.status === 400) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error delete new model." }
				})
				return Promise.reject(new Error("Error deleting models."))
			} else if (response.status === 500) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Internal server error." }
				})
				return Promise.reject(new Error("Error deleting models."))
			}

			return response.json()
		})
		.then(result => {
			// console.log("🚀 ~ deleteModel ~ result:", result)
			if (
				result.message ===
				"Model is being used by a firmware, change firmware model to be able to delete model"
			) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: {
						message:
							"Model is being used by a firmware, change firmware model to be able to delete model"
					}
				})
			}

			if (
				result.message ===
				"Model is being used by nodes, change nodes model to be able to delete model"
			) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: {
						message:
							"Model is being used by nodes, change nodes model to be able to delete model"
					}
				})
			}

			if (result.message === "Model deleted with success") {
				dispatch(
					actions.deletedModel({
						...queryParams
					})
				)
			}
			queryParams.onHide()
			return
		})
		.catch(error => {
			console.log("delete model: ", error)
			error.clientMessage = "Can't delete model"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
			queryParams.onHide()
		})
}

/*** FIRMWARE ***/
//* With API
export const createFirmware = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.createFirmware({ ...queryParams })
		.then(response => {
			if (!response) throw Error("Can't make a request for server")

			if (response.status === 200) {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Success creating new firmware." }
				})
				// queryParams.setReFetchData(true)
				queryParams.onHide()
			} else if (response.status === 400) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error creating new firmware." }
				})
				return Promise.reject(new Error("Error creating new firmwares."))
			} else if (response.status === 500) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Internal server error." }
				})
				return Promise.reject(new Error("Error creating new firmwares."))
			}

			return response.json()
		})
		.then(result => {
			// console.log("🚀 ~ createFirmware ~ result:", result)
			const { data } = result
			if (result.data) {
				dispatch(
					actions.createdFirmware({
						data
					})
				)
			}
			return
		})
		.catch(error => {
			console.log("Create Firmware: ", error)
			error.clientMessage = "Can't create firmware"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
			queryParams.onHide()
		})
}
export const deleteFirmwares = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.deleteFirmwares({ ...queryParams })
		.then(response => {
			if (!response) throw Error("Can't make a request for server")

			if (response.status === 200) {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: { message: "Success delete new Firmware." }
				})
				// queryParams.setReFetchData(true)
				queryParams.onHide()
			} else if (response.status === 400) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error delete new Firmware." }
				})
				return Promise.reject(new Error("Error deleting models."))
			} else if (response.status === 500) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Internal server error." }
				})
				return Promise.reject(new Error("Error deleting models."))
			}

			return response.json()
		})
		.then(result => {
			// console.log("🚀 ~ deleteFirmware ~ result:", result)
			if (
				result.message ===
				"This firmware firmware cannot be deleted because it still is being used by some nodes"
			) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: {
						message:
							"This firmware firmware cannot be deleted because it still is being used by some nodes"
					}
				})
			}

			if (result.message === "Firmware deleted with success") {
				dispatch(
					actions.deletedFirmware({
						...queryParams
					})
				)
			}
			return
		})
		.catch(error => {
			console.log("delete model: ", error)
			error.clientMessage = "Can't delete model"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
			queryParams.onHide()
		})
}

// TODO api call shoudl handle return and response like this
// export const createNodes = queryParams => dispatch => {
// 	if (
// 		!queryParams ||
// 		!queryParams.technology ||
// 		!queryParams.data ||
// 		!queryParams.data.callersUserId
// 	) {
// 		return Promise.reject(new Error("Invalid params"))
// 	}

// 	dispatch(actions.startCall({ callType: callTypes.action }))

// 	return requestFromServer
// 		.createNodes({ ...queryParams })
// 		.then(response => response.json())
// 		.then(result => {
// 			console.log("🚀 ~ .then ~ result:", result)
// 			return
// 		if (!response) throw Error("Can't make a request for server");

//   if (response.status === 200) {
//     dispatch({
//       type: "SNACKBAR_SUCCESS",
//       payload: "Success creating new node.",
//     });
//     // queryParams.setReFetchData(true)
//     queryParams.hide();
//   } else if (response.status === 400) {
//     dispatch({
//       type: "SNACKBAR_ERROR",
//       payload: "Error creating new node.",
//     });
//     return Promise.reject(new Error("Error creating new nodes."));
//   } else if (response.status === 500) {
//     dispatch({
//       type: "SNACKBAR_ERROR",
//       payload: "Internal server error.",
//     });
//     return Promise.reject(new Error("Error creating new nodes."));
//   }
// console.log("🚀 ~ editNodes and update table ~ result:", result)
//   const { data } = result;
//   if (result.data && result.data.length > 0) {
//     dispatch(
//       actions.createdNodes({
//         data,
//       })
//     );
//   }
//   return;
// 		})
// 		.catch(error => {
// 			console.log("Create node: ", error)
// 			error.clientMessage = "Can't create node"
// 			dispatch(actions.catchError({ error, callType: callTypes.list }))
// 			queryParams.hide()
// 		})
// }
