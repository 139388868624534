import React, { useEffect, useState, useMemo } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { makeStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import moment from "moment"
import { Button } from "../../../../_partials/Button"
import { useUIContext } from "../../UIContext"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { SearchBarCustom1 } from "../../../../_partials/inputs/SearchBarCustom1"
import SearchIcon from "@material-ui/icons/Search"
import { filterBySearchString } from "../../../Admin/manage-customer/UIHelpers"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		border: "none",
		height: "500px",
		marginTop: "1rem",
		"& .MuiDataGrid-root": {
			height: "560px", // Set a fixed height for the grid
			overflow: "auto" // Enable scrolling
		},

		"& .MuiPagination-root makeStyles-root-130": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},

		"& .MuiDataGrid-cell:focus": {
			outline: "none"
		},
		"& .MuiDataGrid-selectedRowCount": {
			display: "none"
		},
		"& .MuiDataGrid-colCell": {
			minWidth: "0" // Ensure that minWidth is set to 0 to allow percentage width
		},
		"& .MuiDataGrid-columnSeparator": {
			opacity: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
			fontWeight: 600,
			fontSize: "1rem",
			color: "#4a4a68"
		},
		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--textLeft": {
			fontSize: "14px",
			fontWeight: 500
		},
		"& .MuiDataGrid-columnHeaderTitleContainer": {
			paddingLeft: 0,
			paddingRight: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
			paddingRight: "0px",
			paddingLeft: "10px"
		},
		"& .hide-scrollbar .MuiDataGrid-scrollbar": {
			display: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
			paddingLeft: "2px"
		},

		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
			maxHeight: "65px !important",
			minHeight: "65px !important"
		},
		"& .MuiPagination-root": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		"& .MuiPaginationItem-root": {
			borderRadius: "3px !important",
			color: "#3077d3 !important",
			padding: "0 !important",
			margin: "-1px !important",
			border: "1px solid #f4f8fe !important"
		},

		"& .MuiPaginationItem-textPrimary.Mui-selected": {
			color: "#fff !important",
			border: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon, & .MuiDataGrid-root .MuiDataGrid-filterIcon, & .MuiDataGrid-root .MuiDataGrid-sortIcon":
			{
				color: "#3077d3"
			},
		"& .MuiDataGrid-root .MuiDataGrid-viewport": {
			minHeight: "700px",
			maxHeight: "none"
		},
		"& .MuiDataGrid-renderingZone": {
			maxHeight: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-footerContainer": {
			minHeight: "90px !important"
		}
	},

	"& .MuiDataGrid-columnSeparator": {
		opacity: "0 !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
		fontWeight: "600 !important",
		fontSize: "1rem",
		color: "#4a4a68"
	},

	"& .MuiDataGrid-cell row-cell .MuiDataGrid-cell--textLeft": {
		fontSize: "14px !important",
		fontWeight: "500 !important"
	},

	"& .MuiDataGrid-columnHeaderTitleContainer": {
		paddingLeft: "0 !important",
		paddingRight: "0 !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
		paddingRight: "0px !important",
		paddingLeft: "10px !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
		paddingLeft: "2px"
	},

	"& .MuiDataGrid-root .MuiDataGrid-row:hover": {
		backgroundColor: "inherit !important"
	},

	"& .MuiDataGrid-colCell": {
		minWidth: 0
	},

	"& .actions-header": {
		marginLeft: "10px"
	},

	"& .MuiDataGrid-row": {
		maxHeight: "65px !important",
		minHeight: "65px !important"
	},

	"& .MuiDataGrid-cell row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
		maxHeight: "65px !important",
		minHeight: "65px !important"
	},

	"& .MuiPagination-root": {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},

	"& .MuiPaginationItem-root": {
		borderRadius: "3px !important",
		color: "#3077d3 !important",
		padding: "0 !important",
		margin: "0 !important",
		border: "1px solid #f4f8fe !important"
	},

	"& .MuiPaginationItem-textPrimary.Mui-selected": {
		color: "#fff !important",
		border: "none"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon":
		{
			color: "#3077d3"
		},

	"& .MuiDataGrid-root .MuiDataGrid-sortIcon, .MuiDataGrid-root .MuiDataGrid-filterIcon": {
		color: "#3077d3"
	},

	"& .MuiDataGrid-root .MuiDataGrid-viewport": {
		minHeight: "700px",
		maxHeight: "none"
	},

	"& .MuiDataGrid-renderingZone": {
		maxHeight: "none"
	},

	"& .MuiDataGrid-root .MuiDataGrid-footerContainer": {
		minHeight: "90px !important"
	}
}))

const ConfirmTab = ({ onHide, handleChange }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			sitesValue: UIContext.sitesValue,
			confirmTabRows: UIContext.confirmTabRows,
			groupName: UIContext.groupName,
			membersToSendMessage: UIContext.membersToSendMessage,
			setMembersToSendMessage: UIContext.setMembersToSendMessage
		}
	}, [UIContext])

	const { allPeople, areas, allVendors, customerId } = useSelector(
		state => ({
			customerId: state.profile?.currentCustomer?.id,
			allPeople: state?.manageGroups?.allPeople,

			areas: state.basePage?.areas
				? state.basePage?.areas.filter(val => val.geoFencing === true)
				: [],
			allvendors: state.profile?.currentCustomer?.vendors || []
		}),
		shallowEqual
	)

	// const [allPeople, setAllPeople] = useState([])
	const [peopleToUse, setPeopleToUse] = useState([])
	const [rows, setRows] = useState([])
	const [rowsToUse, setRowsToUse] = useState([])
	const [selectionModel, setSelectionModel] = useState([])
	const [searchText, setSearchText] = useState("")
	const [searchedRows, setSearchedRows] = useState([])

	const columns = [
		{
			field: "name",
			headerName: "Name",
			hideable: false,
			width: 160,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "companyName",
			headerName: "Company",
			type: "string",
			minWidth: 110,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "role",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			headerName: "Role",
			type: "string",
			minWidth: 70
		},
		{
			field: "uuid",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			headerName: "Tag (uuid)",
			type: "string",
			minWidth: 110
		},
		{
			field: "geofencing",
			headerName: "Geofencing",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 120,
			valueGetter: params => {
				const { geofencing } = params.row

				if (!Array.isArray(geofencing) || geofencing.length === 0) {
					return "No Geofencing"
				}

				const selectedGeofencingNames = []
				for (const geofencingId of geofencing) {
					const foundGeofencing = areas.find(area => area.id === geofencingId)
					if (foundGeofencing) {
						selectedGeofencingNames.push(foundGeofencing.name)
					}
				}

				if (selectedGeofencingNames.length === 0) {
					return "Unknown"
				} else if (selectedGeofencingNames.length === areas.length) {
					return "All Selected"
				} else {
					return selectedGeofencingNames.join(", ")
				}
			}
		},
		{
			field: "startDate",
			headerName: "Start Date",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 110,
			renderCell: params => {
				if (!params?.row?.startDate) return ""
				if (
					typeof params?.row?.startDate === "string" ||
					params?.row?.startDate instanceof Date
				) {
					return moment(params?.row?.startDate).format("DD/MM/YYYY")
				}
				return moment(params?.row?.startDate.toDate()).format("DD/MM/YYYY")
			}
		},
		{
			field: "email",
			headerName: "Email",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 250
		},
		{
			field: "phoneNumber",
			headerName: "Phone Number",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 140
		},
		{
			field: "helmetId",
			headerName: "Helmet ID",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 120
		},
		{
			field: "nationality",
			headerName: "Nationality",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 120
		},
		{
			field: "idCard",
			headerName: "ID Number",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 120
		},
		{
			field: "passport",
			headerName: "Passport Number",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 140
		},
		{
			field: "driverLicense",
			headerName: "Driver's License",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 140
		},
		{
			field: "birthdate",
			headerName: "Date of Birth",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 140,
			renderCell: params => {
				if (!params?.row?.birthdate) return ""
				if (
					typeof params?.row?.birthdate === "string" ||
					params?.row?.birthdate instanceof Date
				) {
					return moment(params?.row?.birthdate).format("DD/MM/YYYY")
				}
				return moment(params?.row?.birthdate.toDate()).format("DD/MM/YYYY")
			}
		},
		{
			field: "emergencyName",
			headerName: "Emergency Name",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 230
		},
		{
			field: "emergencyNumber",
			headerName: "Emergency Contact",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 230
		},
		{
			field: "supervisorName",
			headerName: "Supervisor Name",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 230
		},
		{
			field: "supervisorNumber",
			headerName: "Supervisor Contact",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 240
		},
		{
			field: "disabled",
			headerName: "Disabled",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "boolean",
			renderCell: params => {
				const { disabled } = params.row
				if (!disabled) {
					return "false"
				} else {
					return "true"
				}
			}
		}
	]

	const handleSearch = value => {
		setSearchText(value)
	}

	const parsePeopleData = data => {
		return (
			data?.length > 0 &&
			data
				.filter(person => UIProps.confirmTabRows.includes(person.id))
				.map(person => ({
					...person,
					name: person.personalData?.name || "",
					email: person.personalData?.email || "",
					phoneNumber: person.personalData?.phoneNumber || "",
					nationality: person.personalData?.nationality || "",
					passport: person.personalData?.passport || "",
					idCard: person.personalData?.idCard || "",
					driverLicense: person.personalData?.driverLicense || "",
					birthdate: person.personalData?.birthdate || "",
					companyName:
						(allVendors &&
							allVendors.find(vendor => vendor.id === person.companyId)?.company) ||
						""
				}))
				.sort((a, b) => {
					const nameA = a.personalData?.name || ""
					const nameB = b.personalData?.name || ""
					return nameA.localeCompare(nameB)
				})
		)
	}

	const handlePeopleToUse = () => {
		const parsedAllPeople = parsePeopleData(allPeople)
		setPeopleToUse(parsedAllPeople)
	}

	useEffect(() => {
		if (!UIProps.confirmTabRows) return

		// setSelectionModel(UIProps.confirmTabRows)
		handlePeopleToUse()
	}, [UIProps.confirmTabRows])

	useEffect(() => {
		if (!peopleToUse) return
		setRows(peopleToUse)
	}, [peopleToUse])

	useEffect(() => {
		if (!rows) return

		if (!searchText && searchText.trim() == "") setSearchedRows(filteredRows)
		// return

		const fieldsToSearch = [
			"personalData.name",
			"personalData.email",
			"personalData.phoneNumber",
			"company",
			"role",
			"uuid",
			"UID"
		] // Define the fields to search
		const filteredRows = filterBySearchString(rows, searchText, fieldsToSearch)

		if (filteredRows && filteredRows.length > 0) {
			setSearchedRows(filteredRows)
		} else {
			setSearchedRows([])
		}
	}, [rows, searchText])

	useEffect(() => {
		if (!rows && !searchedRows) return

		if (searchedRows) {
			setRowsToUse(searchedRows)
		} else if (rows && rows.length > 0) {
			setRowsToUse(rows)
		}
	}, [rows, searchedRows])

	useEffect(() => {
		if (UIProps.membersToSendMessage && UIProps.membersToSendMessage.length > 0) {
			const allRowIds = UIProps.membersToSendMessage.map(row => row.id)
			setSelectionModel(allRowIds)
			return
		}

		if (rowsToUse && rowsToUse.length > 0) {
			const allRowIds = rowsToUse.map(row => row.id)
			setSelectionModel(allRowIds)
		}
	}, [rowsToUse])

	const handleSubmit = () => {
		if (!customerId) return

		if (!rowsToUse || rowsToUse.length === 0) {
			dispatch({
				type: "SNACKBAR_WARNING",
				payload: {
					message: `No group members selected. Please select at least one group member.`
				}
			})
			return
		}

		const members = rowsToUse.filter(person => selectionModel.includes(person.id))

		const uniqueArrayOfMembers = Array.from(new Set(members))

		// dispatch(
		// 	actions.createGroup({
		// 		customerId: customerId,
		// 		sites: UIProps.sitesValue,
		// 		members: uniqueArrayOfMembers,
		// 		name: UIProps.groupName,
		// 		onHide: onHide
		// 	})
		// ).then(() => {
		// 	onHide()
		// })

		UIProps.setMembersToSendMessage(uniqueArrayOfMembers)
	}

	return (
		<div style={{ padding: "1rem", backgroundColor: "#FFFFFF" }} data-testid="confirm-tab">
			<div className="d-flex justify-content-between pt-4">
				<div className="col">
					<span
						style={{
							color: "#444",
							fontFamily: "Poppins",
							fontSize: "16px",
							fontStyle: "normal",
							fontWeight: "600",
							lineHeight: "160%"
						}}
					>
						Confirm Recipients {`(${peopleToUse.length})`}
					</span>
				</div>
				<div className="col" style={{ display: "flex", placeContent: "end" }}>
					<SearchBarCustom1
						onSearch={handleSearch}
						searchText={searchText}
						disabled={!peopleToUse || peopleToUse.length === 0}
						placeholder="Search"
						size="small"
						style={{
							width: "200px",
							background: "#F2F2F2",
							marginRight: "10px"
						}}
						InputProps={{
							startAdornment: (
								<SearchIcon
									fontSize="small"
									style={{
										color: "#7F8C8D",
										marginRight: "0.7rem"
									}}
								/>
							)
						}}
					/>
				</div>
			</div>
			<div className={classes.root}>
				<Box sx={{ width: "100%" }}>
					<DataGrid
						rows={rowsToUse}
						columns={columns}
						style={{
							fontFamily: "Poppins",
							fontWeight: 400,
							fontSize: "13px",
							border: "none",
							width: "100%",
							color: "#464e5f"
						}}
						className={classes.root}
						density="standard"
						pagination
						disableSelectionOnClick
						disableColumnMenu={true}
						hideFooterPagination
						autoHeight={false}
						sortingOrder={["desc", "asc"]}
						getRowClassName={params => (params.row.disabled ? "disabled-row" : "")} //*apply class to disabled people
						//* to selected only current page rows
						checkboxSelection
						paginationMode="server"
						onSelectionModelChange={newSelectionModel => {
							// UIProps.setIds(newSelectionModel)
							setSelectionModel(newSelectionModel)
						}}
						selectionModel={selectionModel}
					/>
				</Box>
			</div>
			<div className="row justify-content-between" style={{ padding: "0 1rem 0 1rem" }}>
				<Button
					color="primary"
					variant="outlined"
					startIcon={<KeyboardArrowLeftIcon fontSize="small" />}
					onClick={e => handleChange(e, 0)}
				/>
				<Button
					color="primary"
					variant="contained"
					text="SAVE"
					className="ml-2"
					disabled={!peopleToUse || peopleToUse.length === 0}
					// onClick={() => handleSubmit()}
					onClick={e => {
						handleSubmit()
						handleChange(e, 2)
					}}
				/>
			</div>
		</div>
	)
}

export default ConfirmTab
