import React, { useState, useMemo, useEffect } from "react"
import { useUIContext } from "../../UIContext"
import { makeStyles } from "@material-ui/core/styles"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import StepContent from "@material-ui/core/StepContent"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"

import { Button } from "../../../../_partials/Button"

import SitesFilter from "./FilterPeopleSteps/SitesFilter"
import GroupsFilter from "./FilterPeopleSteps/GroupsFilter"

const useStyles = makeStyles(theme => ({
	textField: {
		//  marginTop: "2px",
		width: "100%",
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		margin: 0,
		"& .MuiFilledInput-root:hover": {
			border: "1px solid #8C8CA1"
		},

		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		// -------------- \\
		// --- Filled --- \\
		"& .MuiFilledInput-root": {
			backgroundColor: "#F4F4F4",
			// backgroundColor: "#F2F2F2",
			borderColor: "rgba(0,0,0,0)",
			// borderRadius: "4px"
			borderRadius: "5px"
		},
		"& .MuiFilledInput-inputMarginDense": {
			padding: "0 15px",
			height: "40px",
			verticalAlign: "middle"
		},
		"& .MuiFilledInput-multiline.MuiFilledInput-marginDense ": {
			padding: "12px"
		},
		// -------------------- \\
		// --- Autocomplete --- \\
		"& .MuiAutocomplete-inputRoot": {
			backgroundColor: "#F4F4F4",
			borderColor: "rgba(0,0,0,0)",
			borderRadius: "5px"
		}
	},
	textFieldError: {
		border: "1px solid #F64E60"
	},
	textFieldDefaultSize: {
		"& .MuiInputBase-root": {
			height: "45px",
			padding: "0px 12px",
			display: "flex",
			alignItems: "center"
		},
		"& .MuiInputBase-input": {
			padding: "0"
		}
	},
	helperText: {
		margin: 0,
		color: theme.palette.error.main
	},
	textFieldWithAppend: {
		marginTop: "2px",
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		margin: 0,
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px",
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px",
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0
		},
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0
	},
	stepper: {
		"& .MuiStepLabel-iconContainer": {
			paddingRight: "12px"
		},
		"& .MuiSvgIcon-root": {
			width: "1.3em",
			height: "1.3em",
			fontWeight: "bold"
		},
		"& .MuiStepLabel-label": {
			fontFamily: "Poppins",
			fontSize: "16px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "24px"
		},
		"& .MuiStepLabel-label.MuiStepLabel-active": {
			color: "#2673F0"
		},
		"& .MuiStepLabel-label.MuiStepLabel-completed": {
			color: "#2673F0"
		}
	}
}))

function getSteps() {
	return ["Sites", "Groups"]
}

function getStepContent(step, sitesValue, setSitesValue, groupName) {
	switch (step) {
		case 0:
			return (
				<SitesFilter
					sitesValue={sitesValue}
					setSitesValue={setSitesValue}
					groupName={groupName}
				/>
			)
		case 1:
			return <GroupsFilter />
		default:
			return "Unknown step"
	}
}

export default function FilterPeople({ handleChange, allGroups, allPeople }) {
	const classes = useStyles()

	// useUIContext
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			groupId: UIContext.groupId,
			groupName: UIContext.groupName,
			sitesValue: UIContext.sitesValue
		}
	}, [UIContext])

	// useState
	const [activeStep, setActiveStep] = useState(0)
	const [sitesValue, setSitesValue] = useState(UIProps.sitesValue)

	// useEffect
	useEffect(() => {
		if (!UIProps.groupId) return

		setSitesValue(UIProps.sitesValue)
	}, [UIProps.groupId])

	// handlers
	const steps = getSteps()

	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1)
	}

	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1)
	}

	const handleReset = () => {
		setActiveStep(0)
	}

	return (
		<div className={classes.root} data-testid="filter-people-container">
			<Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
				{steps.map((label, index) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
						<StepContent>
							{/* <Typography>{getStepContent(index)}</Typography> */}
							{getStepContent(index, sitesValue, setSitesValue, UIProps.groupId, classes)}
							<div className={classes.actionsContainer}>
								<div className="d-flex">
									<Button
										variant="outlined"
										color="primary"
										disabled={activeStep === 0}
										onClick={handleBack}
										className={classes.button}
									>
										Back
									</Button>
									<Button
										variant="contained"
										color="primary"
										onClick={
											activeStep === steps.length - 1
												? e => handleChange(e, 1)
												: handleNext
										}
										className={`${classes.button} ml-2`}
										disabled={
											(activeStep === 1 && (!sitesValue || sitesValue.length === 0)) ||
											!allPeople ||
											allPeople.length === 0
										}
									>
										{activeStep === steps.length - 1 ? "Finish" : "Next"}
									</Button>
								</div>
							</div>
						</StepContent>
					</Step>
				))}
			</Stepper>
			{activeStep === steps.length && (
				<Paper square elevation={0} className={classes.resetContainer}>
					<Typography>All steps completed - you&apos;re finished</Typography>
					<Button onClick={handleReset} className={classes.button}>
						Reset
					</Button>
				</Paper>
			)}
		</div>
	)
}
