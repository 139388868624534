import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import * as actions from "../../../_redux/admin/inventory/InventoryActions"
import ImageUploading from "react-images-uploading"
import { makeStyles } from "@material-ui/core/styles"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { Button } from "../../../../_partials/Button"
import { Input } from "../../../../_partials/inputs/Input"
import { Checkbox } from "../../../../_partials/inputs/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { Autocomplete_People } from "../../../../_partials/inputs/Autocomplete_PeopleTable"
import { startButtonProgress, finishButtonProgress } from "../../utils/utils"

const EditSchema = Yup.object().shape({
	name: Yup.string()
		.nullable()
		.trim()
		.required("Name is required")
		.min(1, "Name must not be empty"),
	vendor: Yup.string()
		.nullable()
		.trim()
		.required("Vendor is required")
		.min(1, "Vendor Vendor must not be empty"),
	type: Yup.string()
		.nullable()
		.trim()
		.when("isGateway", {
			is: false,
			then: Yup.string().required("Type is required").min(1, "Type must not be empty"),
			otherwise: Yup.string().nullable()
		}),
	nodeType: Yup.string().nullable().required("Node type is required"),
	LEDs: Yup.string()
		.nullable()
		.trim()
		.test(
			"is-greater-than-zero",
			"LEDs must be greater than 0",
			value => !value || parseFloat(value) > 0
		),
	battery: Yup.string()
		.nullable()
		.trim()
		.test(
			"is-greater-than-zero",
			"Battery must be greater than 0",
			value => !value || parseFloat(value) > 0
		),
	sinks: Yup.string()
		.nullable()
		.trim()
		.test(
			"is-greater-than-zero",
			"Sink must be greater than 0",
			value => !value || parseFloat(value) > 0
		),
	chipset: Yup.string()
		.nullable()
		.trim()
		.required("Chipset is required")
		.min(1, "Chipset Chipset must not be empty")
})

const useStyles = makeStyles(theme => ({
	checkbox: {
		borderRadius: "4px",
		//*figma
		// background: "#F7F7F7",
		background: "#ECF1F4",
		width: "100%",
		"& .MuiFormControlLabel-label": {
			color: "#4A4A68",
			textAlign: "center",
			fontFamily: "Poppins",
			fontSize: "14px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "22px"
		}
	},
	autocomple_input: {
		"& .MuiInputBase-root": {
			height: "40px",
			padding: "0 15px",
			verticalAlign: "middle"
		},
		"& input": {
			textTransform: "capitalize"
		}
	}
}))

const initialValues = {
	name: "",
	vendor: "",
	type: "",
	nodeType: "",
	sinks: "0",
	LEDs: "",
	battery: "",
	chipset: "",
	features: {
		button: false,
		fallDetection: false, //motion
		buzzer: false,
		nfc: false
	},
	ipProtection: "",
	images: []
}

const ipProtection_list = [
	{ value: "ip65", name: "IP65" },
	{ value: "ip66", name: "IP66" },
	{ value: "ip67", name: "IP67" },
	{ value: "ip68", name: "IP68" }
]

const nodeType_list = [
	{ value: "anchor", name: "anchor" },
	{ value: "tag", name: "tag" },
	{ value: "gateway", name: "gateway" },
	{ value: "sensor", name: "sensor" }
]

const type_list = [
	{ value: "card", name: "Card" },
	{ value: "bracelet", name: "Bracelet" },
	{ value: "keyfob", name: "Keyfob" },
	{ value: "helmet", name: "Helmet" }
	// { value: "sensor", name: "Sensor" }
]

const chipset_list = [
	{ value: "NORDIC NRF 52832", name: "NORDIC NRF 52832" },
	{ value: "NORDIC NRF 52833", name: "NORDIC NRF 52833" },
	{ value: "NORDIC NRF 54840", name: "NORDIC NRF 54840" }
]

export function AddInventoryModelsModalForm({
	setShowNewModelModal,
	listLoading,
	technology,
	user,
	onHide,
	saving,
	setSaving
}) {
	const dispatch = useDispatch()
	const classes = useStyles()

	// ━━━━━━━━━━━━━  useState  ━━━━━━━━━━━━━ \\
	const [formValues, setFormValues] = useState(initialValues)
	const [isGateway, setIsGateway] = useState(false)
	const [images, setImages] = useState([])
	// ━━━━━━━━━━━━━  useEffect  ━━━━━━━━━━━━━ \\

	// ━━━━━━━━━━━━━  handlers  ━━━━━━━━━━━━━ \\
	const getUserPic = () => {
		if (!images[0]?.data_url) {
			return `url(${toAbsoluteUrl("/media/logos/blank_2.svg")}`
		}
		return `url(${images[0].data_url})`
	}

	// Function to convert file to Base64 encoding
	const fileToBase64 = async file => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result.split(",")[1]) // Removes data URL prefix
			reader.onerror = error => reject(error)
		})
	}
	// Function to validate file type
	const isValidFileType = file => {
		const allowedExtensions = ["png", "jpg", "jpeg"] // List allowed file extensions
		const fileExtension = file.name.split(".").pop().toLowerCase()

		return allowedExtensions.includes(fileExtension)
	}

	const onSubmit = async values => {
		if (!formValues || !technology || !user.id) return
		setSaving(true)
		const buttonToProgressEl = document.getElementById("buttonToProgress_editPoi")
		buttonToProgressEl &&
			startButtonProgress(buttonToProgressEl, "button__progress_editPoi", "button__text_editPoi")
		let base64Images = []
		for (const image of formValues.images) {
			if (!isValidFileType(image.file)) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload:
						"Invalid file type! Please select files of the following types: png, jpg, jpeg."
				})
				return
			}
			try {
				const base64String = await fileToBase64(image.file)
				base64Images.push(base64String)
			} catch (error) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error converting file to Base64." }
				})
				console.error("Error converting file to Base64:", error)
				return
			}
		}

		if (formValues.images && formValues.images.length > 0 && !base64Images) return

		dispatch(
			actions.createModel({
				data: {
					...formValues,
					...formValues.features,
					images: base64Images,
					callersUserId: user.id
				},
				technology: technology,
				setShowNewModelModal,
				onHide
			})
		)
			.then(() => {
				setSaving(false)
				onHide()
			})
			.catch(error => {
				console.error("Error saving:", error) // Handle error
				setSaving(false) // Reset saving state on error
			})
	}

	const handleCheckboxChange = featureKey => {
		setFormValues(prevValues => ({
			...prevValues,
			features: {
				...prevValues.features,
				[featureKey]: !prevValues.features[featureKey]
			}
		}))
	}

	const hide = () => {
		setShowNewModelModal(false)
	}

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={EditSchema}
			// onSubmit={values => {
			// 	onSubmit(values)
			// }}
			data-testid="AddInventoryModelsModalForm-Formik"
		>
			{({ setFieldValue, handleSubmit, dirty, onImageUpload, values, isValid }) => {
				return (
					<Form className="form form-label-right" data-testid="AddInventoryModelsModalForm">
						{/* Image, Name, Vendor */}
						<div className="form-group row mb-5">
							<div className="col text-center">
								<ImageUploading
									value={images}
									onChange={(imageList, addUpdateIndex) => {
										// setImages(imageList)
										if (!imageList) {
											setFieldValue("images", "")
											setImages(imageList)
											setFormValues({ ...formValues, images: [] })
										} else {
											setFieldValue("images", imageList)
											setImages(imageList)
											setFormValues({ ...formValues, images: imageList })
										}
									}}
									dataURLKey="data_url"
									multiple
									maxNumber={4} // Set maximum number of images allowed
									acceptType={["png", "jpg", "jpeg"]}
								>
									{({
										onImageUpload,
										onImageRemoveAll,
										onImageRemove,
										isDragging,
										dragProps
									}) => (
										<div
											className="upload__image-wrapper"
											style={{
												pointerEvents: saving ? "none" : "auto"
											}}
										>
											{images && images.length <= 0 ? (
												<div
													className="image-input image-input-outline"
													style={{
														backgroundColor: "white",
														paddingRight: "8px",
														paddingTop: "8px",
														paddingBottom: "8px"
													}}
												>
													<div
														className="image-input-wrapper"
														style={{
															borderColor: isDragging ? "#C3C4DD" : "white",
															backgroundImage: `${getUserPic()}`,
															cursor: "pointer",
															height: "120px",
															width: "120px",
															backgroundSize: "auto",
															backgroundPosition: "center",
															backgroundColor: "#DDE9FD"
														}}
														onClick={onImageUpload}
														{...dragProps}
													/>
													<label
														className="btn btn-md btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
														data-action="change"
														data-toggle="tooltip"
														title=""
														data-original-title="Change logo"
														onClick={onImageUpload}
													>
														<i className="fa fa-pen icon-sm text-muted"></i>
													</label>
												</div>
											) : (
												<div
													style={{
														textAlign: "-webkit-center"
													}}
												>
													<div
														className="image-input image-input-outline row"
														style={{
															backgroundColor: "white",
															paddingRight: "8px",
															paddingTop: "8px",
															paddingBottom: "8px",
															maxWidth: "200px",
															width: "200px",
															display: "flex"
														}}
													>
														{/* Rendering images */}
														{images.map((image, index) => (
															<div key={index} className="col-6 mb-5">
																<div
																	className="image-input-wrapper"
																	style={{
																		borderColor: isDragging ? "#C3C4DD" : "white",
																		backgroundImage: `url(${image.data_url})`, // Show selected image
																		cursor: "pointer",
																		height: "80px",
																		width: "80px",
																		minWidth: "80px",
																		backgroundSize: "auto",
																		backgroundPosition: "center",
																		backgroundColor: "#DDE9FD"
																	}}
																>
																	<span
																		className="btn btn-md btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
																		style={{
																			position: "absolute",
																			right: "0",
																			top: "-10px",
																			right: "-10px"
																		}}
																		onClick={() => onImageRemove(index)}
																	>
																		<i className="ki ki-bold-close icon-xs text-muted"></i>
																	</span>
																</div>
															</div>
														))}
													</div>
												</div>
											)}
											{images[0]?.file?.size &&
												images[0].file.size / (1024 * 1024) > 10 && (
													<span className="form-text text-danger mt-2">
														{`Image is too big (${(
															images[0].file.size /
															(1024 * 1024)
														).toFixed(2)}Mb).`}
														<br />
														Max imagesize: 10Mb.
													</span>
												)}
											<span
												className="form-text mt-2"
												style={{
													color: "#6CA4FF",
													textAlign: "center",
													fontFamily: "Poppins",
													fontSize: "10px",
													fontStyle: "normal",
													fontWeight: "400",
													lineHeight: "14px"
												}}
											>
												Allowed file types: <br />
												png, jpg, jpeg
											</span>
										</div>
									)}
								</ImageUploading>
							</div>
							<div className="col">
								<div className="row">
									<Field
										// disabled={personId ? true : false}
										variant="filled"
										name="name"
										label="Name"
										disabled={saving}
										labelFullWidth={true}
										inputFullWidth={true}
										requiredField={true}
										size="small"
										component={Input}
										InputProps={{
											disableUnderline: true
										}}
										placeholder="Enter name"
										onChange={event => {
											if (!event.target.value) {
												// setSelectedFirmware("")
												setFieldValue("name", "")
												setFormValues({ ...formValues, name: "" })
											} else {
												// setSelectedFirmware(event.target.value)
												setFieldValue("name", event.target.value)
												setFormValues({ ...formValues, name: event.target.value })
											}
										}}
									/>
								</div>
								<div className="row mt-3">
									<Field
										// disabled={personId ? true : false}
										variant="filled"
										name="vendor"
										label="Vendor"
										disabled={saving}
										labelFullWidth={true}
										inputFullWidth={true}
										requiredField={true}
										size="small"
										component={Input}
										InputProps={{
											disableUnderline: true
										}}
										placeholder="Enter Vendor"
										onChange={event => {
											if (!event.target.value) {
												setFieldValue("vendor", "")
												setFormValues({ ...formValues, vendor: "" })
											} else {
												setFieldValue("vendor", event.target.value)
												setFormValues({ ...formValues, vendor: event.target.value })
											}
										}}
									/>
								</div>
							</div>
						</div>

						{/* Type, Node type */}
						<div className="form-group row mb-5">
							<div className="col">
								<Field
									id="ip-autocomplete"
									customColor="#ECF1F4"
									name="nodeType"
									label="Node Type"
									disabled={saving}
									className={classes.autocomple_input}
									requiredField
									component={Autocomplete_People}
									placeholder="Choose node type"
									options={
										nodeType_list && [
											...nodeType_list.map(val => {
												const { value, name } = val
												return { value: value, name: name }
											})
										]
									}
									value={formValues.nodeType}
									onChange={(event, newValue) => {
										if (!newValue) {
											setFieldValue("nodeType", "")
											setFormValues({ ...formValues, nodeType: "" })
											setIsGateway(false)
										} else {
											setFieldValue("nodeType", newValue)
											setFormValues({ ...formValues, nodeType: newValue })
											if (newValue == "gateway") setIsGateway(true)
											else setIsGateway(false)
										}
									}}
								/>
							</div>
							{formValues.nodeType != "sensor" && formValues.nodeType != "anchor" && (
								<div className="col">
									<Field
										id="ip-autocomplete"
										customColor="#ECF1F4"
										name="type"
										label="Type"
										disabled={saving || isGateway}
										requiredField={!isGateway}
										className={classes.autocomple_input}
										component={Autocomplete_People}
										placeholder="Enter type"
										options={
											type_list && [
												...type_list.map(val => {
													const { value, name } = val
													return { value: value, name: name }
												})
											]
										}
										value={formValues.type}
										onChange={(event, newValue) => {
											if (!newValue) {
												setFieldValue("type", "")
												setFormValues({ ...formValues, type: "" })
											} else {
												setFieldValue("type", newValue)
												setFormValues({ ...formValues, type: newValue })
											}
										}}
									/>
								</div>
							)}
						</div>

						{/* Sink */}
						{isGateway && (
							<div className="form-group row mb-5">
								<div className="col">
									<Field
										variant="filled"
										name="sinks"
										label="Sink"
										size="small"
										component={Input}
										type="number"
										InputProps={{
											disableUnderline: true
										}}
										placeholder="Enter Sink number"
										onChange={event => {
											if (!event.target.value) {
												setFieldValue("sinks", "0")
												setFormValues({ ...formValues, sinks: "0" })
											} else {
												setFieldValue("sinks", event.target.value)
												setFormValues({ ...formValues, sinks: event.target.value })
											}
										}}
									/>
								</div>
							</div>
						)}

						{/* Button, Motion, Buzzer, NFC */}
						<span
							style={{
								color: "#1C1C3B",
								fontFamily: "Poppins",
								fontSize: "16px",
								fontStyle: "normal",
								fontWeight: "500",
								lineHeight: "24px",
								display: isGateway ? "none" : "block"
							}}
						>
							Specific Features
						</span>
						<div
							className="form-group row mb-5 pl-4 mt-3"
							style={{ display: isGateway ? "none" : "flex" }}
						>
							{/* <div className="d-flex align-items-center w-100"> */}
							{formValues &&
								formValues.features &&
								Object.keys(formValues.features).map((featureKey, index) => (
									<div className="col-6 pr-0" key={index}>
										<FormControlLabel
											control={
												<Checkbox
													disabled={saving}
													checked={formValues.features[featureKey]} // Assuming features is an object with boolean values
													onClick={() => handleCheckboxChange(featureKey)}
												/>
											}
											label={featureKey}
											className={classes.checkbox}
										/>
									</div>
								))}
							{/* </div> */}
						</div>

						{/* Leds, Ip protection */}
						<div className="form-group row mb-5">
							<div className="col" style={{ display: isGateway ? "none" : "block" }}>
								<Field
									variant="filled"
									name="LEDs"
									label="LEDs"
									size="small"
									disabled={saving}
									component={Input}
									type="number"
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter LEDs number"
									onChange={event => {
										if (!event.target.value) {
											setFieldValue("LEDs", "")
											setFormValues({ ...formValues, LEDs: "" })
										} else {
											setFieldValue("LEDs", event.target.value)
											setFormValues({ ...formValues, LEDs: event.target.value })
										}
									}}
								/>
							</div>
							<div className="col">
								<Field
									id="ip-autocomplete"
									customColor="#ECF1F4"
									name="ipProtection"
									label="Ip protection"
									disabled={saving}
									component={Autocomplete_People}
									placeholder="Choose ip protection"
									options={
										ipProtection_list && [
											...ipProtection_list.map(val => {
												const { value, name } = val
												return { value: value, name: name }
											})
										]
									}
									value={formValues.ipProtection}
									onChange={(event, newValue) => {
										if (!newValue) {
											setFieldValue("ipProtection", "")
											setFormValues({ ...formValues, ipProtection: "" })
										} else {
											setFieldValue("ipProtection", newValue)
											setFormValues({ ...formValues, ipProtection: newValue })
										}
									}}
								/>
							</div>
						</div>

						{/* Battery, Chipset */}
						<div className="form-group row mb-5">
							<div className="col" style={{ display: isGateway ? "none" : "block" }}>
								<Field
									variant="filled"
									name="battery"
									label="Battery"
									type="number"
									size="small"
									disabled={saving}
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter battery"
									onChange={event => {
										if (!event.target.value) {
											setFieldValue("battery", "")
											setFormValues({ ...formValues, battery: "" })
										} else {
											setFieldValue("battery", event.target.value)
											setFormValues({ ...formValues, battery: event.target.value })
										}
									}}
								/>
							</div>
							<div className="col">
								<Field
									id="ip-autocomplete"
									customColor="#ECF1F4"
									name="chipset"
									label="Chipset"
									disabled={saving}
									className={classes.autocomple_input}
									requiredField
									component={Autocomplete_People}
									placeholder="Enter chipset"
									options={
										chipset_list && [
											...chipset_list.map(val => {
												const { value, name } = val
												return { value: value, name: name }
											})
										]
									}
									value={formValues.chipset}
									onChange={(event, newValue) => {
										if (!newValue) {
											setFieldValue("chipset", "")
											setFormValues({ ...formValues, chipset: "" })
										} else {
											setFieldValue("chipset", newValue)
											setFormValues({ ...formValues, chipset: newValue })
										}
									}}
								/>
							</div>
						</div>

						{/* Cancel, Save */}
						<div className="form-group row mb-1 justify-content-end">
							<div className="d-flex align-items-center">
								<Button
									variant="outlined"
									color="primary"
									style={{ width: "85px", height: "35px", marginRight: "10px" }}
									text="Cancel"
									onClick={hide}
									disabled={saving}
									data-testid="Formik_Form_Button"
								/>
								<Button
									data-testid="Formik_Form_Button"
									variant="contained"
									style={{
										width: "65px",
										height: "35px",
										marginRight: "10px",
										// fontFamily: "Poppins",
										fontWeight: "700",
										backgroundColor: "#3077d3",
										color: "#f4f4f4"
									}}
									// text="Save"
									onClick={() => {
										// Starts the change of the saving UI button like progress bar
										const buttonToProgressEl = document.getElementById(
											"buttonToProgress_editPoi"
										)
										buttonToProgressEl &&
											startButtonProgress(
												buttonToProgressEl,
												"button__progress_editPoi",
												"button__text_editPoi"
											)
										onSubmit()
										// handleSubmit()
									}}
									className="ml-2"
									id="buttonToProgress_editPoi"
									data-progress-text="Saving..."
									data-complete-text="Save complete."
								>
									<div className="button__progress_editPoi"></div>
									<span className="button__text_editPoi">Save</span>
								</Button>
							</div>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}
