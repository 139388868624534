import React, { useState, useEffect, useRef } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { firestore } from "../../../../../../firebase"
import { collection, query, where, limit, getDocs, startAfter } from "firebase/firestore"
import { debounce, random } from "lodash"
import { Input } from "../../../../../_partials/inputs/Input"
import { ModalProgressBar } from "../../../../../_partials/ModalProgressBar"
import { shallowEqual, useSelector } from "react-redux"
import { checkIfTagsAreAssigned } from "../../../../_redux/_helpers/ActionHelpers"

export function AutocompleteWithLazyLoading({
	label,
	onChange,
	uuid,
	placeholder,
	customHeight,
	disabled
}) {
	const defaultNoOptionsText = "No available tags found."
	const searchingNoOptionsText = "No available tags found. Please provide full tag uuid."

	const { customerId, selectedSite } = useSelector(
		state => ({
			customerId: state.profile?.currentCustomer?.id,
			selectedSite: state.profile?.currentSite?.id
		}),
		shallowEqual
	)

	const [options, setOptions] = useState([])
	const [optionsHistory, setOptionsHistory] = useState([])
	const [loading, setLoading] = useState(false)
	const [lastDocument, setLastDocument] = useState(null)
	const [hasMore, setHasMore] = useState(true)
	const [exactDocument, setExactDocument] = useState()
	const [noOptionsText, setNoOptionsText] = useState(defaultNoOptionsText)
	const [fetchMoreData, setFetchMoreData] = useState()

	const listRef = useRef(null)

	useEffect(() => {
		if (!fetchMoreData && uuid) return
		fetchData()
	}, [fetchMoreData])

	useEffect(() => {
		uuid && fetchData().then(() => handleSearch({}, uuid))
	}, [uuid])

	useEffect(() => {
		if (exactDocument?.assigned) {
			onChange()
			return
		}
		onChange(exactDocument)
	}, [exactDocument])

	const handleSearch = async (event, value) => {
		setExactDocument()
		if (!value) {
			setNoOptionsText(defaultNoOptionsText)
			optionsHistory && setOptions(optionsHistory)
			return
		}
		setNoOptionsText(searchingNoOptionsText)

		var valueInt = parseInt(value)
		if (isNaN(valueInt)) return

		if (exactDocument?.uuid === valueInt) return

		setLoading(true)

		const collectionRef = query(
			collection(firestore, `Sites/${selectedSite}/Nodes`),
			where("nodeType", "==", "tag"),
			where("uuid", "==", valueInt),
			limit(1)
		)

		const querySnapshot = await getDocs(collectionRef)

		let fetchedOptions = querySnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		if (!querySnapshot.empty && !uuid) {
			const response = await checkIfTagsAreAssigned({ customerId, tags: valueInt })
			const assigned = response ? response[0]?.assigned : false
			fetchedOptions = [{ assigned: assigned, ...fetchedOptions[0] }]
		}

		setExactDocument(fetchedOptions[0])
		setOptions(fetchedOptions)
		setLoading(false)
	}

	const debouncedHandleSearch = debounce(handleSearch, 300) // Adjust the debounce delay as needed

	const fetchData = async () => {
		if (!hasMore) return
		setLoading(true)
		const docsLimit = 15

		const collectionRef = collection(firestore, `Sites/${selectedSite}/Nodes`)
		let q = query(collectionRef, where("nodeType", "==", "tag"), limit(docsLimit))

		if (lastDocument) {
			q = query(collectionRef, limit(docsLimit), startAfter(lastDocument))
		}

		try {
			const querySnapshot = await getDocs(q)

			const fetchedOptions = querySnapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data()
			}))
			const uuidArray = querySnapshot.docs
				.map(doc => doc.data().uuid)
				.filter(val => val && val !== uuid)

			if (querySnapshot.docs.length < docsLimit) {
				setHasMore(false)
			}

			let newOptions = []

			if (uuidArray.length > 0) {
				const uuidArrayChecked = await checkIfTagsAreAssigned({ customerId, tags: uuidArray })

				newOptions = fetchedOptions.filter(val => {
					if (typeof val.uuid !== "number") return
					const isAssigned =
						uuidArrayChecked?.length > 0 &&
						uuidArrayChecked.find(value => value.uuid === val.uuid)?.assigned
					if (isAssigned) return
					return true
				})
			}

			setOptions(prevOptions => [...prevOptions, ...newOptions])
			setOptionsHistory(prevOptions => [...prevOptions, ...newOptions])
			setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1])

			//If new options are less then 10 fetch again
			if (newOptions.length < 10) {
				// Used a state because calling function inside function
				// would cause a loop due to no getting updated states
				setFetchMoreData(random(1, 999999999))
			}
		} catch (err) {
			// Handle the error
			console.log("Error: ", err)
			setLoading(false)
		}

		setLoading(false)
	}

	const handleScroll = () => {
		const { current } = listRef
		if (!current || loading) return

		const { scrollTop, clientHeight, scrollHeight } = current

		if (scrollTop + clientHeight >= scrollHeight && hasMore) {
			fetchData()
		}
	}

	return (
		<Autocomplete
			data-testid="manage-assets_AutocompleteWithLazyLoading"
			variant="filled"
			defaultValue={uuid && { uuid: uuid }}
			options={options}
			disabled={disabled}
			getOptionLabel={option => {
				return option.uuid
					? Number.isInteger(option.uuid)
						? option.uuid.toString()
						: option.uuid
					: "error"
			}}
			loading={loading}
			onOpen={() => handleScroll()}
			onInputChange={debouncedHandleSearch}
			noOptionsText={noOptionsText}
			renderInput={params => (
				<>
					<Input
						style={{ borderRadius: "5px" }}
						placeholder={placeholder}
						{...params}
						label={label}
						InputProps={{
							...params.InputProps
						}}
						customError={
							exactDocument?.assigned
								? "Tag is already assign. Please choose a different tag."
								: null
						}
					/>
					<ModalProgressBar style={{ visibility: loading ? "visible" : "hidden" }} />
				</>
			)}
			ListboxProps={{
				onWheel: handleScroll,
				ref: listRef,
				style: { overflowY: "scroll" }
			}}
		/>
	)
}
