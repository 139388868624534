try {
	var localConfig = require("./local-config").default
} catch (err) {}

// export default {
// 	production: localConfig?.production || "$PRODUCTION",
// 	firebaseConfig: {
// 		apiKey: localConfig?.apiKey || "$FIREBASE_API_KEY",
// 		authDomain: localConfig?.authDomain || "$FIREBASE_AUTH_DOMAIN",
// 		// databaseURL: localConfig?.databaseURL || "$FIREBASE_DATABASE_URL",
// 		projectId: localConfig?.projectId || "$FIREBASE_PROJECT_ID",
// 		storageBucket: localConfig?.storageBucket || "$FIREBASE_STORAGE_BUCKET",
// 		messagingSenderId: localConfig?.messagingSenderId || "$FIREBASE_MESSAGING_SENDER_ID",
// 		appId: localConfig?.appId || "$FIREBASE_APP_ID",
// 		measurementId: localConfig?.measurementId || "$FIREBASE_MEASUREMENT_ID"
// 	}
// }

export default {
	production: (() => {
		const value = process.env.REACT_APP_PRODUCTION || localConfig?.production
		// console.log("Production:", value)
		return value
	})(),
	firebaseConfig: {
		apiKey: (() => {
			const value = process.env.REACT_APP_FIREBASE_API_KEY || localConfig?.apiKey
			// console.log("Firebase API Key:", value)
			return value
		})(),
		authDomain: (() => {
			const value = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || localConfig?.authDomain
			// console.log("Auth Domain:", value)
			return value
		})(),
		projectId: (() => {
			const value = process.env.REACT_APP_FIREBASE_PROJECT_ID || localConfig?.projectId
			// console.log("Project ID:", value)
			return value
		})(),
		storageBucket: (() => {
			const value = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || localConfig?.storageBucket
			// console.log("Storage Bucket:", value)
			return value
		})(),
		messagingSenderId: (() => {
			const value =
				process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || localConfig?.messagingSenderId
			// console.log("Messaging Sender ID:", value)
			return value
		})(),
		appId: (() => {
			const value = process.env.REACT_APP_FIREBASE_APP_ID || localConfig?.appId
			// console.log("App ID:", value)
			return value
		})(),
		measurementId: (() => {
			const value = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || localConfig?.measurementId
			// console.log("Measurement ID:", value)
			return value
		})()
	}
}
