import React from "react"
import SVG from "react-inlinesvg"

const Logo = () => {
	return (
		<div className="text-center" style={{ marginTop: "3rem", marginBottom: "1.5rem" }}>
			<SVG src="logos/ck-icon.svg" style={{ fill: "#FFFFFF", marginBottom: "0.5rem" }} />
			<h1 className="font-size-h1" style={{ color: "#FFFFFF", marginBottom: "0.5rem" }}>
				CROWDKEEP
			</h1>
			<h5 className="font-size-h5" style={{ color: "#0A1B3D", marginBottom: "2.5rem" }}>
				Healthcare
			</h5>
		</div>
	)
}

export default Logo
