import { firestore } from "../../../../firebase"
import { collection, getDocs, or, query, where } from "firebase/firestore"

export function getSites(queryParams) {
	const sitesRef = collection(firestore, "Sites")
	const wherePermissionToView = where(
		"permissionsToView",
		"array-contains",
		queryParams.customerId
	)
	const wherePermissionToEdit = where(
		"permissionsToEdit",
		"array-contains",
		queryParams.customerId
	)
	const orWhere = or(wherePermissionToView, wherePermissionToEdit)
	const sitesQuery = query(sitesRef, orWhere)
	return getDocs(sitesQuery)
}

function callManageSite(params) {
	const {
		operation,
		customerId,
		actionById,
		actionByEmail,
		id,
		name,
		address,
		city,
		state,
		country,
		location,
		networkID,
		networkChannel,
		permissionsToEdit,
		permissionsToView,
		finished,
		disabled
	} = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		operation,
		customerId,
		actionById,
		actionByEmail,
		id,
		name,
		address,
		city,
		state,
		country,
		location,
		networkID,
		networkChannel,
		permissionsToEdit,
		permissionsToView,
		completed: finished,
		disabled
	})

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/manage-site`, requestOptions).catch(
		err => {
			console.log("Error: ", err)
		}
	)
}

export function createSite({ customerId, user, site }) {
	if (!customerId || !user || !site) return Promise.resolve(null)
	return callManageSite({
		operation: "create",
		customerId: customerId,
		actionById: user.id,
		actionByEmail: user.email,
		...site
	})
}

export function editSite({ customerId, user, site, siteID }) {
	if (!customerId || !user || !site || !siteID) return Promise.resolve(null)
	return callManageSite({
		operation: "update",
		customerId: customerId,
		actionById: user.id,
		actionByEmail: user.email,
		id: siteID,
		...site
	})
}

export function disableSite({ customerId, user, disabled, siteID }) {
	if (!customerId || !user || disabled === undefined || !siteID) return Promise.resolve(null)
	return callManageSite({
		operation: "disable",
		customerId: customerId,
		actionById: user.id,
		actionByEmail: user.email,
		id: siteID,
		disabled: disabled
	})
}
