import React, { useMemo } from "react"
import { useUIContext } from "./UIContext"
import { useDispatch } from "react-redux"
import { Button } from "../../../_partials/Button"
import { BiEditAlt } from "react-icons/bi"
import { useSelector, shallowEqual } from "react-redux"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import CloseIcon from "@mui/icons-material/Close"
import { fill } from "lodash"

export function UserGrouping({ setSelectionModel }) {
	const dispatch = useDispatch()

	// ━━━━━━━━━━━━━  Selectors  ━━━━━━━━━━━━━ \\
	const { listLoading, userClaims } = useSelector(
		state => ({
			listLoading: state.inventory.listLoading,
			userClaims: state.auth.claims
		}),

		shallowEqual
	)

	// ━━━━━━━━━━━━━  UIContext  ━━━━━━━━━━━━━ \\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.idsInventoryNodes || [],
			setIds: UIContext.setIdsInventoryNodes,
			openEditInventoryNodesModal: UIContext.openEditInventoryNodesModal,
			openUnassignInventoryNodesModal: UIContext.openUnassignInventoryNodesModal,
			openAssignInventoryNodesModal: UIContext.openAssignInventoryNodesModal,
			openPrintNodeLabelModal: UIContext.openPrintNodeLabelModal,
			openDeleteInventoryNodesModal: UIContext.openDeleteInventoryNodesModal
		}
	}, [UIContext])

	const checkForIds = cb => () => {
		if (UIContext.idsInventoryNodes.length > 0) {
			cb()
			return
		}
		dispatch({
			type: "SNACKBAR_WARNING",
			payload: { message: `No Inventory Nodes Selected` }
		})
	}

	return (
		<div className="form" data-testid="manage-roles_userGrouping">
			<div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
				<div className="col-xl-12">
					<div className="d-flex align-items-center">
						<div className="select_count_box">
							<label className="-font-bold font-danger-">
								<span className="span_selection_count">
									Selected nodes count:{" "}
									<b style={{ color: "#000" }}>{UIProps.ids?.length}</b>
									{UIProps.ids?.length > 0 && (
										<OverlayTrigger overlay={<Tooltip>Clear node selection</Tooltip>}>
											<CloseIcon
												variant="outlined"
												style={{
													color: "#2673F0",
													marginLeft: "5px",
													cursor: "pointer",
													verticalAlign: "bottom"
												}}
												onClick={() => {
													// console.log("here")
													UIProps.setIds([])
													setSelectionModel([])
												}}
											/>
										</OverlayTrigger>
									)}
								</span>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20 pt-3">
				<div className="col-xl-12">
					<div className="d-flex align-items-center">
						<div>
							{/* Assign Nodes */}
							<Button
								disabled={listLoading}
								color="primary"
								variant="outlined"
								text="assign"
								onClick={checkForIds(UIProps.openAssignInventoryNodesModal)}
							/>

							{/* Print Nodes */}
							<Button
								color="primary"
								variant="outlined"
								text="print"
								className="ml-4"
								onClick={checkForIds(UIProps.openPrintNodeLabelModal)}
							/>
							{/* Delete Nodes */}
							<OverlayTrigger
								placement="bottom"
								overlay={
									<Tooltip id="firebase-Edit-tooltip">Delete Selected Nodes</Tooltip>
								}
							>
								<Button
									disabled={listLoading}
									color="primary"
									variant="outlined"
									text="print"
									className="ml-4"
									onClick={checkForIds(UIProps.openPrintNodeLabelModal)}
								/>
							</OverlayTrigger>
							{/* Delete Nodes */}
							<OverlayTrigger
								placement="bottom"
								overlay={
									<Tooltip id="firebase-Edit-tooltip">Delete Selected Nodes</Tooltip>
								}
							>
								<Button
									disabled={listLoading}
									variant="contained"
									color="errorLight"
									className="MUICustom_secondary"
									style={{
										marginLeft: "1rem",
										borderRadius: "5px",
										maxWidth: "34px",
										minWidth: "34px",
										height: "33px"
									}}
									startIcon={
										<SVG
											className="MUICustom_secondary_svg"
											style={{ fill: listLoading && "#A3A3A3" }}
											src={toAbsoluteUrl("/media/svg/icons/General/trash2.svg")}
										/>
									}
									onClick={checkForIds(UIProps.openDeleteInventoryNodesModal)}
									data-testid="delete-button"
								/>
							</OverlayTrigger>
							{/* Edit Nodes */}
							<OverlayTrigger
								placement="bottom"
								overlay={<Tooltip id="inventory-edit">Edit Selected Nodes</Tooltip>}
							>
								<Button
									disabled={listLoading}
									variant="contained"
									color="primaryLight"
									className="MUICustom_primaryLight"
									style={{
										marginLeft: "1rem",
										borderRadius: "5px",
										maxWidth: "34px",
										minWidth: "34px",
										height: "33px"
									}}
									startIcon={<BiEditAlt />}
									onClick={checkForIds(UIProps.openEditInventoryNodesModal)}
									data-testid="edit-button"
								/>
							</OverlayTrigger>
							{/* Unassign Nodes */}
							<OverlayTrigger
								placement="bottom"
								overlay={<Tooltip id="inventory-unassign">Unassign Selected Nodes</Tooltip>}
							>
								<Button
									disabled={listLoading}
									variant="contained"
									color="errorLight"
									className="MUICustom_secondary"
									style={{
										marginLeft: "1rem",
										borderRadius: "5px",
										maxWidth: "34px",
										minWidth: "34px",
										height: "33px"
									}}
									startIcon={
										<SVG
											className="MUICustom_secondary_svg"
											style={{ fill: listLoading && "#A3A3A3" }}
											src={toAbsoluteUrl("/media/svg/icons/General/folder-off.svg")}
										/>
									}
									//TODO: Unassign Node from customer/floorplan/SITEID
									onClick={checkForIds(UIProps.openUnassignInventoryNodesModal)}
									data-testid="unassign-button"
								/>
							</OverlayTrigger>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
