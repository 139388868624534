import React, { useMemo } from "react"
import objectPath from "object-path"
import { useHtmlClassService } from "../../_core/MetronicLayout"
import { profileSlice } from "../../../../app/redux/profile/profileSlice"
import { AnimateLoading } from "../../../../_metronic/_partials/controls"
import HeaderSelections from "./HeaderSelections"
import { QuickUserToggler } from "../extras/QuickUserToggler"
import { Button } from "../../../_partials/Button"
import { useHistory } from "react-router-dom"
import FirebaseNotifications from "../extras/FirebaseNotifications"
import FirebaseMessaging from "../extras/FirebaseMessaging"
import "./headerCSS.css"
import { useDispatch } from "react-redux"
import MassTextAlerts from "../extras/MassTextAlerts"
import { ModalProgressBar } from "../../../_partials/ModalProgressBar"

const { actions } = profileSlice

export function Header() {
	const dispatch = useDispatch()
	const uiService = useHtmlClassService()
	const history = useHistory()

	const layoutProps = useMemo(() => {
		return {
			headerClasses: uiService.getClasses("header", true),
			headerAttributes: uiService.getAttributes("header"),
			headerContainerClasses: uiService.getClasses("header_container", true),
			menuHeaderDisplay: objectPath.get(uiService.config, "header.menu.self.display"),
			viewUserDisplay: objectPath.get(uiService.config, "extras.user.display")
		}
	}, [uiService])

	return (
		<>
			{/*begin::Header*/}
			<div
				className={`header ${layoutProps.headerClasses}`}
				id="kt_header"
				{...layoutProps.headerAttributes}
			>
				{/*begin::Container*/}
				<div
					className={`topbar ${layoutProps.headerContainerClasses} d-flex align-items-center justify-content-between`}
				>
					<AnimateLoading />

					{/*begin::Topbar*/}
					<div style={{ overflow: "auto", marginRight: "10px" }}>
						<HeaderSelections />
						{/* {<ModalProgressBar />} */}
					</div>
					<div style={{ display: "flex", alignItems: "center" }}>
						{/* Mass Messaging Send Modal */}
						{/* <MassTextAlerts /> */}

						{/* Firebase Messaging*/}
						<FirebaseMessaging />

						{/* Firebase Notifications*/}
						<FirebaseNotifications />

						{layoutProps.viewUserDisplay && <QuickUserToggler />}

						<Button
							//contained
							variant="contained"
							color="primaryLight"
							// className="signOutBtn"
							style={{
								marginLeft: "20px",
								maxWidth: "104px"
							}}
							text="SIGN OUT"
							onClick={() => {
								dispatch(actions.customerSelected(null))
								dispatch(actions.siteSelected(null))
								dispatch(actions.floorPlanSelected(null))
								history.push("/logout")
							}}
							// startIcon={<DeleteIcon />}
							// disabled
						/>
					</div>
					{/*end::Topbar*/}
				</div>
				{/*end::Container*/}
			</div>
			{/*end::Header*/}
		</>
	)
}
