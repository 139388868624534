import React, { forwardRef, useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/managePeople/managePeopleActions"
import { Input } from "../../../../_partials/inputs/Input"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { Autocomplete_People } from "../../../../_partials/inputs/Autocomplete_PeopleTable"
import { SelectMultiCheckbox } from "../../../../_partials/inputs/SelectMultiCheckbox"
import { Button } from "../../../../_partials/Button"
import { AutocompleteWithLazyLoading } from "../../../Admin/manage-customer/Assets/manage-assets/AutocompleteWithLazyLoading"
import DatePicker from "react-datepicker"
import { InputAdornment, TextField, makeStyles } from "@material-ui/core"
import { MdOutlineCalendarToday } from "react-icons/md"
import { checkIfTagsAreAssigned } from "../../../_redux/_helpers/ActionHelpers"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { Collapse, IconButton } from "@material-ui/core/"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import "react-phone-input-2/lib/style.css"
import PhoneInput from "react-phone-input-2"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import ImageUploading from "react-images-uploading"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { fetchDownloadURL } from "../../../_redux/managePeople/managePeopleHelpers"
import { ExampleCustomInput, ExampleCustomInput2 } from "./PersonDetailedPageForm"
import { uuidv4 } from "../../UIHelpers"
import "./PatientForm.css"
import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
import { GeocoderInput } from "../../../../_partials/inputs/GeocoderInput"
import { useHistory, useParams } from "react-router-dom"

const genderList = [
	{ value: "Male", name: "Male" },
	{ value: "Female", name: "Female" }
]

const PatientForm = ({
	header,
	generalInfoOpen,
	setGeneralInfoOpen,
	classes,
	onImageChange,
	getPicture,
	picture,
	values,
	isLoading,
	newImage,
	isSuper,
	personId,
	setCountryCode,
	handleSubmit,
	dirty,
	documentationInfoOpen,
	setDocumentationInfoOpen,
	nationalities,
	selectedNationality,
	setSelectedNationality,
	setFieldValue,
	onboardingOpen,
	setOnboardingOpen,
	vendors,
	selectedCompany,
	setSelectedCompany,
	initialValues,
	roles,
	selectedRole,
	setSelectedRole,
	areas,
	otherContacts,
	setOtherContacts,
	setNewImage,
	personToEdit,
	mapContainerRef
}) => {
	const history = useHistory()
	const { id } = useParams() // Access the id parameter from the URL

	const [birthDate, setBirthDate] = React.useState(initialValues.birthDate ?? new Date())

	const handleLocateClick = () => {
		history.push(`/dashboard-person/${id}`)
	}

	const handleExportClick = () => {
		const form = document.getElementsByTagName("form")[0]
		const printWindow = window.open("", "", "height=600,width=800")

		// Start building the HTML for printing
		let printContent = "<html><head><title>Print Card</title>"
		printContent += "</head><body>"

		// Loop through form elements
		for (let element of form.elements) {
			if (element.tagName === "INPUT" || element.tagName === "TEXTAREA") {
				const label = form.querySelector(`label[for="${element.id}"]`)
				const labelText = label ? label.innerText : element.name // Get label text or name if no label

				// Only add the label and value if the value is not empty
				if (element.value.trim() !== "") {
					printContent += `<p><strong>${labelText}:</strong> ${element.value}</p>`
				}
			}
		}

		printContent += "</body></html>"

		// Write the constructed content to the print window
		printWindow.document.write(printContent)
		printWindow.document.close() // Necessary for IE >= 10
		printWindow.print()
	}

	return (
		<>
			<Form
				className="form form-label-right"
				data-testid="new-person-modal_PersonDetailedPageForm"
				style={{
					padding: "10px 20px",
					borderRadius: "5px",
					background: "#FFF",
					boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.10)"
				}}
			>
				<div className="row">
					<div
						className="col"
						style={{
							color: "#444",
							fontFamily: "Poppins",
							fontSize: "20px",
							fontStyle: "normal",
							fontWeight: "600",
							lineHeight: "160%"
						}}
					>
						Patient Profile
					</div>
					<div
						className="col"
						style={{
							textAlign: "end"
						}}
					>
						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip id="firebase-messaging-tooltip">Locate</Tooltip>}
						>
							<Button
								style={{ padding: "6px" }}
								color="primaryLight"
								variant="contained"
								onClick={() => handleLocateClick()}
							>
								<img
									alt="Locate"
									src="/icons/location--person.svg"
									width="22"
									height="22"
								/>
							</Button>
						</OverlayTrigger>
						<Button
							color="primary"
							variant="contained"
							text="export"
							className="ml-2"
							onClick={() => handleExportClick()}
						/>
					</div>
				</div>

				<Card style={{ boxShadow: "none" }} margin={false} className={"cardToPrint"}>
					<CardHeader
						className="create_person_modal_title"
						padding={false}
						line={false}
						title={header("General Information", generalInfoOpen, setGeneralInfoOpen)}
					/>
					<Collapse in={generalInfoOpen} className={classes.collapse}>
						<CardBody style={{ padding: "10px 0" }}>
							<div className="form-group d-flex flex-column flex-lg-row w-100">
								<div
									className="col-lg-2 d-flex justify-content-center align-items-center mb-lg-0 mb-5"
									style={{
										pointerEvents: "none"
									}}
								>
									<ImageUploading
										onChange={onImageChange}
										dataURLKey="data_url"
										acceptType={["png", "jpg", "jpeg"]}
									>
										{({ onImageUpload, onImageRemoveAll, isDragging, dragProps }) => (
											<div className="upload__image-wrapper">
												<div
													className="image-input image-input-outline"
													id="kt_profile_avatar"
													style={{
														backgroundColor: "white",
														paddingRight: "8px",
														paddingTop: "8px",
														paddingBottom: "8px"
													}}
												>
													<div
														className="image-input-wrapper"
														style={{
															borderColor: isDragging ? "#C3C4DD" : "white",
															backgroundImage: `${getPicture()}`,
															backgroundColor: "#DDE9FD",
															backgroundSize: picture
																? "100%"
																: newImage
																? "100%"
																: newImage && !values.picture
																? "50%"
																: "50%",
															backgroundRepeat: "no-repeat",
															backgroundPosition: "center",
															cursor: "pointer",
															height: "178px",
															width: "178px"
														}}
														onClick={!isLoading ? onImageUpload : () => {}}
														{...dragProps}
													/>
													<label
														className={`btn btn-md btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow ${
															isLoading ? "disabled" : ""
														}`}
														data-action="change"
														data-toggle="tooltip"
														title=""
														data-original-title="Change logo"
														onClick={!isLoading ? onImageUpload : () => {}}
													>
														<i className="fa fa-pen icon-sm text-muted"></i>
													</label>
													<span
														onClick={() => {
															if (picture && !isLoading) {
																onImageRemoveAll()
																setFieldValue("picture", null)
															}
															if (!isLoading && newImage) {
																onImageRemoveAll()
																setFieldValue("picture", null)
																setNewImage(null)
															}
														}}
														className={`btn btn-md btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow ${
															isLoading ? "disabled" : ""
														}`}
														data-action="remove"
														data-toggle="tooltip"
														title=""
														data-original-title="Remove logo"
													>
														<i className="ki ki-bold-close icon-xs text-muted"></i>
													</span>
												</div>
												{picture?.file?.size &&
													picture.file.size / (1024 * 1024) > 10 && (
														<span className="form-text text-danger mt-2">
															{`Image is too big (${(
																picture.file.size /
																(1024 * 1024)
															).toFixed(2)}Mb).`}
															<br />
															Max imagesize: 10Mb.
														</span>
													)}
												<span className="form-text text-muted mt-2">
													Allowed file types: png, jpg, jpeg.
												</span>
											</div>
										)}
									</ImageUploading>
								</div>
								<div className="col-lg-10">
									<div className="row">
										<div className="col-6 mb-5 pl-lg-3 pr-lg-3 ">
											<Field
												disabled={true} // access only for superAdmins
												variant="filled"
												name="firstName"
												label="First Name"
												requiredField
												size="small"
												component={Input}
												InputProps={{
													disableUnderline: true
												}}
												placeholder="Enter first name"
											/>
										</div>
										<div className="col-6 mb-5 pl-lg-3 pr-lg-3">
											<Field
												disabled={true} // access only for superAdmins
												variant="filled"
												name="lastName"
												label="Last Name"
												requiredField
												size="small"
												component={Input}
												InputProps={{
													disableUnderline: true
												}}
												placeholder="Enter last name"
											/>
										</div>
										<div className="col-6">
											<Field name="phoneNumber">
												{({ field, form }) => {
													return (
														<>
															<div
																style={{
																	marginBottom: "10px",
																	height: "18px"
																}}
															>
																<span
																	style={{
																		color: "#4A4A68",
																		fontFamily: "Poppins",
																		fontSize: "12px",
																		fontWeight: "500"
																	}}
																>
																	Phone Number
																</span>
															</div>
															<PhoneInput
																name="phone"
																{...field}
																/* country={"us"} */
																disabled={true}
																containerClass="countryCode_containerPeople"
																inputClass="countryCode_inputClassPeople"
																buttonClass="countryCode_buttonClassPeople"
																dropdownClass="countryCode_dropdownClassPeople"
																placeholder="Enter phone number"
																onChange={(value, data) => {
																	form.setFieldValue("phoneNumber", value)
																	setCountryCode(data.dialCode)
																}}
															/>
														</>
													)
												}}
											</Field>
										</div>

										<div className="col-6 mb-5 pl-lg-3 pr-lg-3">
											<Field
												variant="filled"
												name="email"
												label="Email"
												size="small"
												disabled={true}
												component={Input}
												InputProps={{
													disableUnderline: true
												}}
												placeholder="Enter email"
											/>
										</div>
									</div>
									<div className="col-12 mb-5 pl-lg-3 pr-lg-3 w-100">
										<Field
											// disabled={isLoading}
											id="manage-site-geocoder"
											disabled={true}
											style={{
												backgroundColor: "rgb(242, 242, 242)"
											}}
											// className="manage-site-geocoder"
											name="address"
											label="Address"
											placeholder="Enter a street address"
											component={GeocoderInput}
											size="small"
											className="geocoder-input"
											variant="filled"
										/>
									</div>
								</div>
							</div>
						</CardBody>
					</Collapse>
				</Card>
				<Card style={{ boxShadow: "none" }} margin={false}>
					<CardHeader
						className="create_person_modal_title"
						padding={false}
						line={false}
						title={header(
							"Documentation Information",
							documentationInfoOpen,
							setDocumentationInfoOpen
						)}
					/>
					<Collapse in={documentationInfoOpen} className={classes.collapse}>
						<CardBody style={{ padding: "10px 0" }}>
							<div className="form-group d-flex flex-column flex-lg-row w-100">
								<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
									<Field
										id="gender-autocomplete"
										name="gender"
										disabled={true}
										customColor="#F4F4F4"
										label="Gender"
										style={{ width: "100%" }}
										component={Autocomplete_People}
										placeholder="Gender"
										options={genderList}
										onChange={(event, newValue) => {
											setFieldValue("gender", newValue)
										}}
									/>
								</div>
								<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
									<Field
										id="nationality-autocomplete"
										customColor="#F2F2F2"
										name="nationality"
										label="Nationality"
										disabled={true}
										component={Autocomplete_People}
										placeholder="Enter nationality"
										options={[
											...nationalities.map(val => {
												return { value: val, name: val }
											})
										]}
										onChange={(event, newValue) => {
											setSelectedNationality(newValue)
											setFieldValue("nationality", newValue)
										}}
									/>
								</div>
								<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
									<div style={{ marginBottom: "10px" }}>
										<span className="new_person_date_picker_label">Date of Birth</span>
									</div>
									<DatePicker
										className="custom-datepicker"
										calendarClassName="date_picker_range_reporting"
										selected={birthDate}
										disabled={true}
										onChange={e => {
											setFieldValue("birthdate", new Date(e))
											setBirthDate(new Date(e))
										}}
										name="birthdate"
										customInput={<ExampleCustomInput />}
										dateFormat="dd/MM/yyyy"
										wrapperClassName="custom-datepicker-wrapper"
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
									/>
								</div>
							</div>
							<div className="form-group d-flex flex-column flex-lg-row w-100">
								<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3 pl-md-3 pr-md-3">
									<Field
										variant="filled"
										name={values.idCard ? "idCard" : "passport"}
										label="ID/Passport Number"
										size="small"
										disabled={true}
										component={Input}
										InputProps={{
											disableUnderline: true
										}}
										placeholder="Enter number"
									/>
								</div>

								<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
									<Field
										variant="filled"
										name="sns"
										label="SNS Number"
										size="small"
										disabled={true}
										component={Input}
										InputProps={{
											disableUnderline: true
										}}
										placeholder="Enter number"
									/>
								</div>
							</div>
						</CardBody>
					</Collapse>
				</Card>
				<Card style={{ boxShadow: "none" }} margin={false}>
					<CardHeader
						className="create_person_modal_title"
						padding={false}
						line={false}
						title={header("Emergency Contacts", otherContacts, setOtherContacts)}
					/>
					<Collapse in={otherContacts} className={classes.collapse}>
						<CardBody style={{ padding: "10px 0" }}>
							<div className="form-group row">
								<div className="col">
									<Field
										variant="filled"
										name="firstEmergencyContact.name"
										label="Contact Name"
										disabled={true}
										component={Input}
										InputProps={{
											disableUnderline: true
										}}
										placeholder="Enter contact name"
									/>
								</div>
								<div className="col">
									<Field
										variant="filled"
										name="firstEmergencyContact.relationship"
										label="Relationship"
										disabled={true}
										component={Input}
										InputProps={{
											disableUnderline: true
										}}
										placeholder="Enter relantionship"
									/>
								</div>
								<div className="col">
									<Field
										variant="filled"
										name="firstEmergencyContact.phoneNumber"
										label="Phone Number"
										disabled={true}
										component={Input}
										InputProps={{
											disableUnderline: true
										}}
										placeholder="Enter contact phone number"
									/>
								</div>
							</div>
							<div className="form-group row">
								<div className="col">
									<Field
										variant="filled"
										name="secondEmergencyContact.name"
										label="Contact Name"
										disabled={true}
										component={Input}
										InputProps={{
											disableUnderline: true
										}}
										placeholder="Enter contact name"
									/>
								</div>
								<div className="col">
									<Field
										variant="filled"
										name="secondEmergencyContact.relationship"
										label="Relationship"
										disabled={true}
										component={Input}
										InputProps={{
											disableUnderline: true
										}}
										placeholder="Enter relantionship"
									/>
								</div>
								<div className="col">
									<Field
										variant="filled"
										name="secondEmergencyContact.phoneNumber"
										label="Phone Number"
										disabled={true}
										component={Input}
										InputProps={{
											disableUnderline: true
										}}
										placeholder="Enter contact phone number"
									/>
								</div>
							</div>
						</CardBody>
					</Collapse>
				</Card>
				<div
					className="d-flex w-100 align-items-center justify-content-center"
					style={{ gap: "10px" }}
				>
					<Button
						variant="outlined"
						color="primary"
						style={{ width: "10%", marginTop: "2.25rem", marginBottom: "1.25rem" }}
						// disabled={!dirty || isLoading}
						disabled={false}
						text="CANCEL"
						onClick={() => history.goBack()}
						data-testid="new-person-modal_handleSubmit"
					/>

					<Button
						variant="contained"
						color="primary"
						disabled={true}
						style={{ width: "10%", marginTop: "2.25rem", marginBottom: "1.25rem" }}
						// disabled={!dirty || isLoading}
						text="SAVE PERSON"
						loading={isLoading}
						onClick={handleSubmit}
						data-testid="new-person-modal_handleSubmit"
					/>
				</div>
			</Form>
			<div ref={mapContainerRef} style={{ display: "none" }} />
		</>
	)
}

export default PatientForm
