import React, { useState, useEffect } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { useParams, Redirect } from "react-router-dom"
import { PersonDetailedPageForm } from "./PersonDetailedPageForm"

const PersonDetailedPage = () => {
	const { id } = useParams() // Access the id parameter from the URL

	const { selectedCustomer, currentFloorPlan, selectedSite, people, companies } = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite, // new selectedLocation
			currentFloorPlan: state.profile?.currentFloorPlan, // new selectedLevel
			people: state.basePage?.people,
			companies: state.profile?.currentCustomer?.vendors || []
		}),
		shallowEqual
	)

	var initialValues = {
		firstName: null, //required
		lastName: null, //required
		sns: null, // required
		startDate: null,
		birthDate: null,
		gender: null,
		disabled: false,
		phoneNumber: null,
		email: null,
		buildingNumber: null,
		floor: null,
		doorNumber: null,
		postalCode: null,
		city: null,
		address: null,
		idCard: null,
		nationality: null,
		passport: null,
		picture: null,
		role: null,
		uuid: null,
		firstEmergencyContact: {
			name: null,
			phoneNumber: null,
			relationship: null
		},
		secondEmergencyContact: {
			name: null,
			phoneNumber: null,
			relationship: null
		}
	}
	const convertDateFromFirestore = firestoreDate => {
		if (!firestoreDate || typeof firestoreDate.seconds !== "number") {
			return null // Handle invalid input
		}

		const milliseconds =
			firestoreDate.seconds * 1000 + Math.floor(firestoreDate.nanoseconds / 1000000)
		const date = new Date(milliseconds)

		return date.toLocaleDateString("pt-PT")
	}

	const convertToDate = dateString => {
		if (!dateString) return null
		const [day, month, year] = dateString.split("/").map(Number)
		const fullYear = year + 2000 // Assuming the year is in the 2000s
		const date = new Date(fullYear, month - 1, day) // Month is 0-indexed

		return date // Return the Date object
	}

	const personToEdit = people && people.find(val => val.id === id)

	if (personToEdit) {
		const fullName = personToEdit.personalData?.name || ""

		// Split the name into an array of words
		const nameParts = fullName.trim().split(/\s+/) // Split by any whitespace, removes extra spaces

		// Check if there's at least one part (to avoid errors with empty or undefined input)
		const firstNameTrimmed = nameParts.length > 0 ? nameParts.slice(0, -1).join(" ") : "" // Take all but the last as first name
		const lastNameTrimmed = nameParts.length > 1 ? nameParts.slice(-1)[0] : "" // Last part is the last name

		initialValues = {
			// firstName: personToEdit.personalData?.name?.split(" ")[0] || "",
			// lastName: personToEdit.personalData?.name?.split(" ")[1] || "",
			firstName: firstNameTrimmed || "",
			lastName: lastNameTrimmed || "",
			phoneNumber: personToEdit.personalData?.phoneNumber || "",
			email: personToEdit.personalData?.email || "",
			nationality: personToEdit.personalData?.nationality,
			idCard: personToEdit.personalData?.idCard || "",
			passport: personToEdit.personalData?.passport || "",
			role: personToEdit.role,
			uuid: personToEdit.uuid || null,
			firstEmergencyContact: personToEdit.firstEmergencyContact || null,
			secondEmergencyContact: personToEdit.secondEmergencyContact || null,
			address: personToEdit.personalData.address || null,
			gender: personToEdit.personalData.gender || null,
			sns: personToEdit.personalData.sns || null,
			startDate: personToEdit.startDate
				? convertToDate(convertDateFromFirestore(personToEdit.startDate)) // Convert Firestore Timestamp
				: null,
			birthDate: personToEdit.personalData.birthDate
				? convertToDate(convertDateFromFirestore(personToEdit.personalData.birthDate)) // Convert Firestore Timestamp
				: null,
			picture: personToEdit?.personalData?.picture ? personToEdit.personalData?.picture : null
		}
	}

	// _______ useState _______ \\

	return (
		<div>
			{!personToEdit && <Redirect exact={true} to="/dashboard" />}

			<div>
				<PersonDetailedPageForm
					onHide={() => {
						return
					}}
					personToEdit={personToEdit}
					personId={id}
					initialValues={initialValues}
				/>
			</div>
		</div>
	)
}

export default PersonDetailedPage
