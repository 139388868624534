import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	allUsers: null,
	lastError: null
}

export const callTypes = {
	list: "list",
	action: "action"
}

export const manageCustomerSlice = createSlice({
	name: "manageCustomer",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		customerEdited: (state, action) => {
			state.actionsLoading = false
			state.error = null
		},
		assetTypeCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.assetTypes = state.assetTypes
				? [...state.assetTypes, action.payload]
				: state.assetTypes
		},
		assetTypeEdited: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.assetTypes =
				state.assetTypes &&
				state.assetTypes.map(entity => {
					if (entity.id === action.payload.id) return action.payload
					return entity
				})
		},
		assetTypeDeleted: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.assetTypes = state.profile?.currentCustomer?.assetTypes.filter(
				val => val.id !== action.payload
			)
		},

		roleCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.roles = state.roles ? [...state.roles, action.payload] : state.roles
		},
		roleDeleted: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.roles = state.profile?.currentCustomer?.roles.filter(
				val => val.id !== action.payload
			)
		},
		roleEdited: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.roles =
				state.roles &&
				state.roles.map(entity => {
					if (entity.id === action.payload.id) return action.payload
					return entity
				})
		},
		customUserRoleCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.customUserRoles = state.customUserRoles
				? [...state.customUserRoles, action.payload]
				: state.customUserRoles
		},
		customUserRoleDeleted: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.customUserRoles = state.profile?.currentCustomer?.customUserRoles.filter(
				val => val.id !== action.payload
			)
		}
	}
})
