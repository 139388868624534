import {
	firestore as db
	//firebaseAppOld as firebase,
} from "../../../../../../firebase"
import { doc, updateDoc } from "firebase/firestore"

export function saveRoutesGeoJsonNewConnectorPosition(queryParams) {
	if (!queryParams || !queryParams.geoJsonRoutes) {
		return Promise.resolve(null)
	}

	const floorPlanRef = doc(
		db,
		"Sites",
		`${queryParams.networkId}/FloorPlans/${queryParams.floorPlanId}`
	)

	return updateDoc(floorPlanRef, {
		geoJsonRoutes: queryParams.geoJsonRoutes
	})
}

export function saveRoutesGeoJson(queryParams) {
	if (!queryParams || !queryParams.geoJsonRoutes) {
		return Promise.resolve(null)
	}

	const floorPlanRef = doc(
		db,
		"Sites",
		`${queryParams.networkId}/FloorPlans/${queryParams.floorPlanId}`
	)

	return updateDoc(floorPlanRef, {
		geoJsonRoutes: queryParams.geoJsonRoutes
	})
}

export function deleteRoutes(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	const floorPlanRef = doc(
		db,
		"Sites",
		`${queryParams.networkId}/FloorPlans/${queryParams.floorPlanId}`
	)

	return updateDoc(floorPlanRef, {
		geoJsonRoutes: queryParams.geoJsonRoutes
	})
}
