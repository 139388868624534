/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react"
import { Button } from "../../../_partials/Button"
import { Alert } from "../../../_partials/Alert"
import { firestore } from "../../../../firebase"
import { collection, query, where, orderBy, limit, onSnapshot, Timestamp } from "firebase/firestore"
import { notificationsSlice } from "../../../redux/notifications/notificationsSlice"

import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { useHistory } from "react-router-dom"
import FirebaseNotificationsModalBody from "./FirebaseNotificationsModalBody"

import { Modal, Badge, Backdrop } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined"

import { OverlayTrigger, Tooltip } from "react-bootstrap"

import notificationSound from "../../../assets/sounds/notificationSound.wav"
import criticalNotificationSound from "../../../assets/sounds/criticalNotificationSound.mp3"

const useStyles = makeStyles(theme => ({
	backdrop: {
		backgroundColor: "red"
	},
	badge: {
		marginRight: "15px",
		borderRadius: "50%",
		"& .MuiBadge-anchorOriginTopRightRectangle.MuiBadge-invisible": {
			transform: "scale(0) translate(25%, 0%)"
		},
		"& .MuiBadge-anchorOriginTopRightRectangle": {
			transform: "scale(1) translate(25%, 0%)"
		}
	},
	badge_severity_one: {
		"& .MuiBadge-badge": {
			color: "#fff",
			backgroundColor: "#1065EF"
		}
	},
	badge_severity_two: {
		"& .MuiBadge-badge": {
			color: "#fff",
			backgroundColor: "#F6B952"
		}
	},
	badge_severity_three: {
		"& .MuiBadge-badge": {
			color: "#fff",
			backgroundColor: "#E65019"
		}
	},
	shakeAnimation: {
		animation: "$shake 500ms",
		animationIterationCount: 3,
		animationTimingFunction: "cubic-bezier(0.36, 0.07, 0.19, 0.97)"
	},
	"@keyframes shake": {
		"0%": {
			transform: "translateX(0)"
		},
		"20%": {
			transform: "translateX(-5px) rotate(-10deg)"
		},
		"40%": {
			transform: "translateX(5px) rotate(10deg)"
		},
		"60%": {
			transform: "translateX(-5px) rotate(-10deg)"
		},
		"80%": {
			transform: "translateX(5px) rotate(10deg)"
		},
		"100%": {
			transform: "translateX(0) rotate(0)"
		}
	},
	blinkAnimationWarning: {
		animation: "$blink-animation-warning 2.5s linear infinite"
	},
	"@keyframes blink-animation-warning": {
		"25%": {
			backgroundColor: "#F9D597"
		},
		"50%": {
			backgroundColor: "#FDF1DC"
		},
		"75%": {
			backgroundColor: "#F9D597"
		}
	},
	blinkAnimationCritical: {
		animation: "$blink-animation-critical 1.5s linear infinite"
	},
	"@keyframes blink-animation-critical": {
		"25%": {
			backgroundColor: "#ed825b"
		},
		"50%": {
			backgroundColor: "#FBE6DE"
		},
		"75%": {
			backgroundColor: "#ed825b"
		}
	},
	dropdown: {
		position: "fixed",
		width: 200,
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		border: "1px solid",
		padding: theme.spacing(1),
		backgroundColor: theme.palette.background.paper
	}
}))

const FirebaseNotifications = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const actions = notificationsSlice.actions

	const { selectedCustomer, notificationSwitch, user, notificationsVolume, notificationWarning } =
		useSelector(
			state => ({
				user: state.auth?.user,
				selectedCustomer: state.profile?.currentCustomer,
				notificationSwitch: state.notifications?.notificationSwitch,
				notificationsVolume: state.notifications?.notificationsVolume || 50,
				notificationWarning: state.notifications?.notificationWarning
			}),
			shallowEqual
		)
	//━━━━━━━━━━━━━━━ States ━━━━━━━━━━━━━━━\\
	const [buttonColor, setbuttonColor] = useState()
	const [newNotificationsCounter, setNewNotificationsCounter] = useState(0)
	const [open, setOpen] = useState(false)
	const [openWarning, setOpenWarning] = useState(false)
	const [allNotifications, setAllNotifications] = useState([])
	const [buttonCoordinates, setButtonCoordinates] = useState({ top: 0, left: 0 })
	const [showAnimation, setShowAnimation] = useState(false)
	const [showWarningAnimation, setShowWarningAnimation] = useState(false)
	const [showCriticalAnimation, setShowCriticalAnimation] = useState(false)

	const [alertContents, setAlertContents] = useState([])
	const [audioSource, setAudioSource] = useState(null)
	const [displayedAlerts, setDisplayedAlerts] = useState([])
	const [playNotificationSound, setPlayNotificationSound] = useState(false)

	const [severity, setSeverity] = useState({
		one: false, //blue
		two: false, //yellow
		three: false //red
	})

	// ━━━━━━━━━━━━━━━ Functions ━━━━━━━━━━━━━━━\\
	const handleOpenNotification = event => {
		if (severity.three) setbuttonColor("errorLight")
		else if (severity.two) setbuttonColor("warningLight")
		else if (severity.one) setbuttonColor("primaryLight")
		else setbuttonColor("primaryLight")

		setOpen(true)
		const buttonRect = event.currentTarget.getBoundingClientRect()
		setButtonCoordinates({ top: buttonRect.bottom + 10, left: buttonRect.left - 325 })
	}

	const handleCloseNotification = () => {
		newNotificationsCounter <= 0 && setbuttonColor()
		setOpen(false)
	}

	const toPlayNotificationSound = () => {
		// the solution to overcome the autoplay restrictions
		// is to use the Web Audio API instead of the HTML5 Audio element.
		// The Web Audio API provides more control over audio playback and doesn't have the same autoplay restrictions.
		// Create an AudioContext instance
		const AudioContext = window.AudioContext || window.webkitAudioContext
		const audioContext = new AudioContext()

		// Fetch the audio file
		fetch(notificationSound)
			.then(response => response.arrayBuffer())
			.then(buffer => {
				// Decode the audio data
				return audioContext.decodeAudioData(buffer)
			})
			.then(decodedData => {
				// Create an AudioBufferSourceNode
				const source = audioContext.createBufferSource()
				source.buffer = decodedData

				// Connect the AudioBufferSourceNode to the AudioContext's destination
				// source.connect(audioContext.destination)

				// Set the volume based on the volumeValue state
				const gainNode = audioContext.createGain()
				// Convert notificationsVolume to a value between 0 and 1
				gainNode.gain.value = notificationsVolume >= 5 ? notificationsVolume / 75 : 0

				source.connect(gainNode)
				gainNode.connect(audioContext.destination)

				// Start playing the audio
				source.start(0)

				// Enable looping
				source.loop = false

				// // Set the audio source in the state
				// setAudioSource(source)
			})
			.catch(error => {
				console.log("Error loading audio:", error)
			})
	}

	const toPlayCriticalSound = () => {
		// Create an AudioContext instance
		const AudioContext = window.AudioContext || window.webkitAudioContext
		const audioContext = new AudioContext()

		// Fetch the audio file
		fetch(criticalNotificationSound)
			.then(response => response.arrayBuffer())
			.then(buffer => {
				// Decode the audio data
				return audioContext.decodeAudioData(buffer)
			})
			.then(decodedData => {
				// Create an AudioBufferSourceNode
				const source = audioContext.createBufferSource()
				source.buffer = decodedData

				// Connect the AudioBufferSourceNode to the AudioContext's destination
				const gainNode = audioContext.createGain()
				// Set the volume based on the volumeValue state
				gainNode.gain.value = 0.3

				source.connect(gainNode)
				gainNode.connect(audioContext.destination)

				// Enable looping
				source.loop = true

				// Resume the AudioContext (needed for certain browsers and autoplay policies)
				if (audioContext.state === "suspended") {
					audioContext.resume()
				}

				// Start playing the audio
				source.start(0)

				// Set the audio source in the state
				setAudioSource(source)
			})
			.catch(error => {
				console.log("Error loading audio:", error)
			})
	}

	// const toPlayCriticalSound = () => {
	// 	const newAudio = new Audio(criticalNotificationSound)
	// 	// Set volume (e.g., 0.3 for 30% volume)
	// 	newAudio.volume = 0.4
	// 	newAudio.addEventListener("canplaythrough", () => {
	// 		newAudio.loop = true
	// 		newAudio.play()
	// 		setAudioSource(newAudio)
	// 	})
	// }

	const handleClose = id => {
		setDisplayedAlerts(prevAlerts =>
			prevAlerts.map(alert => (alert.notification.id === id ? { ...alert, show: false } : alert))
		)
	}

	const seeOnMap = id => {
		if (history.location.pathname != "/dashboard") history.push("/")

		// Clone the alertContents array to avoid mutating the state directly
		const updatedAlertContents =
			displayedAlerts &&
			displayedAlerts.map(alert => ({
				...alert,
				seeOnMap: alert.notification.id === id ? true : false
			}))

		// Update the state with the new array
		setAlertContents(updatedAlertContents)

		// Clone the displayedAlerts array to avoid mutating the state directly
		const updatedDisplayedAlertContents = displayedAlerts.map(alert => ({
			...alert,
			seeOnMap: alert.notification.id === id ? true : false
		}))

		// Update the state with the new array
		setDisplayedAlerts(updatedDisplayedAlertContents)

		return
	}

	const reopenDisplayedAlert = notification => {
		if (!displayedAlerts || !notification) return

		const alert = displayedAlerts.find(a => a.displayedAlerts?.id === displayedAlerts?.id)

		// Check if the notification is already read
		if (alert && notification?.read) {
			// Do nothing if the notification is already read
			return
		}

		setDisplayedAlerts(
			prevAlerts =>
				prevAlerts?.map(a =>
					a.notification?.id === alert.notification?.id
						? { ...alert, show: notification?.read ? false : true }
						: a
				) || []
		)
	}

	// ━━━━━━━━━━━━━━━ useEffect ━━━━━━━━━━━━━━━\\
	useEffect(() => {
		if (!alertContents || alertContents.length === 0) {
			displayedAlerts &&
				setDisplayedAlerts(prevAlerts => prevAlerts?.map(a => ({ ...a, show: false })) || [])
			return
		}

		// Filter alertContents to include only those not already in displayedAlerts
		const newAlerts = alertContents.filter(
			alert =>
				!displayedAlerts.some(displayedAlert => {
					return displayedAlert.notification.id === alert.notification.id
				})
		)

		const updatedNewAlerts = newAlerts.map(alert => ({
			...alert,
			show: alert.notification.read ? false : true
		}))

		setDisplayedAlerts(prevAlerts => [...prevAlerts, ...updatedNewAlerts])

		dispatch(
			actions.notificationWarningChanged(
				alertContents.map(alert => ({
					body: "You have a new notification",
					...alert.notification,
					seeOnMap: alert.seeOnMap || false
				}))
			)
		)
	}, [alertContents])

	useEffect(() => {
		displayedAlerts.forEach(alert => {
			// Check if the notification is read and its show property is true
			if (alert.notification?.read && alert.show) {
				// Trigger onClose logic when a read notification is shown
				handleClose(alert.notification.id)
			}
		})
	}, [displayedAlerts])

	// get new Notification & add to allNotifications IF notificationSwitch
	useEffect(() => {
		if (!selectedCustomer || !selectedCustomer.id) return

		if (notificationSwitch) {
			// DO A SNAPSHOT
			if (!user) return

			const firebaseServerDateTime = Timestamp.now().toDate()
			// const thirtyMinutesAgo = new Date(firebaseServerDateTime - 30 * 60 * 1000) // Calculate the date 30 min ago
			const twoHoursAgo = new Date(firebaseServerDateTime - 2 * 60 * 60 * 1000) // Calculate the date and time 2 hours ago

			const notificationsRef = collection(firestore, `Customers/${selectedCustomer.id}/Messages`)

			const q = query(
				notificationsRef,
				where("type", "==", "notification"),
				where("date", ">=", twoHoursAgo),
				orderBy("date", "desc"),
				limit(100)
			)

			const unsubscribe = onSnapshot(q, snapshot => {
				const data = snapshot.docs.map(doc => {
					return { id: doc.id, ...doc.data() }
				})

				if (!data) return

				// Check if any notification has severity === 2 or 3
				// set things to yellow = warning
				// set things to red = danger
				//TODO
				// const hasSeverityTwo = true
				const hasSeverityTwo = data.some(
					notification => notification.severity === 2 && !notification.read
				)
				const hasSeverityThree = data.some(
					notification => notification.severity === 3 && !notification.read
				)

				// Set playNotificationSound to true if there are new severity 2 notifications
				// setPlayNotificationSound(!hasSeverityThree)

				// Update severity state accordingly
				setSeverity({
					one: !hasSeverityTwo || !hasSeverityThree,
					two: hasSeverityTwo,
					three: hasSeverityThree
				})

				// Display alerts for each severity 2 or 3 notification
				const newAlertContents = data
					.filter(
						notification =>
							!notification.read &&
							(notification.severity === 2 || notification.severity === 3)
					)
					.map(notification => ({
						title:
							notification.severity && notification.severity === 3
								? "CRITICAL ALARM"
								: notification.severity === 2
								? "Warning Alarm"
								: "Info",
						body: notification.body,
						severity: notification.severity || 1,
						notification: notification,
						show: notification.read ? false : true
					}))

				newAlertContents &&
					alertContents &&
					setAlertContents([...alertContents, ...newAlertContents])

				setAllNotifications(data)

				//Play critical sound if severity three is detected
				// if (hasSeverityThree) {
				// 	toPlayCriticalSound()
				// }

				// handleCounterAndSound(hasSeverityTwo, hasSeverityThree)
			})

			return () => {
				// Clean up the snapshot listener
				unsubscribe()
			}
		} else {
			return
		}
	}, [selectedCustomer, user, notificationSwitch])

	// // Handle badge and sound
	// useEffect(() => {
	// 	if (!allNotifications) return

	// 	let counter = 0
	// 	let hasSeverityTwo = severity.two
	// 	let hasSeverityThree = severity.three

	// 	allNotifications.forEach(notification => {
	// 		if (!notification.read) {
	// 			counter++
	// 			setShowCriticalAnimation(hasSeverityThree)
	// 			setShowWarningAnimation(hasSeverityTwo && !hasSeverityThree)
	// 			setShowAnimation(!hasSeverityTwo && !hasSeverityThree)
	// 		}
	// 	})

	// 	setOpenWarning(hasSeverityTwo || hasSeverityThree)

	// 	setNewNotificationsCounter(counter)

	// 	//play notification wav once
	// 	if (counter > newNotificationsCounter && !hasSeverityThree) {
	// 		setPlayNotificationSound(true)
	// 	}

	// 	//play notification wav once
	// 	if (hasSeverityThree) {
	// 		// setPlayCriticalSound(true)
	// 		if (!audioSource) toPlayCriticalSound()
	// 	} else {
	// 		if (audioSource) {
	// 			audioSource.loop = false
	// 			audioSource.stop()
	// 			setAudioSource(null)
	// 		}
	// 	}

	// 	if (counter > 0) {
	// 		setbuttonColor(
	// 			hasSeverityThree ? "errorLight" : hasSeverityTwo ? "warningLight" : "primaryLight"
	// 		)
	// 	} else {
	// 		setbuttonColor("primaryLight")
	// 	}
	// }, [severity])

	useEffect(() => {
		if (!allNotifications) return

		// Count unread notifications
		const counter = allNotifications.reduce((count, notification) => {
			return count + (notification.read ? 0 : 1)
		}, 0)

		setNewNotificationsCounter(counter)
	}, [allNotifications])

	useEffect(() => {
		if (!allNotifications || !selectedCustomer) return

		let hasSeverityTwo = severity.two
		let hasSeverityThree = severity.three

		setShowCriticalAnimation(hasSeverityThree)
		setShowWarningAnimation(hasSeverityTwo && !hasSeverityThree)
		setShowAnimation(!hasSeverityTwo && !hasSeverityThree)

		setOpenWarning(hasSeverityTwo || hasSeverityThree)

		// Play notification sound once when there are new severity 2 notifications
		if (newNotificationsCounter > 0 && !hasSeverityThree) {
			setPlayNotificationSound(true)
		}

		// Play critical sound if severity three is detected
		if (hasSeverityThree && !audioSource) {
			toPlayCriticalSound()
		} else if (!hasSeverityThree && audioSource) {
			// Stop critical sound if severity three is not detected
			audioSource.loop = false
			audioSource.stop()
			setAudioSource(null)
		}

		setbuttonColor(
			hasSeverityThree ? "errorLight" : hasSeverityTwo ? "warningLight" : "primaryLight"
		)
	}, [severity, selectedCustomer])

	useEffect(() => {
		if (!playNotificationSound) return
		// Play the notification sound once when playNotificationSound is true
		if (playNotificationSound) {
			// Play the sound here
			//   playSound(notificationSound);
			toPlayNotificationSound()

			// Set playNotificationSound to false to avoid playing it repeatedly
			setPlayNotificationSound(false)
		}
	}, [playNotificationSound])

	React.useEffect(() => {
		if (!displayedAlerts) return
	}, [displayedAlerts])

	const classes = useStyles()
	return (
		<>
			<div
				className="vstack gap-2 col-md-5"
				style={{
					right: "-40px",
					top: "70px",
					padding: "13px 0",
					position: "absolute",
					maxHeight: "465px",
					overflow: "auto"
				}}
			>
				{displayedAlerts
					.filter(alertContent => alertContent.show !== false)

					.sort((a, b) => b.severity - a.severity)
					.map((alertContent, index) => (
						<Alert
							key={index}
							severity={alertContent.severity || "ALARM"}
							AlertTitle={alertContent.title || ""}
							AlertBody={alertContent.body || "ALARM MESSAGE"}
							onClose={() => handleClose(alertContent.notification.id)}
							seeOnMap={() => seeOnMap(alertContent.notification.id)}
						/>
					))}
			</div>
			<Badge
				key={newNotificationsCounter}
				max={99}
				badgeContent={newNotificationsCounter}
				className={`${classes.badge} 
				${
					showCriticalAnimation
						? classes.blinkAnimationCritical
						: showWarningAnimation
						? classes.blinkAnimationWarning
						: showAnimation
						? classes.shakeAnimation
						: ""
				}
				${
					severity.three
						? classes.badge_severity_three
						: severity.two
						? classes.badge_severity_two
						: classes.badge_severity_one
				}
				d-flex align-items-center`}
			>
				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip id="firebase-notification-tooltip">Notification</Tooltip>}
				>
					<Button
						color={buttonColor}
						className={`
							${
								showCriticalAnimation
									? classes.blinkAnimationCritical
									: showWarningAnimation
									? classes.blinkAnimationWarning
									: showAnimation
									? classes.shakeAnimation
									: ""
							}
						`}
						startIcon={
							<NotificationsNoneOutlinedIcon fontSize="large" className="badge-start-icon" />
						}
						style={{
							width: "36px",
							height: "36px",
							borderRadius: "25px",
							minWidth: "0"
						}}
						onClick={handleOpenNotification}
					/>
				</OverlayTrigger>
			</Badge>
			<Modal
				open={open}
				onClose={handleCloseNotification}
				BackdropComponent={props => <Backdrop {...props} />}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				style={{
					top: buttonCoordinates.top,
					left: buttonCoordinates.left,
					position: "absolute"
				}}
			>
				<div>
					<FirebaseNotificationsModalBody
						allNotifications={allNotifications}
						severity={severity}
						setShowAnimation={setShowAnimation}
						setShowWarningAnimation={setShowWarningAnimation}
						setShowCriticalAnimation={setShowCriticalAnimation}
						notificationWarning={notificationWarning}
						setAlertContents={setAlertContents}
						alertContents={alertContents}
						reopenDisplayedAlert={reopenDisplayedAlert}
					/>
				</div>
			</Modal>
		</>
	)
}
export default FirebaseNotifications
